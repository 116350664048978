import React from "react";



const DayPassFacilities = () => {
  return (
    <div className="row no-gutters">
      <div className="col-lg-12">
        <div className="feature-content light-bg">
          <div className="header1 mb-30">
            <h3 className="mainheading">Facilities & Services Included</h3>
          </div>
          {/* /.header */}
          <div className="row mt-15">
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="100"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/play.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Plug & Play Option</h5>
                <p className="tail">Embrace the convenience of our plug-and-play facilities for instant productivity with minimal setup time.</p>
              </div>
              {/* /.text-meta */}
            </div>
            {/* /.feature-list */}
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/wifi.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">High-speed Internet ( Wi-Fi )</h5>
                <p className="tail">Experience uninterrupted connectivity with our blazing-fast, reliable internet service.</p>
              </div>
              {/* /.text-meta */}
            </div>

            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/air.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Air conditioning</h5>
                <p className="tail"> We cover India like no one else, Virtual Office across all major Indian Cities</p>
              </div>
              {/* /.text-meta */}
            </div>
          </div>


         
          {/* /.feature-list  */}
        </div>
        {/* /.feature-content */}
      </div>
    </div>
  );
};

export default DayPassFacilities;