import { useState } from "react";
import './Benefits.css'

const BenefitsHotSeat = () => {
    const [toggle, setToggle] = useState("1");
    return (
        <>
            <div className='row '>
                <div className='col-xl-6 col-lg-6 block-style-thirtySix mobilepadding'>
                    <h2 className="mainheading mb-30 md-mt-20" >
                        Who is the Hot Seat suitable for?
                    </h2>
                    <div className="benefits-space">
                        <ul><li className="benefits-list">

                            <h2 onClick={() => setToggle("1")} className={`subheading-benefits title ${toggle === '1' ? 'active' : ''}`}>Freelancers and Solopreneurs</h2>
                            {toggle === "1" &&
                                <p className="para-benefits mt-10">For freelancers and solopreneurs seeking flexible workspace, our Hot Seat option offers the freedom to work on diverse projects. Plug in and start working immediately, surrounded by like-minded individuals who inspire and motivate you.</p>
                            }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("2")} className={`subheading-benefits mt-20 title ${toggle === '2' ? 'active' : ''}`}>Digital Nomads </h2>
                                {toggle === "2" &&
                                    <p className="para-benefits mt-10">Are you constantly on the move, seeking a workspace that aligns with your nomadic lifestyle?Our Hot Seat is the perfect choice for digital nomads, offering a home away from home. Work comfortably, connect with fellow travelers, and enjoy a supportive professional environment for remote work.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("3")} className={`subheading-benefits mt-20 title ${toggle === '3' ? 'active' : ''}`}>Small Business Owners </h2>
                                {toggle === "3" &&
                                    <p className="para-benefits mt-10">
                                        If you're a small business owner looking to establish a professional presence, our Hot Seat provides an affordable and flexible solution. Collaborate with other entrepreneurs, network, and benefit from the synergies within our coworking community.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("4")} className={`subheading-benefits mt-20 title ${toggle === '4' ? 'active' : ''}`}>Startups and Innovators</h2>
                                {toggle === "4" &&
                                    <p className="para-benefits mt-10">For startups and innovators looking to foster creativity, collaboration, and resource-sharing, our Hot Seat option is an excellent fit. Join a like-minded community, accelerate growth, and build meaningful connections in our innovative coworking space.</p>
                                }</li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 '>
                    {toggle === "1" &&
                        <img src="images/assets/Acc_Image1.png" alt="coworking space" className="benefits-img fadeinout" />
                    }
                    {toggle === "2" &&
                        <img src="images/assets/Acc_Image2.png" alt="coworking companies in coimbatore| india" className="benefits-img fadeinout" />
                    }
                    {toggle === "3" &&
                        <img src="images/assets/Acc_Image3.png" alt="hot seat, coimbatore coworking space" className="benefits-img fadeinout" />
                    }
                    {toggle === "4" &&
                        <img src="images/assets/Acc_Image4.png" alt="coworking community" className="benefits-img fadeinout" />
                    }
                </div>
            </div>

        </>
    )
}

export default BenefitsHotSeat

