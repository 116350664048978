import React from "react";

const Features4 = () => {
    return (
        <div className="row">
            <div className="col-xl-5 col-lg-5 order-lg-last" data-aos="fade-left">
                <div className="ps-lg-1 md-mt-10">
                    <div className="title-style-fourteen">
                        <h3 className="mainheading mb-15">Private Offices/Team Office</h3>
                        <p className="mainpara">Our private offices are designed to offer individuals a dedicated 
                            and personalized workspace within our vibrant coworking community. 
                            For businesses and teams of varying sizes, our team offices offer 
                            a collaborative and productive workspace. With a private/team office, 
                            you can enjoy the following benefits:</p>
                    </div>
                    <div className="mt-15">
                    </div>
                    <ul className="list-item mt-30">
                        <li style={{color:"#777777", fontSize:"16px", fontWeight:"400", lineHeight:"normal"}}><b style={{color:"#444"}}>Privacy and Focus</b>: Indulge in a serene and undisturbed workspace that enables you to fully 
                        immerse yourself in your tasks without any disruptions, fostering optimal focus and productivity.
                           </li>
                           <li style={{color:"#777777", fontSize:"16px", fontWeight:"400", lineHeight:"normal"}}><b style={{color:"#444"}}>Customization</b>: Customize your office space to reflect your
                            unique style, projecting a professional image that aligns with your brand.
                           </li>
                           <li style={{color:"#777777", fontSize:"16px", fontWeight:"400", lineHeight:"normal"}}><b style={{color:"#444"}}>Security</b>: Store your belongings securely in your private/team office,
                           knowing that your workspace is exclusively accessible to you and authorized team members.
                           </li>
                           <li style={{color:"#777777", fontSize:"16px", fontWeight:"400", lineHeight:"normal"}}><b style={{color:"#444"}}>Networking Opportunities</b>: Experience the best of both worlds: the privacy of your
                            office combined with abundant opportunities to connect and collaborate with like-minded professionals in our vibrant community areas.
                           </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-6 col-lg-6 text-center text-lg-left order-lg-first" data-aos="fade-right" style={{overflow:"hidden"}}>
                <div className="illustration-holder d-inline-block md-mt-30">
                    <img src="images/private/private_img.jpg" alt="the co working space"/>
                </div>
            </div>
        </div>
    );
};

export default Features4;
