import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import HeroBannerThirteen from "../../components/form-survey-landing/HeroBannerThirteen";
import Fancyhomecard from "../../components/features/Fancyhomecard";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import FancyTextHome from "../../components/fancy-text-block/FancyTextHome";
import LatestProduct from "../../components/vr-landing/LatestProduct";
import FancyTextHome2 from "../../components/fancy-text-block/FancyTestHome2";
import BlogStyle3 from "../../components/blog/BlogStyle3";
import Modal from "react-modal";
import HeaderPopupForm from "../../components/form/HeaderPopupForm";
// import { useMediaQuery } from 'react-responsive';
import CouponHome from "../../components/coupon/CouponHome";
import TestimonialHome from "../../components/form-survey-landing/TestimonialHome";
import { useMediaQuery } from "@uidotdev/usehooks";


const Home = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );
  const isLargeDevice = useMediaQuery(
    "only screen and (min-width : 993px) "
  );
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }


  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          Innovspace || Business Accelerator with Coworking Space in Coimbatore,
          India - Affordable Plug n Play Office | Virtual Office | Meeting Rooms | Training Rooms
        </title>
        <meta name="description" content="Innovspace || Business Accelerator with Coworking Space in Coimbatore,
          India - Affordable Plug n Play Office | Virtual Office | Meeting Rooms | Training Rooms" />
        <meta name="keywords" content="coworking, coworking space coimbatore, virtual service" />
        <meta property="og:description" content="This is a description of my page" />
        <meta name="author" content="Your Name" />

        {/* <meta property="og:title" content="My Page Title" />
        <meta property="og:image" content="https://example.com/image.jpg" />
        <meta property="og:url" content="https://example.com/my-page" />
        <meta name="twitter:title" content="My Page Title" />
        <meta name="twitter:description" content="This is a description of my page" />
        <meta name="twitter:image" content="https://example.com/image.jpg" />
        <meta name="twitter:card" content="summary_large_image" /> */}
        <title>Business Accelerator with Coworking Space in Coimbatore, India - Affordable Plug n Play Office | Virtual Office |
          Meeting Rooms | Training Rooms</title>
        <meta name="description"
          content="A Business Accelerator with Coworking Space in Coimbatore offers affordable Plug and Play 
	workspace for startups and freelancers - fits team of any size." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href="https://web.archive.org/web/20230609211023/https://www.innovspace.com/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:title"
          content="Business Accelerator with Coworking Space in Coimbatore, India - Affordable Plug n Play 
	Office | Virtual Office | Meeting Rooms | Training Rooms" />
        <meta property="og:description"
          content="A Business Accelerator with Coworking Space in Coimbatore offers affordable Plug and Play 
	workspace for startups and freelancers - fits team of any size." />
        <meta property="og:url" content="https://web.archive.org/web/20230609211023/https://www.innovspace.com/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="A Business Accelerator with Coworking Space in Coimbatore offers affordable Plug and Play 
	workspace for startups and freelancers - fits team of any size." />
        <meta name="twitter:title"
          content="Business Accelerator with Coworking Space in Coimbatore, India - Affordable Plug n Play 
	Office | Virtual Office | Meeting Rooms | Training Rooms" />
        <meta name="google-site-verification" content="KQY9G3jHoVsocadvxBlr_Q2t9zqGOx1WNVRBQy5gVws" />
      </Helmet>
      {/* End Page SEO Content */}
      <Header />

      {/* banner*/}
      <HeroBannerThirteen />
      {/*end  banner*/}


      {/* 4 box card*/}
      <div className="container">
        <Fancyhomecard></Fancyhomecard>
      </div>
      {/* 4 box card end*/}


      {/* Services */}
      <div className="container pt-20 md-pt-10" id="getstart">
        <div className="service-home">
          <h2 className="mainheading pt-20 pb-20">Our Workspaces that inspire</h2>
          <p className="mainpara">Unleash Your Potential in Workspaces Crafted to Inspire, with Tailored Solutions for Every Need.</p>
        </div>
        <div className=" lg-container pt-30  md-pt-10 md-mb-20 " id="product">
          <div className=" position-relative">
            <div className="row">
              <div className="product_slider_one arrow-middle-center pt-50 md-pt-50 md-pb-60 ">
                <LatestProduct />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Services end */}


      {/* Medium Large */}
      {/* Medium Large web view */}
      {isLargeDevice && <FancyTextHome2></FancyTextHome2>}
      {/* Medium Large web view end*/}

      {/* Mobile view */}
      {isMediumDevice &&
        <div className="container mt-40">
          <div className="position-relative">
            <div >
              <h2 className="mainheading">Solutions for business of all sizes</h2>
              <div className="business">
                <h4 className="subhead">
                  For Individuals and Small Teams
                </h4>
                <p className="md-mt-10 mainpara">
                  At Innovspace, we provide inspiring, flexible workspaces for individual professionals, freelancers, and small teams.
                  Our all-in-one solution not only includes a space to thrive but also comprehensive corporate services like company
                  registration and bookkeeping to support your business operations. Connect, innovate, and grow in an environment
                  designed to foster productivity and creativity, all while enjoying the benefits of professional business services tailored to your unique needs.
                </p>
                <div className="btn-home  mb-5">
                  <a href="/contact-us" className="theme-btn-fourteen1 notification">Book Workspace</a>
                  {/* <a href="#" className="theme-btn-fourteen1 notification" onClick={toggleModalOne}>Book Workspace</a> */}
                </div>
              </div>
            </div>
            <div>
              <div className="business">
                <h4 className="subhead md-mt-10">
                  For Medium to Large Businesses
                </h4>
                <p className="md-mt-10 mainpara">
                  Innovspace offers adaptable, well-equipped workspace solutions for medium to large businesses, accommodating growth and fostering collaboration.
                  Our workspaces, coupled with essential corporate services like accounting and company registration, provide a comprehensive solution for your business needs.
                  With flexible contracts and scalable spaces, Innovspace supports your evolving requirements, enabling you to focus on what you do best: driving your business forward.
                </p>
                <div className="btn-home  mb-5">
                  <a href="/contact-us" className="theme-btn-fourteen2 notification">Book Workspace</a>
                  {/* <a href="#" className="theme-btn-fourteen2 notification" onClick={toggleModalOne}>Book Workspace</a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="md-mt-30">
            <img src="images/assets/home-ml.png" alt="coworking space"></img>
          </div>
        </div>
      }
      {/* Mobile view end */}

      {/* Tablet view */}
      {isSmallDevice &&
        <div className="container mt-40">
          <div className="position-relative">
            <div >
              <h2 className="mainheading">Solutions for business of all sizes</h2>
              <div className="business">
                <h4 className="subhead">
                  For Individuals and Small Teams
                </h4>
                <p className="md-mt-10 mainpara">
                  At Innovspace, we provide inspiring, flexible workspaces for individual professionals, freelancers, and small teams.
                  Our all-in-one solution not only includes a space to thrive but also comprehensive corporate services like company
                  registration and bookkeeping to support your business operations. Connect, innovate, and grow in an environment
                  designed to foster productivity and creativity, all while enjoying the benefits of professional business services tailored to your unique needs.
                </p>
                <div className="btn-home  mb-5">
                  <a href="/contact-us" className="theme-btn-fourteen1 notification">Book Workspace</a>
                  {/* <a href="#" className="theme-btn-fourteen1 notification" onClick={toggleModalOne}>Book Workspace</a> */}
                </div>
              </div>
            </div>
            <div>
              <div className="business">
                <h4 className="subhead md-mt-10">
                  For Medium to Large Businesses
                </h4>
                <p className="md-mt-10 mainpara">
                  Innovspace offers adaptable, well-equipped workspace solutions for medium to large businesses, accommodating growth and fostering collaboration.
                  Our workspaces, coupled with essential corporate services like accounting and company registration, provide a comprehensive solution for your business needs.
                  With flexible contracts and scalable spaces, Innovspace supports your evolving requirements, enabling you to focus on what you do best: driving your business forward.
                </p>
                <div className="btn-home  mb-5">
                  <a href="/contact-us" className="theme-btn-fourteen2 notification">Book Workspace</a>
                  {/* <a href="/contact-us" className="theme-btn-fourteen2 notification" onClick={toggleModalOne}>Book Workspace</a> */}

                </div>
              </div>
            </div>
          </div>
          <div className="md-mt-30">
            <img src="images/assets/home-ml.png" alt="coworking space"></img>
          </div>
        </div>
      }
      {/* Tablet view end*/}

      {/* Medium Large end */}


      {/* Fusing section */}
      <FancyTextHome></FancyTextHome>
      {/* Fusing section end*/}


      {/* Coupon */}
      <div className="container">
        <CouponHome></CouponHome></div>
      {/* Coupon end */}


      {/* Testimonial */}
      <div className=" testimonial-main mt-150 md-mt-80 mb-40">
        {/* <div>
          <p style={{textAlign:"end"}}>Read More</p>
      </div> */}
        <TestimonialHome></TestimonialHome>
      </div>
      {/* Testimonial End */}


      {/* Blog */}
      <div className="container">
        <div className="d-flex justify-content-between mt-150 mb-50 md-mt-50">
          <div><h3 class="mainheading">Blog</h3></div>
          <div ><p className="text-right"><Link to="/blog">View more <span>  <i className="fa fa-chevron-right" aria-hidden="true"
            style={{ fontSize: "16px", fontWeight: "100", paddingLeft: "3px" }}></i> </span></Link></p></div>
        </div>
        <div className="row">
          <div className="feature-blog-three1 feature-blog-three">
            <BlogStyle3></BlogStyle3>
          </div>
        </div>
      </div>
      {/* Blog end*/}


      {/* Cta */}
      <div className="fancy-short-banner-two mt-20 md-mt-10" id="expert">
        <div className="container widthcontactus " >
          <div className="content-wrapper">
            <div className=" bg-wrapper d-lg-flex align-items-center justify-content-between">
              <h3 className="mainheadingcta" >
                Lets find an ideal solution that fits your needs.
                <br></br>
                <span className="footerptag">  <p className="mt-15 md-mb-10">Connect to a Innovspace Expert now</p></span></h3>
              {/* <button className="signUp-action1 notification" onClick={toggleModalOne} > */}
              <div>
                <Link to="/contact-us" style={{ width: "148px" }}>
                  <button className="btn-primaryc">Contact Us</button></Link>
              </div>
              {/* </button> */}
            </div>
          </div>
        </div>
      </div>
      {/* Cta end */}


      {/* footer */}
      <div className="footer-bg-wrapper mt-80 md-mt-40">
        <footer className="theme-footer-one pt-50 md-pt-30">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container">
            <div className="bottom-footer-content">
              <CopyRight />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModalOne}
          contentLabel="My dialog"
          className="custom-modal  modal-contact-popup-one"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >

          <div className="box_inner ">
            <main className="main-body box_inner modal-content clearfix md-mb-30">
              <button className="close" onClick={toggleModalOne}>
                <img src="images/icon/close.svg" alt="close" />
              </button>
              {/* End close icon */}

              {/* /.left-side */}


              <div className="right-side">
                <div className="size">
                  <h3 className="mainheading mb-20">Contact us </h3>
                  <HeaderPopupForm /></div>
              </div>
              {/*  /.right-side */}
            </main>
            {/* /.main-body */}
          </div>
        </Modal>
      </div>
    </div >
  );
};

export default Home;
