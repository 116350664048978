import React from "react";
import { Helmet } from "react-helmet";
import { useState } from "react";
import { Link } from "react-router-dom";
import HeaderPopupForm from "../../components/form/HeaderPopupForm";
import PortfolioMeeting from "../../components/form-survey-landing/PortfolioMeeting";
import FancyTextMeetingRoom from "../../components/fancy-text-block/FancyTestMeetingRoom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import OtherServicesMeetingRoom from "../../components/vr-landing/OtherServicesMeetingRoom";
import FeaturesMeetingRoom from "../../components/form-survey-landing/FeaturesMeetingRoom";
import Modal from "react-modal";
import CouponMeeting from "../../components/coupon/CouponMeeting";
import BenefitsMeetingRoom from "../../components/benefits/BenefitsMeetingRoom";
import FaqMeetingRoom from "../../components/faq/FaqMeetingRoom";

const MeetingRoom = () => {

  const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="main-page-wrapper p0 font-gordita">

      <Helmet>

        {/* <title> Meeting Office Space Rent Kalapatti | Furnished Office Space Coimbatore</title> */}
        <title>Meeting Office Space Rent Kalapatti | Furnished Office Space Coimbatore</title>
        <meta name="description"
          content="Book our meeting rooms and say bye to all space crunches in business life. Also explore our fully furnished office spaces in Coimbatore at any time." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical"
          href="https://web.archive.org/web/20230609205951/https://www.innovspace.com/workspaces/meeting-room/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Meeting Office Space Rent Kalapatti | Furnished Office Space Coimbatore" />
        <meta property="og:description"
          content="Book our meeting rooms and say bye to all space crunches in business life. Also explore our fully furnished office spaces in Coimbatore at any time." />
        <meta property="og:url"
          content="https://web.archive.org/web/20230609205951/https://www.innovspace.com/workspaces/meeting-room/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="Book our meeting rooms and say bye to all space crunches in business life. Also explore our fully furnished office spaces in Coimbatore at any time." />
        <meta name="twitter:title" content="Meeting Office Space Rent Kalapatti | Furnished Office Space Coimbatore" />
        <meta name="twitter:image"
          content="https://web.archive.org/web/20230609205951im_/https://www.innovspace.com/wp-content/uploads/2017/01/avatar-3.svg" />
      </Helmet>
      <Header></Header>

      {/* banner */}
      <div className="fancy-feature-thirtyFive position-relative lg-container pt-100 md-pt-80 mb-90 mt-60," id="product">
        <div className="container mt-40 md-mt-10">
          <div className="row">
            <div className="col-lg-4">
              <div className="block-style-thirtySix">
                <div className="meetingbanner md-mb-10 p-2" >
                  <h1 className="mainheading">Meeting Rooms & Training Rooms</h1>
                  <p style={{ paddingTop: "20px" }} className="mainpara">
                    Experience our premium meeting rooms and conference spaces, catering to diverse needs of modern professionals.
                    Perfect for client meetings, workshops, and corporate events, our well-appointed rooms foster productive collaboration.
                    Elevate your business interactions with our exceptional features and benefits.
                  </p>
                  <div className="mt-30">
                    <Link to="/contact-us">
                      <button className="button-banner">Talk to an expert</button></Link>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="slider-wrapper">
                <div className="portfolio_slider_two survey-slider " >
                  <PortfolioMeeting />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner End  */}


      {/* facilities */}
      <div className="fancy-text-block-three mt-60 md-pb-10">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              <FancyTextMeetingRoom></FancyTextMeetingRoom>
            </div>
          </div>
        </div>
      </div>
      {/* facilities end*/}


      {/* benefits */}
      <div className="container mt-120 md-mt-10">
        <BenefitsMeetingRoom></BenefitsMeetingRoom>
      </div>
      {/* benefits end*/}



      {/* features */}
      <div className="block-style-thirtySix lg-container mt-120 md-mt-50" id="feature">
        <FeaturesMeetingRoom></FeaturesMeetingRoom>
      </div>
      {/* features end */}



      {/* // ---  Pricing Details Start ---*/}
      <div className="container">
      <CouponMeeting></CouponMeeting>
      </div>
      {/* // --- Pricing Details End ---*/}



      {/* other services */}
      <div className="container pt-40 md-pt-50 ">
        <div>
          <h2 className="mainheading">Other Services</h2>
        </div>
        <div className=" lg-container pt-40  md-pt-10 md-mb-40" id="product" >
          <div className=" position-relative">
            <div className="row">
              <div className="product_slider_one arrow-middle-center-otherservices ">
                <OtherServicesMeetingRoom />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* other services end */}



      {/* faq */}
      <div className="faq-classic mt-100 pt-60  pb-50 md-pt-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="title-style-one">
                <h3 className="mainheadingfaq" >Answers to your Meeting Room questions</h3>
                <h6 className=" mt-15">Our team is ready to answer your questions.</h6>
              </div>
            </div>
            <div className="col-lg-7">
              <div >
                <FaqMeetingRoom></FaqMeetingRoom>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* faq end */}



      {/* footer */}
      <div className="footer-bg-wrapper">
        <footer className="theme-footer-one pt-70 md-pt-30">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
          </div>
          <div className="container">
            <div className="bottom-footer-content">
              <CopyRight />
            </div>
          </div>
        </footer>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModalOne}
          contentLabel="My dialog"
          className="custom-modal  modal-contact-popup-one"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div className="box_inner ">
            <main className="main-body box_inner modal-content clearfix md-mb-30">
              <button className="close" onClick={toggleModalOne}>
                <img src="images/icon/close.svg" alt="close" />
              </button>
              {/* End close icon */}
              <div className="left-side">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="row">
                    <div className="col-xl-10 col-lg-8 m-auto">
                      <blockquote>
                        <h3 className="mainheading" style={{ textAlign: "left", color: "#fff" }}>Talk To An Expert</h3>
                      </blockquote>
                      <span className="bio"><p style={{ fontSize: "16px", textAlign: "left", color: "#fff" }}>  Tell Us Your Requirements and Out Workspace Expert
                        willReach out to You at earliest.
                      </p></span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-side">
                <div className="size">
                  <h3 className="mainheading mb-20">Contact us </h3>
                  <HeaderPopupForm /></div>
              </div>
            </main>
          </div>
        </Modal>
        {/* /footer end */}
      </div>
    </div>
  );
};

export default MeetingRoom;