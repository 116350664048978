import React from "react";
import FancyFeatureTewentyEight from "../features/FancyFeatureTewentyEight";
import FancyFeatureThirtyOne from "../features/FancyFeatureThirtyOne";
import AnchorLink from "react-anchor-link-smooth-scroll";

const featureList = [
    {
        icon: "company",
        title: "Private Limited Company Formation",
        description: `Establishing a Private Limited Company marks a pivotal step for entrepreneurs and businesses aspiring for a structured and growth-oriented venture. Our seasoned team provides end-to-end support throughout the formation process, ensuring complete legal compliance. From document preparation to submission, we help you save precious time and effort.
        `,
    },
    {
        icon: "registration",
        title: " LLP Formation",
        description: ` Limited Liability Partnerships (LLPs) present flexible and beneficial business models, especially for professional service firms. Our committed team will steer you through the seamless formation of your LLP, offering valuable support with legal procedures and paperwork. With our proficiency, you can maneuver the process with confidence and ease.`,
    },
    {
        icon: "booking",
        title: "Bookkeeping Services",
        description: ` Maintaining precise financial records is vital for the success of any business, but managing these bookkeeping responsibilities can be laborious. Our competent accountants are at your service to efficiently manage your bookkeeping requirements, organizing financial data, recording transactions, and generating detailed reports to provide insights into your business's financial status.`,
    },
    {
        icon: "booking",
        title: "Legal Services:",
        description: `Legal matters can be a labyrinth for even the most experienced business professionals. At Innovspace, we extend our comprehensive legal services to guide you through these complexities. Our team of legal experts stands ready to advise and assist you in a variety of areas, ensuring your business stays compliant, protected, and primed for success. From contract reviews to intellectual property protection, we cover all your legal needs, letting you focus on your business growth.`,
    },
];

const FancyCorporateService = () => {
    return (
        <div className="container bgcolorcorporate">
            <div className="block-style-thirty mt-200 mb-80 md-mb-10 md-mt-160">


                <div>
                    <div className="text-block md-pb-10 paddingcorporate">

                        <div className="title-style-nine mb-20 md-mb-10">
                            <h3 class="mainheading pb-20" style={{textAlign:"center",paddingTop:"40px"}}>Business Services Offered</h3>

                        </div>
                        <div className="row mt-50">
                            <div className="feature-list-one col-xl-6 col-md-6 ">
                                <ul className="feature-list-one">
                                    <li>
                                        <img
                                            src={`images/assets/company.png`}
                                            alt="icon"
                                            className="icon"
                                        />
                                        <strong style={{ width: "280px" }}>Company Formation</strong>
                                        <span >Establishing a Private Limited Company marks a pivotal step for 
                                            entrepreneurs and businesses aspiring for a structured and growth-oriented 
                                            venture. Our seasoned team provides end-to-end support throughout the formation 
                                            process, ensuring complete legal compliance. From document preparation to submission, 
                                            we help you save precious time and effort.
                                      </span>
                                    </li>
                                </ul>
                            </div>
                            <div className="feature-list-one col-xl-6 col-md-6 ">
                                <ul className="feature-list-one">
                                    <li>
                                        <img
                                            src={`images/assets/booking.png`}
                                            alt="icon"
                                            className="icon"
                                        />
                                        <strong style={{ width: "280px" }}> LLP Formation</strong>
                                        <span >Limited Liability Partnerships (LLPs) present flexible and beneficial business models, 
                                            especially for professional service firms. Our committed team will steer you through the 
                                            seamless formation of your LLP, offering valuable support with legal procedures and 
                                            paperwork. With our proficiency, you can maneuver the process with confidence and ease.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row mt-50">
                            <div className="feature-list-one col-xl-6 col-md-6 ">
                                <ul className="feature-list-one">
                                    <li>
                                        <img
                                            src={`images/assets/booking.png`}
                                            alt="icon"
                                            className="icon"
                                        />
                                        <strong style={{ width: "280px" }}>Book Keeping and Accounting</strong>
                                        <span >Maintaining precise financial records is vital for the success of any business, but managing 
                                            these bookkeeping responsibilities can be laborious. Our competent accountants are at your service 
                                            to efficiently manage your bookkeeping requirements, organizing financial data, recording 
                                            transactions, and generating detailed reports to provide insights into your business's financial status.</span>
                                    </li>
                                </ul>

                            </div>
                            <div className="feature-list-one col-xl-6 col-md-6 ">
                                <ul className="feature-list-one">
                                    <li>
                                        <img
                                            src={`images/assets/legal.png`}
                                            alt="icon"
                                            className="icon"
                                        />
                                        <strong style={{ width: "280px" }}>Legal Services</strong>
                                        <span >Legal matters can be a labyrinth for even the most experienced business professionals. At Innovspace, 
                                            we extend our comprehensive legal services to guide you through these complexities. Our team of legal 
                                            experts stands ready to advise and assist you in a variety of areas, ensuring your business stays compliant, 
                                            protected, and primed for success. From contract reviews to intellectual property protection, we cover all 
                                            your legal needs, letting you focus on your business growth.</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FancyCorporateService;
