import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="row justify-content-end footerpadding">
      <div className="col-lg-3 col-md-12 footer-about-widget">
        <a href="home" className="logo">
          <img src="images/logo/logo_innov.png" alt="coworking space coimbatore" style={{width:"140px"}} />
        </a>
      </div>
      {/* /.footer-list */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title">Our Company</h5>
        <ul>
          <li>
            <Link to="/about" onClick={() => window.scrollTo(0, 0)}>About</Link>
          </li>
          <li>
            <Link to="/clients" onClick={() => window.scrollTo(0, 0)}>Clients</Link>
          </li>
          <li>
            <Link to="/testimonial" onClick={() => window.scrollTo(0, 0)}>Testimonials</Link>
          </li>
         
          <li>
            <Link to="/career" onClick={() => window.scrollTo(0, 0)}>Careers</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title">Workspace</h5>
        <ul>
          <li>
            <Link to="/private-office" onClick={() => window.scrollTo(0, 0)}>Private Office</Link>
          </li>
          <li>
            <Link to="/virtual" onClick={() => window.scrollTo(0, 0)}>Virtual Office</Link>
          </li>
          <li>
            <Link to="/Hot-seat" onClick={() => window.scrollTo(0, 0)}> Hot Seat</Link>
          </li>
          <li>
            <Link to="/dedicated-desk" onClick={() => window.scrollTo(0, 0)}>Dedicated Desk</Link>
          </li>
          <li>
            <Link to="/meeting-room" onClick={() => window.scrollTo(0, 0)}>Meeting Room</Link>
          </li>
        </ul>
      </div>
      {/* /.footer-list */}
      
      <div className="col-lg-3 col-md-4 footer-list">
        <h5 className="footer-title">Know Us</h5>
        <ul>
          <li>
            <Link to="/blog" onClick={() => window.scrollTo(0, 0)}>Blogs</Link>
          </li>
          {/* <li>
            <Link to="#">Workspace Benefits</Link>
          </li>
          <li>
            <Link to="#">Business Benefits</Link>
          </li> */}
          <li>
            <Link to="/contact-us" onClick={() => window.scrollTo(0, 0)}>Contact</Link>
          </li>
        </ul>
      </div>
      {/* /.about-widget */}
    </div>
    //.row
  );
};

export default Footer;
