import React from "react";
import FancyFeatureTewentyEight from "../features/FancyFeatureTewentyEight";
import FancyFeatureThirtyOne from "../features/FancyFeatureThirtyOne";
import DocSlickAsNavTwo from "../block-slider/DocSlickAsNavTwo";

const featureList = [
    {
        icon: "157",
        title: "Drag Image & SVG",
        description: ` Create beautiful sites at breakneck speed without
    compromising on your creativit`,
    },
    {
        icon: "158",
        title: "Create Custom Field",
        description: `Keep your brand front & center with your customer`,
    },
    {
        icon: "159",
        title: "Custom Attribute",
        description: ` Increase your client retention rate by showing your results
    and value.`,
    },
];

const FancyTextHome2 = () => {
    return (
        <div className="container indi-medium">
            <div className="block-style-thirty mt-70 mb-80">
                <div className="row">
                    <div className="col-xl-6 col-lg-6">
                        <div className="text-block md-pb-60">
                            <h3 class="mainheading pt-20 pb-20">Solutions for business of all sizes</h3>
                            <DocSlickAsNavTwo></DocSlickAsNavTwo>
                            {/* End .feature-list-one */}
                        </div>
                        {/* <!-- /.text-block --> */}
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <img src="images/assets/medium_large.png" alt="feature" style={{ borderRadius: "0 0.5rem 0.5rem 0" }}></img>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FancyTextHome2;
