import React from "react";







const FacilitiesPrivate = () => {
    return (
        <div className="row no-gutters">
            <div className="col-lg-12">
                <div className="feature-content light-bg">
                    {/* <div className="header1 mb-30" data-aos="fade-up" data-aos-duration="1200"> */}
                    <div className="header1 mb-30">
                        <h3 className="mainheading">Facilities & Services Included</h3>
                    </div>
                    
                    {/* /.header */}

                    <div className="row mt-15">
                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/wifi.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head">High-speed Internet ( Wi-Fi  & LAN )</h5>
                                <p className="tail">
                                Experience uninterrupted connectivity with our blazing-fast, reliable internet service.
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                        {/* /.feature-list */}

                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/24.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head"> 24 * 7 Service available</h5>
                                <p className="tail">
                                Deliver unparalleled convenience with our 24/7 service, ensuring uninterrupted support and productivity whenever you need it.
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/air.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head"> Air conditioning</h5>
                                <p className="tail">
                                We cover India like no one else, Virtual Office across all major Indian Cities
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                    </div>

                    <div className="row mt-15 md-mt-0">
                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/printer.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head">Printing Options</h5>
                                <p className="tail">
                                Access convenience with our range of on-site printing options for your business needs.
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                        {/* /.feature-list */}

                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/meetingroom.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head"> Complimentary Meeting Rooms</h5>
                                <p className="tail">
                                Utilize our fully-equipped meeting rooms for seamless collaboration and communication at no additional charge.
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/cupboard.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head"> Personal Storage Space</h5>
                                <p className="tail">
                                Safely stow your belongings with our secure and ample storage consoles.
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                    </div>

                    <div className="row mt-15 md-mt-0">
                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/privateoffice.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head">Dedicated Private Space</h5>
                                <p className="tail">
                                    100% digital process with no running around for compliances
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                        {/* /.feature-list */}

                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/customise.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head"> Customization Option</h5>
                                <p className="tail">
                                Personalize your workspace for maximum comfort and productivity through our flexible customization choices.
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                        <div className="feature-list d-flex col-lg-4 col-md-6">
                            <div className="icon-box d-flex align-items-center justify-content-center">
                                <img src="images/services/glassboard.png" alt="icon" />
                            </div>
                            <div className="text-meta">
                                <h5 className="head">Glass board with markers</h5>
                                <p className="tail">
                                    We cover India like no one else, Virtual Office across all major Indian Cities
                                </p>
                            </div>
                            {/* /.text-meta */}
                        </div>
                    </div>



                    {/* /.feature-list  */}
                </div>
                {/* /.feature-content */}
            </div>
        </div>
    );
};
export default FacilitiesPrivate;