import React from "react";
import { Link } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
const Fancyhomecard = () => {
  return (
    <div className="row mt-70 mb-40 md-mb-10">
      <div className="col-lg-3 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <img src="images/assets/book.svg" alt="feature"></img>
            </div>
            <div className="text">
              <Link className="homelink" to="contact-us">
                Book Workspace</Link>
              <p> Book meeting rooms & day offices now </p>
            </div>
          </div>
        </div>
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div className="col-lg-3 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <img src="images/assets/id.svg" alt="feature"></img>
            </div>
            <div className="text">
              <Link className="homelink" to="bussiness">
                Business Services</Link>
              <p> Company Registration, Accounting & Book Keeping </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div className="col-lg-3 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200">
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <img src="images/assets/chair.svg" alt="feature"></img>
            </div>
            <div className="text">
              <Link className="homelink" to="virtual">
                Set Up Virtual Space</Link>
              <p> Get your company a distinct physical address </p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}

      <div className="col-lg-3 d-flex mb-35" data-aos="fade-up" data-aos-duration="1200" data-aos-delay="100">
        <div className="block-style-twentyFour">
          <div className="d-flex align-items-start">
            <div className="icon d-flex align-items-center justify-content-center">
              <img src="images/assets/call.svg" alt="feature"></img>
            </div>
            <div className="text">
              {/* <AnchorLink href="#expert"> */}
                <Link className="homelink" to="contact-us">
                  Talk to an Expert</Link>
              {/* </AnchorLink> */}
              <p>Need assistance selecting the ideal work schedule</p>
            </div>
          </div>
        </div>{" "}
        {/* /.block-style-twentyFour */}
      </div>
      {/* End .col */}
      {/* End .col */}
    </div>
  );
};

export default Fancyhomecard;
