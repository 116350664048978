import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import Features4 from "../../components/form-survey-landing/Features4";
import FacilitiesPrivate from "../../components/fancy-text-block/FacilitiesPrivate";
import CarouselPrivate from "../../components/form-survey-landing/CarouselPrivate";
import OtherServicesPrivateOffice from "../../components/vr-landing/OtherServicesPrivateOffice";
import CouponPrivate from "../../components/coupon/CouponPrivate";
import BenefitsPrivate from "../../components/benefits/BenefitsPrivate";
import FaqPrivateOffice from "../../components/faq/FaqPrivateOffice";

const PrivateOffice = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Private Office Coimbatore | Plug and Play Office | Shared Work Space India</title>
        <meta name="description"
          content="If you are looking for Private Office spaces in Coimbatore then you have come to the right place. We offer office spaces at best location at best price." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical"
          href="https://web.archive.org/web/20230609202119/https://www.innovspace.com/workspaces/private-office/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Private Office Coimbatore | Plug and Play Office | Shared Work Space India" />
        <meta property="og:description"
          content="If you are looking for Private Office spaces in Coimbatore then you have come to the right place. We offer office spaces at best location at best price." />
        <meta property="og:url"
          content="https://web.archive.org/web/20230609202119/https://www.innovspace.com/workspaces/private-office/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="If you are looking for Private Office spaces in Coimbatore then you have come to the right place. We offer office spaces at best location at best price." />
        <meta name="twitter:title" content="Private Office Coimbatore | Plug and Play Office | Shared Work Space India" />
        <meta name="twitter:image"
          content="https://web.archive.org/web/20230609202119im_/https://www.innovspace.com/wp-content/uploads/2017/01/avatar-4.svg" />

      </Helmet>

      <Header></Header>

      {/* banner */}
      <div className="block-style-thirtySix container pt-110 md-pt-60" >
        <div className="row" >
          <div className="col-xl-5 col-lg-5 ">
            <div className="privatebanner md-pt-25 mt-40">
              <div className="title-style-fourteen">
                <h1 className="mainheading">Private Office / Team Office Spaces Built For Your Success</h1>
              </div>
              <div className="mt-20">
              </div>
              <p className="mainpara">A premier destination offering flexible office solutions designed to cater to the unique requirements of
                professionals and teams. Immerse yourself in our vibrant coworking atmosphere, where a diverse array of
                office choices awaits individuals and groups alike. Delve into our private office and team office alternatives
                to discover the impeccable match for your needs.</p>
              <div className="mt-30">
                <Link to="/contact-us">
                  <button className="button-banner">Talk to an expert</button></Link>
              </div>
            </div>
          </div>
          <div className="col-xl-7 col-lg-7 md-pt-30 " style={{ padding: "10px" }}>
            <CarouselPrivate></CarouselPrivate>
          </div>
        </div>
      </div>
      {/* banner end*/}



      {/* Facilities  */}
      <div className="fancy-text-block-three md-pt-20 pt-110 md-pb-10">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              <FacilitiesPrivate />
            </div>
          </div>
        </div>
      </div>
      {/* Facilities end */}


      {/* benefits */}
      <div className="container mt-120 md-mt-20">
        <BenefitsPrivate></BenefitsPrivate>
      </div>

      <div className="block-style-thirtySix lg-container  md-pt-20 mt-130 md-mt-10" id="feature">
        <div className="container">
          <Features4 />
        </div>
      </div>
      {/* benefits end*/}


      {/* coupon*/}
      <div className="container">
      <CouponPrivate></CouponPrivate>
      </div>
      {/* coupon end*/}


      {/*other services*/}
      <div className="container pt-100 md-pt-100 ">
        <div>
          <h2 className="mainheading" >Other Services</h2>
        </div>
        <div className=" lg-container pt-40  md-pt-10 md-mb-40" id="product">
          <div className=" position-relative">
            <div className="row">
              <div className="product_slider_one arrow-middle-center-otherservices otherservicespadding">
                <OtherServicesPrivateOffice />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*other services end*/}

      {/*Faq */}
      <div className="faq-classic mt-100 pt-60  pb-50 md-pt-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="title-style-one">
                <h3 className="mainheadingfaq" >Answers to your Private Office questions</h3>
                <h6 className=" mt-15">Our team is ready to answer your questions.</h6>
              </div>
            </div>
            <div className="col-lg-7">
              <div >
                <FaqPrivateOffice></FaqPrivateOffice>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*Faq end*/}


      {/* <!-- Footer --> */}
      <div className="footer-bg-wrapper">
        <footer className="theme-footer-one pt-70 md-pt-30 ">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
          </div>
          <div className="container ">
            <div className="bottom-footer-content ">
              <CopyRight />
            </div>
          </div>
        </footer>
        {/* /.theme-footer end*/}
      </div>
    </div>
  );
};

export default PrivateOffice;
