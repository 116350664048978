import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialInnovSpace() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [

    {
      img: "BigIdea",
      desc: `I hope this email finds you well. I am writing to express my utmost satisfaction and appreciation for the outstanding coworking space you have created.

      Since joining your establishment, I have been impressed by the impeccable facilities and the welcoming atmosphere. The attention to detail in ensuring a comfortable working environment is truly commendable.
      
      The amenities provided, such as high-speed internet connectivity, modern workstations, and well-equipped meeting rooms have greatly contributed to enhancing my productivity. Additionally, the availability of communal areas for networking and collaboration has proven to be invaluable.
      
      Furthermore, I would like to acknowledge your team's prompt response to any inquiries or concerns that I may have had. Their willingness to go above and beyond in providing exceptional customer service has not gone unnoticed.
      
      In conclusion, I want to express my gratitude for creating such an incredible coworking space that fosters growth and creativity. It is evident that you have put a tremendous amount of effort into curating an environment conducive to success.
      
      I have attached the logo below. Should there be any way in which I can assist or provide further feedback, please do not hesitate to reach out.`,
      reviewerName: "Kanimozhi. R",
      designation: "Talent Acquisition Executive",
      countstar:"5",
      tesimg: "BigIdea"
    },

    {
      img: "softworth",
      desc: `Innovspace has truly transformed our work experience. With its comfortable and inspiring environment, high-speed internet, and well-equipped meeting rooms, our productivity has rapidly increased. The friendly and helpful staff, along with the opportunity to network with other professionals, creates a thriving community. Its convenient location is an added bonus for both us and our clients. Innovspace is undoubtedly the perfect coworking space, and we highly recommend it to anyone looking for an exceptional workspace.`,
      reviewerName: "Hari Krishnan V R",
      designation: "HR Manager",
      countstar:"5",
      tesimg: "BigIdea"
    },

    {
      img: "fynanziare1",
      desc: `As a startup founder, this co-working space has been instrumental in the early stages of our company. The flexible membership options allowed us to scale up or down as needed, giving us the freedom to adapt to our growing team. The office infrastructure is top-notch, providing everything we require to run our operations smoothly. Beyond the practicalities, the sense of community here is incredible. We’ve met brilliant minds, received valuable feedback, and even found potential investors among our co-members. Being a part of this co-working space has been a tremendous boost to our startup journey.`,
      reviewerName: "Sabarinathan",
      designation: "Co-Founder",
      countstar:"5",
      tesimg: "BigIdea"
    },

    {
      img: "pupilsblockchain",
      desc: `I’m a digital nomad, and finding a co-working space that feels like a home away from home is a challenge. Luckily, this co-working space exceeded all my expectations. The welcoming environment, complete with cozy breakout areas and a fully stocked kitchen, makes it feel like an extension of my own workspace. The global network of co-working spaces provided by the membership is a fantastic perk, allowing me to work seamlessly during my travels. I’ve met fascinating people from various backgrounds and built connections that go beyond professional collaborations. I’m thrilled to be a part of this co-working community and can’t recommend it enough to fellow nomads and remote workers.`,
      reviewerName: "RanjithKumar Rangaraju",
      designation: "Co- Founder",
      countstar:"5",
      tesimg: "BigIdea"
    },

    {
      img: "halley",
      desc: `I’ve been a member of this co-working space for over a year, and I can confidently say it’s been a game-changer for my productivity and business. The environment is vibrant and professional, providing the perfect balance of focus and collaboration. The amenities, from high-speed internet to well-equipped meeting rooms, have been essential for my work. Moreover, the supportive community of like-minded professionals has been invaluable, allowing me to expand my network and gain valuable insights. I highly recommend this co-working space to anyone looking for a productive and inspiring workspace`,
      reviewerName: "Shanmuga Sundaram",
      designation: "Director",
      countstar:"5",
      tesimg: "BigIdea"
    },

    {
      img: "hk",
      desc: `Over the past 14 months at InnovSpace in Kalapatti, Coimbatore, I've had an enriching experience that I'm eager to share. Our 25-seater area, thoughtfully split into two sections, has proven to be the perfect backdrop for both focused work and collaborative projects. But what really elevates InnovSpace above others isn't just its space design. The meticulous attention to cleanliness, bolstered by top-tier security, makes it a haven for professionals. Their in-house maintenance and IT support, always at the ready, have been an absolute lifesaver on multiple occasions, ensuring smooth workflow. A heartfelt kudos to InnovSpace for curating such an optimal and nurturing workspace environment. I wholeheartedly endorse it for any enterprise or individual seeking a premier co-working experience in Coimbatore.`,
      reviewerName: "Sownder Kumar",
      designation: "CHRO",
      countstar:"5",
      tesimg: "BigIdea"
    },

    {
      img: "praathee",
      desc: `"We've been partnering with Innvospace for over a year now, and their services have been exceptional. The amenities they offer are truly top-notch, ranging from high-speed internet to well-equipped meeting rooms. What sets Innvospace apart is their remarkable team. They are incredibly cooperative, friendly, and their support is nothing short of awesome. It's evident that the staff goes the extra mile to create a welcoming environment. We couldn't be happier with our experience at Innvospace!"`,
      reviewerName: "Sureshkumar Kuppusamy",
      designation: "Managing Director",
      countstar:"5",
      tesimg: "Praathee"
    },

    {
      img: "infoplus1",
      desc: `It has been a pleasure and Pleasant being a tenant at Innovspace Business Centre for one & Half years.

      Each of our employees has enjoyed the high-quality office space with exclusively connected to the world, convenient location to Commute, and the impeccable service from the property management staff. Not only with the facility, but the cultural activities also, hosted by Innovspace start of this year Pongal Festival-2023 is a remarkable event for Kalapatti & SITRA Office together [To Gather Event] and our employees showed the Synergy in Teaming up and executing their roles. It really helped us to build a team with spirit. Deepavali Lunch is also fantastic.. We wish to continue for more years with Innovspace Business Centre.`,
      reviewerName: "VIJAY RAMASAMY",
      designation: "Delivery Lead",
      countstar:"5",
      tesimg: "INFOPLUS Technologies"
    },

    {
      img: "Your Farm1",
      desc: `I've been working in this co-working space along with 20 other of my employees for the past one and half year. It's more than just a place to work; it's a community that fosters creativity, collaboration, and productivity.

      The first thing that struck me was the welcoming atmosphere. The staff here are incredibly friendly and always ready to help, whether it's with technical issues or just providing a warm smile to start your day. It's like having an extended work family.
      
      The physical space itself is thoughtfully designed. There are various seating options to suit different working styles with high-speed internet and well-equipped meeting rooms.
      
      Overall, this co-working space is more than just a place; it's a hub of creativity, productivity, and collaboration. I will definitely recommend it to anyone looking for a fantastic co-working environment.`,
      reviewerName: "Ramya",
      designation: "Digital Marketing Analyst",
      countstar:"5",
      tesimg: "a"
    },
    
    {
      img: "Elitez India",
      desc: `It has been more than 6+ years with Innovspace Family and it has always been more than just a workplace - It's not just a place to work; it's a collaborative environment where ideas are born, connections are made, and friendships flourish. From day one, the support team has been exceptional, they make sure every issue is addressed immediately. Whether it's technical assistance, administrative support, or just a friendly face to brighten your day, the team here is absolutely top-notch. Having been a part of this coworking family for so long, I've witnessed the commitment to continuous improvement and excellence. They actively seek feedback and implement changes to enhance the overall experience for members. I wholeheartedly recommend it to anyone in search of a place where work feels like a pleasure.`,
      reviewerName: "K J Naveen",
      designation: "Managing Director",
      countstar:"5",
      tesimg: "b"
    },
    {
      img: "stanventures",
      desc: `Innovspace is the best place to startup companies as they take care of all the operational needs for an office premises. Support from the team Innovspace is always at peak. Quick resolving of any kind of operations issues is the USP of the team Innovspace. They understand the difficulties of a business and providing excellent support as much as possible.`,
      reviewerName: "Subash",
      designation: "HR Department",
      countstar:"5",
      tesimg: "b"
    },
    {
      img: "Codevantage1",
      desc: `I've been using the Innovspace coworking space for a year, and I must say that I am quite satisfied with the overall experience. Innovspace team is very responsive if we have any issues. It's affordable, and it's a great place to launch your startup. `,
      reviewerName: "Aswin Kumar",
      designation: "Founder of Codevantage, Inc",
      countstar:"5",
      tesimg: "b"
    },
  ];

  return (
    <>
      {TestimonilContent.map((val, i) => (
        <div className="col-lg-6 col-md-6 " key={i}>
          <div className="bg-wrapper mt-50">
            <div className="testimonial-review">
            <img src={`images/logo/${val.img}.png`} alt="" className="logo"/>
             <div  className="star-count">
              {(new Array(Number(val.countstar)).fill(0)).map(_ => 
                 <i class='fa fa-star' style={{color:" #f3da35"}}></i>
              )}
              {/* {getStars(Number(val.countstar))} */}
              </div></div>
              <div class="horizontal-rule-dark _1-5em-bottom-margin mt-20 mb-10"></div>
            <p className="title-scroll">{val.desc}</p>
            {/* <img src={`images/logo/${val.tesimg}.png`} loading="lazy" width="48" height="" alt="" class="large-avatar _75em-bottom-margin"/> */}
            {/* <img src="https://uploads-ssl.webflow.com/61c1ec16984f4464d33a1282/623cb3bce7664c43ed079160_6021d4cd2506acd8d43518d7_Danielle%20aihini%2C%20Robin%20headshot.png" 
            loading="lazy" width="48" height="" alt="" class="large-avatar _75em-bottom-margin"/> */}
            <div className="name md-mt-20">{val.reviewerName}</div>
            <div className="desig mainpara">{val.designation}</div>
          </div>
        </div>
      ))}
      
     
    </>
  );
}
