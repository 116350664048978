import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const BlogContent = [

  {
    img: "Dedicated desk",
    tag: "Dedicated Desks",
    title: `Experience productivity and personalized workspace with our Dedicated Desks in our vibrant coworking community. Reserve your spot today for a focused and comfortable work environment`,
    routePath: "/dedicated-desk",
    dealyAnimation: "50",
    alt:"office spaces",
  },
  {
    img: "Private Team office",
    tag: "Private / Team Office",
    title: `Elevate your team's productivity with our private office spaces in our dynamic coworking environment. Experience privacy and collaboration in a dedicated workspace tailored to your needs.`,
    routePath: "/private-office",
    dealyAnimation: "100",
    alt:"plug n play office",
  },
  {
    img: "Day pass",
    tag: "Day Pass",
    title: `Get a taste of our thriving coworking community with our Day Pass option. Enjoy a productive day of flexible work in our vibrant workspace, complete with all the amenities you need.`,
    routePath: "/day-pass",
    dealyAnimation: "50",
    alt:"affordable coworking space",
  },
  {
    img: "Meeting Room",
    tag: "Meeting Room",
    title: `Book our well-equipped meeting rooms today to impress clients and colleagues in a professional setting. Host successful meetings and presentations in our coworking space.`,
    routePath: "/meeting-room",
    dealyAnimation: "100",
    alt:"coworking space companies",
  },
  {
    img: "virtual_office",
    tag: "Virtual Office",
    title: `Elevate your business with our Virtual Office solution, offering a prestigious address and essential remote services. Build your seamless virtual presence today for flexibility and a professional image.`,
    routePath: "/virtual",
    dealyAnimation: "150",
    alt:"plug and play office",
  },
  {
    img: "corporate office",
    tag: "Business Services",
    title: `Simplify business setup with our comprehensive services including company & LLP registration, available in our collaborative coworking space. Streamline processes & maximize efficiency today.`,
    routePath: "/bussiness",
    dealyAnimation: "50",
    alt:"cheap coworking space",
  },
  
];

const OtherServicesHotSeat = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
     
        {BlogContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6 md-mt-20 "
          key={i}
        >
          <div className="card background-card">
            <img
              src={`images/assets/${item.img}.jpg`}
              alt={item.alt}
              className="img-holder"
            />
            <div class="p2">
            <Link to={item.routePath}>
            <h4 class="cardhead">
            {item.tag}</h4></Link>           
              <p className="title-font">
                {item.title}
              </p>       
          </div> 
        </div>
        </div>
      ))}
      </Slider>
    </>
  );
};

export default OtherServicesHotSeat;
