import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Features from "../../components/form-survey-landing/Features";
import Portfolio from "../../components/form-survey-landing/Portfolio";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import FancyTextBlockThree from "../../components/fancy-text-block/FancyTextBlockThree";
import OtherServices from "../../components/vr-landing/OtherServices";
import CouponDedicated from "../../components/coupon/CouponDedicated";
import BenefitsDedicated from "../../components/benefits/BenefitsDedicated";
import FaqDedicatedDesk from "../../components/faq/FaqDedicatedDesk";



const DedicatedDesk = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>
          Rent Dedicated Desk Space Coimbatore | Corporate Meeting, Training rooms
        </title>
        <title>Rent Dedicated Desk Space Coimbatore | Corporate Meeting, Training rooms</title>
        <meta name="description"
          content="Innovspace co-working space has well designed open desks, discussion and meeting rooms with all the basic amenities needed to start work immediately." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical"
          href="https://web.archive.org/web/20230609211947/https://www.innovspace.com/workspaces/co-working-spaces/dedicated-desk/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Rent Dedicated Desk Space Coimbatore | Corporate Meeting, Training rooms" />
        <meta property="og:description"
          content="Innovspace co-working space has well designed open desks, discussion and meeting rooms with all the basic amenities needed to start work immediately." />
        <meta property="og:url"
          content="https://web.archive.org/web/20230609211947/https://www.innovspace.com/workspaces/co-working-spaces/dedicated-desk/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="Innovspace co-working space has well designed open desks, discussion and meeting rooms with all the basic amenities needed to start work immediately." />
        <meta name="twitter:title" content="Rent Dedicated Desk Space Coimbatore | Corporate Meeting, Training rooms" />
        <meta name="twitter:image"
          content="https://web.archive.org/web/20230609211947im_/https://www.innovspace.com/wp-content/uploads/2017/01/avatar-3.svg" />
      </Helmet>
      <Header></Header>

      {/*banner*/}
      <div className="fancy-feature-thirtyFive position-relative lg-container pt-100 md-pt-80 mb-90 mt-60," id="product">
        <div className="container mt-40 md-mt-10">
          <div className="row">
            <div className="col-lg-4">
              <div className="block-style-thirtySix">
                <div className="dedicatedbanner md-mb-10 p-2" >
                  <h1 className="mainheading">Dedicated Desks</h1>
                  {/* <p style={{ paddingTop: "20px" }} className="mainpara">Our bright, elegantly designed open-plan coworking spaces can enhance the way you work.
                    They provide the opportunity to be a part of a vibrant community in our shared office space.</p> */}
                  <p style={{ paddingTop: "20px" }} className="mainpara">
                    Welcome to Innovspace, where productivity meets flexibility with our dedicated desk options. Our dedicated desks are designed to cater
                    to a wide range of professionals who value a personalized and professional workspace. Whether you're a freelancer, remote worker,
                    entrepreneur, or part of a small team, our dedicated desks offer the perfect solution for your workspace needs.</p>

                  <div className=" tag-line md-mb-20">
                    <Link to="/contact-us">
                      <button className="button-banner">Talk to an expert</button></Link>
                  </div>
                </div>
              </div>
            </div>
            <div >
              <div className="slider-wrapper">
                <div className="portfolio_slider_two survey-slider" >
                  <Portfolio />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*banner end */}



      {/*facilities*/}
      <div className="fancy-text-block-three mt-150 mb-10 md-mt-50 md-pb-10">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              <FancyTextBlockThree />
            </div>
          </div>
        </div>
      </div>
      {/*facilities end*/}



      {/* -- Benefits start -- */}
      <div className="container mt-120 md-mt-10">
        <BenefitsDedicated></BenefitsDedicated>
      </div>
      {/* -- Benefits end -- */}



      {/* -- features-- */}
      <div className="block-style-thirtySix lg-container  mt-130 md-mt-10" id="feature">
        <div className="container">
          <Features />
        </div>
      </div>
      {/* -- features end-- */}



      {/* -- coupon-- */}
      <div className="container">
      <CouponDedicated></CouponDedicated>
      </div>
      {/* -- coupon end -- */}



      {/* -- other services-- */}
      <div className="container pt-100 md-pt-100 ">
        <div>
          <h2 className="mainheading" >Other Services</h2>
        </div>
        <div className=" lg-container pt-40  md-pt-10 md-mb-40" id="product">
          <div className=" position-relative">
            <div className="row">
              <div className="product_slider_one arrow-middle-center-otherservices otherservicespadding">
                <OtherServices />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* --other services end-- */}



      {/* -- faq-- */}
      <div className="faq-classic mt-100 pt-60  pb-50 md-pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="title-style-one">
                <h3 className="mainheadingfaq" >Answers to your Dedicated Desk questions</h3>
                <h6 className=" mt-15">Our team is ready to answer your questions.</h6>
              </div>
            </div>
            <div className="col-lg-7">
              <div >
                <FaqDedicatedDesk></FaqDedicatedDesk>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* -- faq end-- */}



      {/* -- footer-- */}
      <div className="footer-bg-wrapper">
        <footer className="theme-footer-one pt-70 md-pt-30">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
          </div>
          <div className="container">
            <div className="bottom-footer-content">
              <CopyRight />
            </div>
          </div>
        </footer>
        {/* -- footer end-- */}
      </div>
    </div>

  );
};

export default DedicatedDesk;