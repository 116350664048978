import { useState } from "react";
import './Benefits.css';
import ContactForm from "../form/ContactForm";
import { Link } from "react-router-dom";

const BenefitsContactUs = () => {
    const [toggle, setToggle] = useState("1");
    return (
        <>
            <div className='row '>
                <div className='col-xl-6 col-lg-6 block-style-thirtySix mobilepadding'> 
                    <div className="benefits-space">
                            <div className=" contactus-btn" onClick={() => setToggle("1")} >
                            {toggle!=="1" ? <h2 className={`subheading-benefits paddingmain paddingmainheading title ${toggle === '1' ? 'active' : ''}`}>Potential Customer</h2>:
                                <h2 className={`subheading-benefits paddingmainheading title ${toggle === '1' ? 'active' : ''}`}>Potential Customer</h2>}
                            {toggle === "1" &&
                                <p className="para-benefits paddingsubheading">Rent your dream workspace in prime locations</p>
                            }</div>

                        <div className=" contactus-btn mt-30" onClick={() => setToggle("2")} >
                            {toggle!=="2" ? <h2 className={`subheading-benefits paddingmain paddingmainheading title ${toggle === '2' ? 'active' : ''}`}>Join our team </h2>:
                                <h2 className={`subheading-benefits paddingmainheading title ${toggle === '2' ? 'active' : ''}`}>Join our team </h2>}
                                {toggle === "2" &&
                                    <p className="para-benefits paddingsubheading">Be a part of our team</p>
                                }</div>

                      <div className=" contactus-btn mt-30" onClick={() => setToggle("3")}>
                      {toggle!=="3" ? <h2 className={`subheading-benefits paddingmain paddingmainheading title ${toggle === '3' ? 'active' : ''}`}>Get More Details</h2>:
                                <h2 className={`subheading-benefits paddingmainheading title ${toggle === '3' ? 'active' : ''}`}>Get More Details</h2>}
                               
                                {toggle === "3" &&
                                    <p className="para-benefits paddingsubheading">
                                  We’d love to hear from you</p>
                                }</div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 '>
                    {toggle === "1" &&
                    <div className="paddinglef">
                    <div className="contactuspadding">
                    {/* <h4 className="mt-20 mainheading" style={{fontSize:"28px"}}>Contact us</h4> */}
                    <h4 className=" mainheading" style={{ fontSize: "28px" }}>Talk to an Expert</h4>
                    <p style={{ fontSize: "16px", marginTop: "10px" }}> Tell us your requirements and out workspace expert
                      will reach out to you at earliest.
                    </p>
                    </div>
                    <ContactForm></ContactForm></div>
                    }
                    {toggle === "2" &&
                    <div className="contact-career">
                    <img src="images/service/career.jpg" alt="plug and play office in coimbatore" className="w-100" />
                    <p className="mainpara" style={{padding:"20px 20px 20px 20px"}}>
                    The hiring process is an opportunity for us to get to know you, and for you to get to know Innovspace. 
                    Learn what to expect from the first moment you think about applying all the way to your first day.
                    </p>
                    <div className="mt-20" style={{padding:"0 20px 30px 20px"}}>
                      <Link to="/career">
                        <button className=" btn-primary">Join Us Today</button></Link>
                    </div>
                    {/* <a className="btn btn-primary mt-20" href="career">Learn more</a> */}
                    </div>
                    }
                    {toggle === "3" &&
                           <div className="paddinglef">
                           <div >
                             <h3 className="mainheading" style={{ fontSize: "28px" }} >Call us</h3>
                             <p className="mt-10" style={{fontSize: "16px" }}>(Mon - Sat, 10 am to 8 pm)
                             <br />Reach out to our sales team directly for any details, pricing or availability</p>
                             <a className="mt-10" href="tel:+91 98406 69944"style={{ fontSize: "16px" }}>+91 98406 69944</a></div>
             
                           <h4 className="mt-20 mainheading" style={{ fontSize: "28px" }} >Email Us</h4>
                           <p className="mt-10" style={{ fontSize: "16px" }}>For sales queries, partnerships, feedback, etc.<br /> Drop us an email</p>
                           <a className="mt-10" href="mailto: admin@innovspace.com"style={{ fontSize: "16px" }} >admin@innovspace.com</a>
                           
                           <h4 className="mt-20 mainheading" style={{ fontSize: "28px" }}>Address</h4>
                           <h4 className="mainheading mt-10" style={{fontSize:"20px"}}> Innovspace Business Solutions</h4>
                           <div className="row address">
                           <div className="col-xl-5 col-lg-5 address-line1">
                           <h4 className="mainheading mt-10" style={{fontSize:"16px"}}> Branch Office 1</h4>
                           <p className="mt-10" style={{ fontSize: "16px" }}>277/1A,<br /> Annamalai Industrial Park, Kalapatti, <br />Coimbatore – 641048.<br /></p>
                           </div>
                           <div className="col-xl-7 col-lg-7 address-line2">
                           <h4 className="mainheading mt-10" style={{fontSize:"16px"}}> Branch Office 2</h4>
                           <p className="mt-10" style={{ fontSize: "16px" }}>No. 11/4,<br /> Pooja Garden, Kalapatti Main Rd, <br />Indira Nagar, Civil Aerodrome Po,<br/> Coimbatore-641014.<br/></p>
                           </div>
                           </div>
                         </div>   
                    }
                </div>
            </div>
        </>
    )
}
export default BenefitsContactUs

