import { useState } from "react";
import './Benefits.css'

const BenefitsDedicated = () => {
    const [toggle, setToggle] = useState("1");
    return (
        <>
            <div className='row '>
                <div className='col-xl-6 col-lg-6 block-style-thirtySix mobilepadding'>
                    <h2 className="mainheading mb-30 md-mt-20" >
                    Here's who will find our dedicated desks ideal
                    </h2>
                    <div className="benefits-space">
                        <ul><li className="benefits-list">

                            <h2 onClick={() => setToggle("1")} className={`subheading-benefits title ${toggle === '1' ? 'active' : ''}`}>Freelancer</h2>
                            {toggle === "1" &&
                                <p className="para-benefits mt-10">Boost your productivity and focus as a freelancer with our dedicated desks, offering a consistent and distraction-free workspace.</p>
                            }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("2")} className={`subheading-benefits mt-20 title ${toggle === '2' ? 'active' : ''}`}>Remote Worker</h2>
                                {toggle === "2" &&
                                    <p className="para-benefits mt-10">Embrace efficient remote work at our coworking space, escaping home distractions. Our dedicated desks offer a collaborative, productive, and balanced work-life environment.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("3")} className={`subheading-benefits mt-20 title ${toggle === '3' ? 'active' : ''}`}>Entrepreneur</h2>
                                {toggle === "3" &&
                                    <p className="para-benefits mt-10">Ignite your entrepreneurial journey in a creative and collaborative space. Our dedicated desks provide the resources to grow your business and network with fellow entrepreneurs.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("4")} className={`subheading-benefits mt-20 title ${toggle === '4' ? 'active' : ''}`}>Creative Professionals</h2>
                                {toggle === "4" &&
                                    <p className="para-benefits mt-10">Unleash your creativity in our dedicated desks designed for artists, designers, and writers. Immerse yourself in a vibrant community and be inspired by the dynamic atmosphere of our coworking space.</p>
                                }</li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 '>
                    {toggle === "1" &&
                        <img src="images/assets/Acc_Image5.png" alt="benefits of coworking spaces for startups"className="benefits-img fadeinout" />
                    }
                    {toggle === "2" &&
                        <img src="images/assets/Acc_Image6.png" alt="business work space"className="benefits-img fadeinout" />
                    }
                    {toggle === "3" &&
                        <img src="images/assets/Acc_Image7.png" alt="coworking startup" className="benefits-img fadeinout" />
                    }
                    {toggle === "4" &&
                        <img src="images/assets/Acc_Image8.png" alt="coworking community" className="benefits-img fadeinout" />
                    }
                </div>
            </div>
        </>
    )
}

export default BenefitsDedicated

