import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What are meeting rooms/conference rooms in a coworking space?",
    desc: `Meeting rooms in a coworking space are dedicated spaces designed for teams or individuals to conduct private meetings, 
    presentations, workshops, or collaborative sessions. They offer a professional setting with various seating arrangements and 
    audiovisual equipment.`,
    expand: "a",
  },
  {
    title: "How can I reserve a meeting room in a coworking space?",
    desc: `To reserve a meeting room, you usually need to inquire with our admin team or use your booking sheet. 
    Provide the desired date, time, and duration of your meeting. They will guide you through the reservation process and inform you 
    about any additional charges, if applicable.`,
    expand: "b",
  },
  {
    title: "What amenities are typically available in meeting rooms?",
    desc: `Meeting rooms often come equipped with amenities such as audiovisual equipment (e.g., projectors, screens), whiteboards or flip charts, 
    high-speed internet access, conference call capabilities, comfortable seating, and adequate lighting. Based upon the requirement we may also 
    provide additional features like video conferencing facilities or catering services.`,
    expand: "c",
  },
  {
    title: "Are meeting rooms available for external bookings by non-members?",
    desc: `Yes, meeting rooms can be booked by non-members for an additional fee. This allows individuals or organizations outside of the 
    coworking community to utilize the professional meeting facilities provided.`,
    expand: "d",
  },
  {
    title: "Are there additional charges for booking meeting rooms in a coworking space?",
    desc: `As an innovspace coworking community member, you receive complimentary access to meeting rooms for a specified duration based on your space.
     If you exceed this limit, charges may apply based on the meeting room's policies. Additional costs depend on factors like room size, booking duration, and requested services. Contact our team for detailed pricing information.`,
    expand: "e",
  },
  {
    title: "Can I invite guests or clients to a meeting room in a coworking space?",
    desc: `Yes, you can invite guests or clients to a meeting room. We have specific policies or additional charges for non-members 
    attending meetings. Ensure that you communicate the details of your meeting to our team and provide necessary information to your guests for a smooth entry.`,
    expand: "f",
  },

];

const FaqMeetingRoom = () => {
  return (
    <div className="accordion-style-four ">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqMeetingRoom;
