import React from "react";

const FeaturesDayPass = () => {
  return (
    <div className="row contentswip">
      <div className="col-xl-6 col-lg-7 order-lg-last" data-aos="fade-left">
        <div className="ps-lg-5 mt-70 md-mt-30">
          <div className="title-style-fourteen">
            <h3 className="mainheading">Unleash Flexibility with our "Day Pass"</h3>
          </div>
          <div className="tag-line">
          </div>
          <p className="mainpara">Experience the liberty to work in our versatile workspaces with our "Day Pass". This option allows you full-day access to our facilities and services whenever you need them. Perfect for the on-the-go professional!</p>
          <p className="mainpara pt-20">Contact us today to learn more about our Day Pass options and book your space in our thriving coworking community.</p>
        </div>
      </div>
      <div className="col-xl-6 col-lg-5 text-center text-lg-left order-lg-first " data-aos="fade-right" >
        <div className="illustration-holder d-inline-block md-mt-30" >
          <img src="images/assets/day_pass.png" alt="" className="w-100" />
        </div>
      </div>
    </div>
  );
};
export default FeaturesDayPass;