import React from 'react'
import Modal from "react-modal";
import { useState } from "react";
import HeaderPopupForm from "../../components/form/HeaderPopupForm";

const CouponDay = () => {
    const [isOpen, setIsOpen] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  return (
    <div className="container coupon-background1 mt-100 md-mt-70">
    <div className="row">
      <div className="col-lg-6 col-xl-6 mainpaddings">
        <h3 className="cardheader2 mt-30" style={{ fontSize: "18px", fontWeight: "500" }}>INNOVSPACE</h3>
        <h3 className="mainheading mt-10 parawidth"> Flexible Coworking Space, now for just <span style={{color:"#008dd0"}}>₹500/ Day</span></h3>
        <h3 className="mt-10 cardheader2">With the "Day Pass", you gain access to high-speed internet, comfortable and adjustable workspaces, and other amenities that make your workday seamless. It's a no-commitment opportunity to experience the stimulating, collaborative environment of our coworking space, coupled with the freedom to work as you please.</h3>
        <h3 className="mt-10 cardheader2">Whether you're meeting clients, working on a one-off project, or simply want a change of scenery, our "Day Pass" offers the ideal solution. Try it once, and you'll realize it's not just a workspace, it's a space that works for you.</h3>
        {/* <button className="btn btn-primary mt-20">Book Now</button> */}
        <button className="btn-primary mt-20 notification" onClick={toggleModalOne}>
        Book Now
          </button>
      </div>
      <div className="col-lg-6 col-xl-6 parent">
        {/* <img src="images/assets/home_1.png" alt="no image" className="child1 child"/> */}
      </div>
    </div>
    <Modal
          isOpen={isOpen}
          onRequestClose={toggleModalOne}
          contentLabel="My dialog"
          className="custom-modal  modal-contact-popup-one"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >

          <div className="box_inner ">
            <main className="main-body box_inner modal-content clearfix md-mb-30">
              <button className="close" onClick={toggleModalOne}>
                <img src="images/icon/close.svg" alt="close" />
              </button>
              {/* End close icon */}
           
              {/* /.left-side */}


              <div className="right-side">
                <div className="size">
                  <h3 className="mainheading mb-20">Contact us </h3>
                  <HeaderPopupForm /></div>
              </div>
              {/*  /.right-side */}
            </main>
            {/* /.main-body */}
          </div>
        </Modal>
  </div>
  )
}

export default CouponDay