import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is the process of Private Limited Company Registration?",
    desc: `Private Limited Company Registration establishes a separate entity with limited liability protection for shareholders. This process includes selecting a name, preparing documents, filing the registration application, and obtaining necessary approvals.`,
    expand: "a",
  },
  {
    title: "What is LLP Registration and how is it different from Private Limited Company Registration?",
    desc: `LLP Registration establishes a hybrid business structure called a Limited Liability Partnership. It offers partners limited liability protection while combining the advantages of a separate legal entity with flexible management and tax benefits.`,
    expand: "b",
  },
  {
    title: "Why should I register my business as a Private Limited Company or LLP?",
    desc: `Registering your business as a Private Limited Company or LLP provides benefits like limited liability protection, enhanced funding opportunities, increased credibility, and potential tax advantages. It establishes a recognized legal structure for your business.`,
    expand: "c",
  },
  {
    title: "What are the key requirements for Private Limited Company or LLP Registration?",
    desc: `The requirements for Private Limited Company or LLP Registration typically include documents such as identity proof, address proof, directors/partners details, Memorandum of Association (MOA), and Articles of Association (AOA). The specific requirements may vary depending on the jurisdiction and local regulations.`,
    expand: "d",
  },
  {
    title: "Why is bookkeeping important for businesses, and what does it involve?",
    desc: `Bookkeeping is vital for businesses as it ensures accurate financial records, tracks income and expenses, reconciles accounts, and generates financial statements. It aids in monitoring cash flow, adhering to tax regulations, making informed decisions, and ensuring financial transparency.`,
    expand: "e",
  },
  {
    title: "Can I handle bookkeeping myself, or should I outsource it to professionals?",
    desc: `You can choose to handle bookkeeping yourself if you have the skills and time. Yet, outsourcing to professionals provides benefits like accounting expertise, time savings, increased accuracy, and access to specialized software for efficient financial management.`,
    expand: "f",
  },
];

const FaqCorporateService = () => {
  return (
    <div className="accordion-style-four ">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqCorporateService;
