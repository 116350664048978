import React from "react";







const FancyTextMeetingRoom = () => {
  return (
    <div className="row no-gutters">
      <div className="col-lg-12">
        <div className="feature-content light-bg">
          {/* <div className="header1 mb-30" data-aos="fade-up" data-aos-duration="1200"> */}
          <div className="header1 mb-30">
            <h3 className="mainheading">Facilities & Services Included</h3>
          </div>
          {/* /.header */}
          <div className="row mt-15">
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="100"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/wifi.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">High-speed Internet ( Wi-Fi & LAN)</h5>
                <p className="tail">Experience uninterrupted connectivity with our blazing-fast, reliable internet service.</p>
              </div>
              {/* /.text-meta */}
            </div>
            {/* /.feature-list */}
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/projector.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Audio Visual Setup</h5>
                <p className="tail">Boost your presentations' impact with our effortless projector setups, transforming your ideas into compelling visuals.</p>
              </div>
              {/* /.text-meta */}
            </div>

            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/reception.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head"> Boxed Catering Services</h5>
                <p className="tail"> Indulge in the convenience of our boxed catering, featuring a selection of delicious and nourishing meals to fuel your day.</p>
              </div>
              {/* /.text-meta */}
            </div>
          </div>


          <div className="row mt-20 md-mt-10">
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="100"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/glassboard.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Glass board with markers</h5>
                <p className="tail">
                Elevate presentations with our glass boards and markers to turn ideas into visible solutions.
                </p>
              </div>
              {/* /.text-meta */}
            </div>
            {/* /.feature-list */}
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-dela
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/powerbackup.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Power backup (Diesel generator)</h5>
                <p className="tail">
                  Our average document delivery time is less than 3 working days</p>
              </div>
              {/* /.text-meta */}
            </div>
            <div className="feature-list d-flex col-lg-4 col-md-6"

            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/air.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Air conditioning and Drinking water</h5>
                <p className="tail">
                Ensure your comfort while you work with our efficient and consistent air conditioning.
                </p>
              </div>
              {/* /.text-meta */}
            </div>
          </div>
          {/* /.feature-list  */}
        </div>
        {/* /.feature-content */}
      </div>
    </div>
  );
};

export default FancyTextMeetingRoom;