import { useState } from "react";
import './Benefits.css'

const BenefitsVirtualOffice = () => {
    const [toggle, setToggle] = useState("1");
    return (
        <>
            <div className='row '>
                <div className='col-xl-6 col-lg-6 block-style-thirtySix mobilepadding'>
                    <h2 className="mainheading mb-30 md-mt-20 md-mb-10" >
                        Key Features and Benefits
                    </h2>
                    <div className="benefits-space">
                        <ul><li className="benefits-list">

                            <h2 onClick={() => setToggle("1")} className={`subheading-benefits title ${toggle === '1' ? 'active' : ''}`}>Prestigious Business Address</h2>

                            {toggle === "1" &&
                                <p className="para-benefits mt-10"> Boost your reputation with a prime business address in a sought-after location, impressing clients, partners, and investors on your website and marketing materials.
                                </p>
                            }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("2")} className={`subheading-benefits mt-20 title ${toggle === '2' ? 'active' : ''}`}>Mail Handling and Forwarding</h2>
                                {toggle === "2" &&
                                    <p className="para-benefits mt-10"> Let our team handle your mail, packages, and deliveries, forwarding them to your chosen address or securely storing them for your convenience.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("3")} className={`subheading-benefits mt-20 title ${toggle === '3' ? 'active' : ''}`}>Dedicated Phone Number and Call Answering</h2>
                                {toggle === "3" &&
                                    <p className="para-benefits mt-10">
                                        Stay connected with clients using a dedicated business phone number. Our receptionists answer calls professionally, forwarding messages via email, text, or voicemail for a personalized experience.
                                    </p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("4")} className={`subheading-benefits mt-20 title ${toggle === '4' ? 'active' : ''}`}>Networking Opportunities </h2>
                                {toggle === "4" &&
                                    <p className="para-benefits mt-10">
                                        Join our Virtual Office community to network with professionals, attend exclusive events, workshops, and seminars, fostering valuable connections and collaborations for business growth.
                                    </p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("5")} className={`subheading-benefits mt-20 title ${toggle === '5' ? 'active' : ''}`}>Flexibility and Cost Savings</h2>
                                {toggle === "5" &&
                                    <p className="para-benefits mt-10"> Experience unmatched flexibility with our Virtual Office service—work from anywhere while projecting a professional image. Say goodbye to long-term leases and overhead costs, as you reduce expenses and allocate resources more efficiently.</p>
                                }</li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 '>
                    {toggle === "1" &&
                        <img src="images/assets/Acc_Image18.png" alt="co working space meeting room" className="benefits-img fadeinout" />
                    }
                    {toggle === "2" &&
                        <img src="images/assets/Acc_Image19.png" alt="co working community" className="benefits-img fadeinout" />
                    }
                    {toggle === "3" &&
                        <img src="images/assets/Acc_Image20.png" alt="co working com" className="benefits-img fadeinout" />
                    }
                    {toggle === "4" &&
                        <img src="images/assets/Acc_Image21.png" alt="about co working space" className="benefits-img fadeinout" />
                    }
                    {toggle === "5" &&
                        <img src="images/assets/Acc_Image22.png" alt="co working space" className="benefits-img fadeinout" />
                    }
                </div>
            </div>
        </>
    )
}

export default BenefitsVirtualOffice

