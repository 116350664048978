import React from "react";
import FancyFeatureTewentyEight from "../features/FancyFeatureTewentyEight";
import FancyFeatureThirtyOne from "../features/FancyFeatureThirtyOne";

const featureList = [
    {
        icon: "bulp",
        title: "Identity",
        description: ` Elevate your business image by capitalizing on the prime location and trusted brand of Innovspace.`,
    },
    {
        icon: "hand",
        title: "Growth",
        description: `With our flexible and adaptable shared office spaces, we support your growth journey and make business expansion seamless.`,
    },
    {
        icon: "network",
        title: "Network",
        description: `Dive into a world of networking opportunities with exclusive industry-related sessions, competitions, and social events, all designed to expand your connections and boost your business growth.`,
    },
];

const FancyTextHome = () => {
    return (
        <div className="container indi-innov">
            <div className="block-style-thirty mt-170 mb-80 md-mt-50">
                <div className="row contentswip">
                    <div className="col-xl-6 col-lg-6 imagepaddinghome" >
                        <img src="images/assets/innovs-h.png" alt="feature"></img>
                    </div>
                    <div className="col-xl-6 col-lg-6">
                        <div className="text-block1 md-pb-20">
                            <img src="images/shape/shape01_green.png" alt="feature" class="cs-screen1 screen-two" />
                            <div className="title-style-nine mb-20 md-mb-20">
                                <h3 class="mainheading pb-20 md-pt-20 pt-20">Fusing Innovative Business Practices with Ideal Workspaces</h3>
                                <p className="mainpara" style={{ width: "100%", padding: "0 0 10px", margin: "0px 0 0", lineHeight: "22px", fontSize:"16px" }}> 11 Innovspace is a professionally blended Business Accelerator cum Coworking space in Coimbatore, offering a rich corporate work setup for Startups, Private companies, Freelancers, NGOs, CXOs, Business Consultants and Mainstream organisations at the most comfortable budget</p>
                            </div>
                            <ul className="feature-list-one">
                                {featureList.map((list, i) => (
                                    <li key={i}>
                                        <img
                                            src={`images/assets/${list.icon}.svg`}
                                            alt="icon"
                                            className="icon"
                                        />
                                        <strong>{list.title}</strong>
                                        <span >{list.description}</span>
                                    </li>
                                ))}
                            </ul>
                            {/* End .feature-list-one */}

                        </div>
                        {/* <!-- /.text-block --> */}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FancyTextHome;
