import { useState } from "react";
import './Benefits.css'

const BenefitsHotSeat = () => {
    const [toggle, setToggle] = useState("1");
    return (
        <>
            <div className='row '>
                <div className='col-xl-6 col-lg-6 block-style-thirtySix mobilepadding'>
                    <h2 className="mainheading mb-30 md-mt-20" >
                    Who is the Meeting Room suitable for?
                    </h2>
                    <div className="benefits-space">
                        <ul><li className="benefits-list">

                            <h2 onClick={() => setToggle("1")} className={`subheading-benefits title ${toggle === '1' ? 'active' : ''}`}>Versatile Meeting Spaces </h2>

                            {toggle === "1" &&
                                <p className="para-benefits mt-10"> Versatile meeting rooms and spaces for diverse groups. From intimate to spacious settings, customize your perfect environment.
                                </p>
                            }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("2")} className={`subheading-benefits mt-20 title ${toggle === '2' ? 'active' : ''}`}>Cutting-Edge Technology </h2>
                                {toggle === "2" &&
                                    <p className="para-benefits mt-10">Elevate meetings with advanced rooms: seamless communication, impactful presentations, high-speed internet, wireless sharing, top-notch audiovisuals.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("3")} className={`subheading-benefits mt-20 title ${toggle === '3' ? 'active' : ''}`}> Professional Ambiance </h2>
                                {toggle === "3" &&
                                    <p className="para-benefits mt-10">
                                 Inspiring meeting rooms with professional ambiance. Impeccable decor, ergonomic furniture, and natural light enhance productivity. Impress clients with attention to detail.
</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("4")} className={`subheading-benefits mt-20 title ${toggle === '4' ? 'active' : ''}`}>Comprehensive Amenities </h2>
                                {toggle === "4" &&
                                    <p className="para-benefits mt-10">
                                     Our meeting rooms are equipped with essential amenities, including whiteboards, flip charts, markers, and projectors, allowing for effective brainstorming and visual presentations. 
                                    </p>
                                }</li>

                              <li className="benefits-list">
                                <h2 onClick={() => setToggle("5")} className={`subheading-benefits mt-20 title ${toggle === '5' ? 'active' : ''}`}>Elevate Your Meetings with Our Meeting Rooms</h2>
                                {toggle === "5" &&
                                    <p className="para-benefits mt-10"> Enhance business success with our premium meeting rooms. Elevate collaboration, innovation, and growth in our coworking space.</p>
                                }</li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 '>
                    {toggle === "1" &&
                        <img src="images/assets/Acc_Image14.png" alt="co working space meeting room"className="benefits-img fadeinout" />
                    }
                    {toggle === "2" &&
                        <img src="images/assets/Acc_Image11.png"  alt="co working community"className="benefits-img fadeinout" />
                    }
                    {toggle === "3" &&
                        <img src="images/assets/Acc_Image15.png"  alt="co working com" className="benefits-img fadeinout" />
                    }
                    {toggle === "4" &&
                        <img src="images/assets/Acc_Image16.png"  alt="about co working space" className="benefits-img fadeinout" />
                    }
                      {toggle === "5" &&
                        <img src="images/assets/Acc_Image17.png"  alt="co working space" className="benefits-img fadeinout" />
                    }
                </div>
            </div>

        </>
    )
}

export default BenefitsHotSeat

