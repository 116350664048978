import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const BlogContent = [

  {
    img: "hotseat_1",
    tag: "Dedicated Desks",
    title: `In a dynamic, shared office space, you can rent as many desks as you need. Included are a business address and storage options.`,
    routePath: "/blog-details",
    dealyAnimation: "50",
    alt:"co working office",
  },
  {
    img: "hotseat_2",
    tag: "Private / Team Office",
    title: `Dedicated workspaces for those professionals working on long-term projects and want a seat specifically reserved for them.
    `,
    routePath: "/blog-details",
    dealyAnimation: "100",
    alt:"shared office space",
  },
  {
    img: "hotseat_3",
    tag: "Day Pass",
    title: `Dedicated workspaces for those professionals working on long-term projects and want a seat specifically reserved for them.`,
    routePath: "/blog-details",
    dealyAnimation: "50",
    alt:"startup coworking space",
  },
  {
    img: "hotseat_4",
    tag: "Meeting Room",
    title: `Dedicated workspaces for those professionals working on long-term projects and want a seat specifically reserved for them.`,
    routePath: "/blog-details",
    dealyAnimation: "100",
    alt:"co work",
  },


  
];

const ImageGalleryDayPass = () => {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider {...settings}>
        {/* {vrProducts.map((product) => (
          <div className="item" key={product._id}>
            <div className="block-style-thirtySeven">
              <div className="img-meta d-flex align-items-center justify-content-center">
                <img
                  src={`images/media/${product.image}.png`}
                  alt="vr product"
                />
              </div>
              <h4 className="p-title mt-25">
                <a href={product.productLink}>{product.title}</a>
              </h4>
              <div className="price mt-15 mb-15">${product.price}</div>
              <ul className="d-flex justify-content-center rating">
                {product.rating.map((star, i) => (
                  <li key={i}>
                    <i className={`fa ${star}`} aria-hidden="true"></i>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        ))} */}
        {BlogContent.map((item, i) => (
        <div
          className="col-lg-4 col-md-6 md-mt-20 md-pb-10"
          // data-aos="fade-up"
          // data-aos-duration="1200"
          key={i}
          data-aos-delay={item.dealyAnimation}
        >

          <div className="card background-card">

       
            <img
              src={`images/hotseat/${item.img}.jpg`}
              
              alt={item.alt}
              className="img-holder"
            />
            {/* <div class="p2">
            <h4 class="cardhead">
            {item.tag}</h4>
            
              <p className="title-font">
                {item.title}
              </p>
          
          </div> */}
         
        </div>
        </div>
      ))}
      </Slider>
    </>
  );
};

export default ImageGalleryDayPass;
