
// import React, { useState, useEffect } from 'react';

// const ImageGallery = ({ images }) => {
//   const [currentImageIndex, setCurrentImageIndex] = useState(0);
//   const [scrollCounter, setScrollCounter] = useState(0);

//   useEffect(() => {
//     const handleScroll = (event) => {
//       if (event.deltaY === 0) {
//         return;
//       }

//       event.preventDefault();

//       setScrollCounter((prevCounter) => prevCounter + 1);

//       if (scrollCounter >= 5) {
//         if (event.deltaY > 0) {
//           // Scrolling down, move to the next image
//           setCurrentImageIndex((prevIndex) =>
//             prevIndex === images.length - 1 ? prevIndex : prevIndex + 1
//           );
//         } else {
//           // Scrolling up, move to the previous image
//           setCurrentImageIndex((prevIndex) =>
//             prevIndex === 0 ? prevIndex : prevIndex - 1
//           );
//         }
//         setScrollCounter(0);
//       }
//     };

//      window.addEventListener('wheel', handleScroll, { passive: false });

//     return () => {
//       window.removeEventListener('wheel', handleScroll);
//     };
//   }, [images, scrollCounter]);

//   return (
//     <div className="image-gallery">
//       <div className='row'>
//       <div
//         className="image-gallery-inner col-4"
//         style={{
//           transform: `translateX(-${currentImageIndex * 37.333}%)`,
//           width: `${images.length * 30.333}%`
//         }}
//       >
//         {images.map((image, index) => (
//           <img
//             key={index}
//             src={image}
//             alt={`Image ${index}`}
//             className={`image-gallery-item ${
//               index === currentImageIndex ? 'active' : ''
//             }`}
//           />
//         ))}
//       </div>
//       </div>
//     </div>
//   );
// };

// export default ImageGallery;



import React, { useState, useEffect } from 'react';

const ImageGallery = ({ images }) => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [scrollCounter, setScrollCounter] = useState(0);
  const [hovered, setHovered] = useState(false);

  useEffect(() => {
    const handleScroll = (event) => {
      if (event.deltaY === 0) {
        return;
      }

      event.preventDefault();

      setScrollCounter((prevCounter) => prevCounter + 1);

      if (scrollCounter >= 5) {
        if (event.deltaY > 0) {
          // Scrolling down, move to the next image
          setCurrentImageIndex((prevIndex) =>
            prevIndex === images.length - 1 ? prevIndex : prevIndex + 1
          );
        } else {
          // Scrolling up, move to the previous image
          setCurrentImageIndex((prevIndex) =>
            prevIndex === 0 ? prevIndex : prevIndex - 1
          );
        }
        setScrollCounter(0);
      }
    };

    if (hovered) {
      window.addEventListener('wheel', handleScroll, { passive: false });
    }

    return () => {
      window.removeEventListener('wheel', handleScroll);
    };
  }, [hovered, images, scrollCounter]);

  const handleImageLoad = () => {
    if (currentImageIndex === images.length - 1) {
      setHovered(true);
    }
  };

  return (
    <div className="image-gallery"       onMouseEnter={() => setHovered(true)}
    onMouseLeave={() => setHovered(false)}>
      
        <div
          className="image-gallery-inner"
          style={{
            transform: `translateX(-${currentImageIndex * 37.333}%)`,
            width: `${images.length * 30.333}%`
          }}
          // onMouseEnter={() => setHovered(true)}
          // onMouseLeave={() => setHovered(false)}
        >
          {images.map((image, index) => (
            <img
              key={index}
              src={image}
              alt={`Image ${index}`}
              className={`image-gallery-item ${
                index === currentImageIndex ? 'active' : ''
              }`}
              onLoad={handleImageLoad}
           
            />
          ))}
        </div>

    </div>
  );
};

export default ImageGallery;




