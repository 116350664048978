import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import BlogInner from "../../../components/blog/BlogInner";
import "./blog.scss";

const Blog = () => {
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
          Blogs - Innovspace - Business Accelerator | Coworking Space
        </title>
        <meta name="description" content="coworking space coimbatore" />
        <meta
          name="robots"
          content="max-snippet:-1, max-image-preview:large, max-video-preview:-1"
        />
        <link
          rel="canonical"
          href="https://web.archive.org/web/20230609212730/https://www.innovspace.com/blogs/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta
          property="og:title"
          content="Blogs - Innovspace - Business Accelerator | Coworking Space"
        />
        <meta property="og:description" content="coworking space coimbatore" />
        <meta
          property="og:url"
          content="https://web.archive.org/web/20230609212730/https://www.innovspace.com/blogs/"
        />
        <meta
          property="og:site_name"
          content="Innovspace - Business Accelerator | Coworking Space"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="coworking space coimbatore" />
        <meta
          name="twitter:title"
          content="Blogs - Innovspace - Business Accelerator | Coworking Space"
        />
      </Helmet>
      {/* End Page SEO Content */}
      <Header />
      {/* End Header */}

      {/* 	=============================================
            Fancy Hero One
        ==============================================  */}
      <div className="Blog-hero-one md-pb-10">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-lg-12 m-auto">
              <h1 className="blogseo">Check our company inside story</h1>
            </div>
            <div className="col-lg-12 m-auto">
              <p className="mainpara">
                You will find here our company news and latest update
              </p>
            </div>
          </div>
        </div>
        <div className="bubble-one"></div>
        <div className="bubble-two"></div>
        <div className="bubble-three"></div>
        <div className="bubble-four"></div>
        <div className="bubble-five"></div>
        <div className="bubble-six"></div>
      </div>
      {/* /.fancy-hero-one */}
      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      {/* 
            <div className="blogs-page-bg">
                <div className="container">
                    <div className="row">
                      
                        <div className="col-lg-8 blog-title width-lg">
                            <img src="images/blog/blognew.jpg" alt="startup office spaces" class="image-meta"></img>
                            <p>Apr 22, 2024</p>
                            <a href="/blog-v7">
                                <h2>Exploring the Gig Economy and Its Impact on Workspace Trends </h2></a>
                          
                        </div>
                        <div className="col-lg-4 col-md-6 md-mt-20">
                            
                        </div>
                    </div>
                </div>
            </div> */}

      {/* =====================================================
            BlogInner Start
        ===================================================== */}

      <div className=" mb-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-10 blog-title1 ">
              {/* <h3 className="mainheading" style={{ textAlign: "left" }}> Latest Blogs</h3> */}
              <div className="feature-blog-threeblog ">
                <BlogInner></BlogInner>
              </div>
            </div>
            <div className="col-lg-2 "></div>
          </div>
        </div>
      </div>

      {/* =====================================================
            BlogInner End
        ===================================================== */}

      <footer className="theme-footer-one pt-70 md-pt-30">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default Blog;
