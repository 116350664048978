

import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import emailjs from '@emailjs/browser';
import axios from 'axios';
import { useState, useRef} from "react";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import ReCAPTCHA from "react-google-recaptcha"


function AboutForm() {

  const [name, setname] = useState("");
  const [phone, setphone] = useState("");
  const [email, setemail] = useState("");
  const [company, setcompany] = useState("");
  const [natureofbussiness, setnatureofbussiness] = useState("");
  const [city, setcity] = useState("");
  const [anyothers, setanyothers] = useState("");
  const [duration, setduration] = useState("");
  const [startfrom, setstartfrom] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [isChecked, setIsChecked] = useState(false);
  const [checkboxError, setCheckboxError] = useState('');
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [checkbox, setcheckbox] = useState("");
  const captchaRef = useRef(null)
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaerror, setcaptchaerror] = useState('');


  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setcheckbox(event.target.value);
  };
  const handlename = (event) => {
    setname(event.target.value);
  };
  const handlephone = (event) => {
    setphone(event.target.value);
  };
  const handleemail = (event) => {
    setemail(event.target.value);
  };
  const handlecompany = (event) => {
    setcompany(event.target.value);
  };
  const handlenatureofbussiness = (event) => {
    setnatureofbussiness(event.target.value);
  };
  const handlecity = (event) => {
    setcity(event.target.value);
  };
  const handleanyothers = (event) => {
    setanyothers(event.target.value);
  };
  const handleduration = (event) => {
    setduration(event.target.value);
  };


  const validateForm = () => {
    const errors = {};
    if (!isChecked) {
      errors.checkbox = 'Checkbox must be checked';
      setCheckboxError('Checkbox must be checked');
    } else {
      setCheckboxError('');
    }

    if (name.trim() === "") {
      errors.name = "Name is required";
    }



    if (phone.trim() === "") {
      errors.phone = "Phone number is required";
    } else if (!/^[0-9]{10}$/.test(phone)) {
      errors.phone = "Invalid phone number";
    }
    if (email.trim() === "") {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Invalid email format";
    }
    if (company.trim() === "") {
      errors.company = "Company is required";
    }
    if (natureofbussiness.trim() === "") {
      errors.natureofbussiness = "Nature of business is required";
    }
    // if (city.trim() === "") {
    //   errors.city = "City is required";
    // }
    // if (duration.trim() === "") {
    //   errors.duration = "Duration is required";
    // }
    // if (anyothers.trim() === "") {
    //   errors.anyothers = "Any Others is required";
    // }
    if (captchaerror === '' && captchaToken ===null) {
      setcaptchaerror("Captcha is required")
  }





    setFormErrors(errors);
    console.log("Object.keys(errors).length ", Object.keys(errors).length )
    return Object.keys(errors).length === 0;
  };

/* recaptcha s */ 
  const RecaptchaChange = (value) =>{
    const token =captchaRef.current.getValue();
    setCaptchaToken(token)
    setcaptchaerror('')
    console.log('sss',token)
    //     setcaptchaerror('')
    // captchaRef.current.getResponse().then(res => {
    //     setCaptchaToken(res)
    //     setcaptchaerror('')
    // })

}
/* recaptcha e */ 




  const handleSubmit = (event) => {
    event.preventDefault();
    const token =captchaRef.current.getValue();
    if (validateForm()) {
       
      if (captchaToken !==null){

      const config = {
        headers: {
          'accept': ' application/json',
          'api-key': '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',
          'content-type': 'application/json'
        }
      };
      const url = "https://api.brevo.com/v3/smtp/email";

      const data = {

        "sender": {
          "name":"Client Enquiry",
           "email":"admin@innovspace.com"
        },
        "to": [
          {
            "email": "admin@innovspace.com",
            "name": "Innovspace"
          }
        ],
        "subject": "Innovspace Enquiry Form",
        "htmlContent": `<html><head></head><body>
            <h3>About Us Page Form</h3>
            <p>Name: ${name}</p>
            <p>Phone No: ${phone}</p>
            <p>Email: ${email}</p>
            <p>Company: ${company}</p>
            <p>Nature Of Bussiness: ${natureofbussiness}</p>
            <p>City: ${city}</p>
            <p>Start From: ${startfrom}</p>
            </body></html>`

      }

      axios.post(url, data, config)
        .then(response => {
          console.log("data", response.data);
        })
        .catch(error => {
          console.log("error", error);
        });

      // Perform form submission or further processing
      setSubmissionStatus('success');
      console.log("Form submitted successfully!");
      setname("");
      setphone("");
      setemail("");
      setcity("");
      setcompany("");
      setnatureofbussiness("");
      setanyothers("");
      setduration("");
      setcheckbox("");
      setIsChecked(false);
      setcaptchaerror("");
      setstartfrom(null)
      setCaptchaToken(null)
      captchaRef.current.reset();
                setTimeout(() => {
                    setSubmissionStatus(null);
                    setCaptchaToken(null);
                }, 5000);
      }
    } else {
      console.log("Form validation failed!");
    }
  
  };

  return (

    <div >
      <div class="container">
        <h3 className="mainheading" style={{ paddingBottom: "5px" }}>Enquire Now</h3>
        <p className="mainpara mb-20" style={{ color: "#444444" }}>Space Interested</p>
        <form onSubmit={handleSubmit}>
          <div class="row">
            <div class="form-group col-md-6">

              <TextField id="outlined-basic" label="Name" variant="outlined" onChange={handlename} value={name}
                error={!!formErrors.name}
                helperText={formErrors.name}
                size="small"
                name="name"
                fullWidth

              />

            </div>
            <div class="form-group col-md-6 md-mt-20">

              <TextField id="outlined-basic" label="Phone Number" variant="outlined" onChange={handlephone} value={phone}
                error={!!formErrors.phone}
                helperText={formErrors.phone}
                size="small"
                fullWidth
                name="phone"

              />
              {/* </Box>
      */}
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">

              <TextField id="outlined-basic" label="Email Id" variant="outlined" onChange={handleemail} value={email}
                error={!!formErrors.email}
                helperText={formErrors.email}
                size="small"
                fullWidth
                name="email"
                style={{ marginTop: "20px" }}
              />
              {/* </Box> */}
            </div>
            <div class="form-group col-md-6">
              {/* 
              <Box
                component="form"
                sx={{
                  '& > :not(style)': { mt:1,mb:1, width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
              <TextField id="outlined-basic" label="Company" variant="outlined" onChange={handlecompany} value={company}
                error={!!formErrors.company}
                helperText={formErrors.company}
                size="small"
                fullWidth
                name="company"
                style={{ marginTop: "20px" }}
              />
              {/* </Box> */}
            </div>
          </div>
          <div class="row">
            <div class="form-group col-md-6">

              {/* <Box
                component="form"
                sx={{
                  '& > :not(style)': {mt:1,mb:1,  width: '100%' },
                }}
                noValidate
                fullWidth
                autoComplete="off"
              > */}
              <TextField id="outlined-basic" label="Nature of business" variant="outlined" onChange={handlenatureofbussiness} value={natureofbussiness}
                error={!!formErrors.natureofbussiness}
                helperText={formErrors.natureofbussiness}
                size="small"
                fullWidth
                name="nature"
                style={{ marginTop: "20px" }}
              />
              {/* </Box> */}
            </div>
            <div class="form-group col-md-6">
              {/* 
            <Box
                component="form"
                sx={{
                  '& > :not(style)': {mt:1,mb:1,  width: '100%' },
                }}
                noValidate
                autoComplete="off"
              > */}
              <TextField id="outlined-basic" label="City" variant="outlined" onChange={handlecity} value={city}
                error={!!formErrors.city}
                helperText={formErrors.city}
                size="small"
                fullWidth
                name="city"
                style={{ marginTop: "20px" }}
              />
              {/* </Box> */}
            </div>
          </div>
          {/* <div class="row">
            <div class="form-group col-md-12">
              <TextField id="outlined-basic" label="Any Others" variant="outlined" onChange={handleanyothers} value={anyothers}
                error={!!formErrors.anyothers}
                helperText={formErrors.anyothers}
                size="small"
                fullWidth
                name="anyothers"
                style={{ marginTop: "20px" }}
              />
              

            </div>
          </div> */}

          <div class="row">
            <div class="form-group col-md-6" style={{ marginTop: "22px" }}>

              {/* <Box
    component="form"
    sx={{
      '& > :not(style)': {mt:1,mb:1,  width: '100%' },
    }}
    noValidate
    autoComplete="off"
  > */}
              {/* <TextField id="outlined-basic" label="Duration" variant="outlined" onChange={handleduration} value={duration}
                error={!!formErrors.duration}
                helperText={formErrors.duration}
                size="small"
                fullWidth
                name="duration"
                style={{ marginTop: "20px" }}
              /> */}
              <DatePicker label="Start From" slotProps={{ textField: { size: 'small' } }}
                value={startfrom} onChange={(newValue) => setstartfrom(newValue)}
                size="small"
              />
              {/* </Box> */}
            </div>
          </div>

          {/* <div class="mt-2">
            

            <p style={{fontSize:"15px"}}>By Selecting this you agree to our privacy policy.<span className='privatepolicy'> Private policy</span></p>

       
          </div> */}

          <div class="mt-20">
            <span>
              <div class="form-check">
                <input class="form-check-input" type="checkbox" value={checkbox} id="flexCheckDefault" checked={isChecked}
                  onChange={handleCheckboxChange} />
                <p style={{ fontSize: "13px" }}>By Selecting this, you agree to our <a href='/terms-conditions' target='blank' className='privatepolicy'> Terms & Conditions.</a></p>
              </div>
            </span>
            {checkboxError && (
              <FormHelperText error>{checkboxError}</FormHelperText>
            )}
            {submissionStatus === 'success' && (
              <FormHelperText style={{ color: 'green', fontSize:'16px', margin: '15px 0px' }}>Form submitted successfully!</FormHelperText>
            )}
          </div>
          <div class="mt-20">
                    <ReCAPTCHA sitekey={"6LeQCf0oAAAAAGbw4unaulP784of1ZMykUK_6dq-"} ref={captchaRef}  onChange={()=>RecaptchaChange()} 
                     />
                     <div style={{color:'#D32F2F', fontSize:"12px", fontFamily:'Roboto'}}> {captchaerror} </div>
                    </div>
          <div class="mt-20">
            <button type="submit" class="btn-primary" >Enquiry Now</button>

          </div>

        </form>

      </div>
    </div>
  );
}

export default AboutForm;
