import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import CounterFive from "../../../../components/counter/CounterFive";
import TeamThree from "../../../../components/team/TeamThree";
import FeaturesA from "../../../../components/form-survey-landing/FeaturesA";
import FeatureFour from "../../../../components/features/FeatureFour";
import { useState } from 'react';
import { Timeline } from "../../../all-home-pages/Timeline";
import BrandFour from "../../../../components/brand/BrandFour";
import AboutForm from "../../../../components/form/AboutForm";
import BranchOffice from "../../../../components/block-slider/BranchOffice";



const About1 = () => {

    const myList = [
        {
            date: "2017",
            name: "Margin and padding are unique properties of CSS that adds spaces between the container and its content. For simple understanding",
            s: "Extreme ",
            t: "maor k"
        },
        {
            date: "2018",
            name: "Event number 02 Margin and padding are unique properties of CSS that adds spaces between the container and its content. For simple understanding",
            s: "Extreme ",
            t: "s k"
        },
        {
            date: "2019",
            name: "Event number 03 Margin and padding are unique properties of CSS that adds spaces between the container and its content. For simple understanding",
            s: "Extreme ",
            t: "Demo"
        },
        {
            date: "2020",
            name: "Event number 04 Margin and padding are unique properties of CSS that adds spaces between the container and its content. For simple understanding",
            s: "example some",
            t: "Maor tt"
        },
        {
            date: "2021",
            name: "Event number 05 Margin and padding are unique properties of CSS that adds spaces between the container and its content. For simple understanding",
            s: "Extreme ",
            t: "Maor tt"
        },
        {
            date: "2022",
            name: "Event number 06 Margin and padding are unique properties of CSS that adds spaces between the container and its content. For simple understanding",
            s: "Extreme ",
            t: "Demo"
        },
        {
            date: "2023",
            name: "Event number 07 Margin and padding are unique properties of CSS that adds spaces between the container and its content. For simple ",
            statusE: "In Progress",

        }


    ];

    const [labelWidth, setlabelWidth] = useState(180);
    const [amountMove, setamountMove] = useState(110);
    const [lineColor, setlineColor] = useState("#61dafb");
    const [darkMode, setdarkMode] = useState(false);
    const [eventTextAlignCenter, seteventTextAlignCenter] = useState(true);
    const [showSlider, setshowSlider] = useState(true);
    const [arrowsSize, setarrowsSize] = useState(false);

    return (
        <div className="main-page-wrapper">
            <Helmet>
                <title>About || Business Accelerator with Coworking Space in Coimbatore, India | Plug n Play Office</title>
                <meta name="description"
                    content="Innovspace, a Business Accelerator with Coworking Space in Coimbatore offers Plug and Play workspace for startups and freelancers." />
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href="https://web.archive.org/web/20230609211728/https://www.innovspace.com/home/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title"
                    content="Business Accelerator with Coworking Space in Coimbatore, India | Plug n Play Office" />
                <meta property="og:description"
                    content="Innovspace, a Business Accelerator with Coworking Space in Coimbatore offers Plug and Play workspace for startups and freelancers." />
                <meta property="og:url" content="https://web.archive.org/web/20230609211728/https://www.innovspace.com/home/" />
                <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description"
                    content="Innovspace, a Business Accelerator with Coworking Space in Coimbatore offers Plug and Play workspace for startups and freelancers." />
                <meta name="twitter:title"
                    content="Business Accelerator with Coworking Space in Coimbatore, India | Plug n Play Office" />
            </Helmet>
            {/* End Page SEO Content */}

            <Header />

            <div className="block-style-thirtySix lg-container mt-10 mb-50" id="feature">
                <div className="container">
                    <FeaturesA />
                </div>
            </div>

            {/* <div className="container">
                    <div className="row">
            <div className="sldier-wrapper  mt-30 md-mt-50">
            <BranchOffice />
          </div>
          </div>
          </div> */}

            <div className="mt-90 pt-90 md-pt-10 md-mt-80 mb-90 md-mb-50" id='journey'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-5 col-lg-5">
                            <h2 className="mainheading mainheadingabout" style={{ textAlign: "left" }}>
                                Workspace with end to end facilities management, Innovspace
                            </h2>
                        </div>
                        <div className="col-xl-7 col-lg-7">
                            <p className="mainpara md-mt-30">
                                Our array of offerings extends beyond just a desk and a chair. We provide a rich corporate
                                work setup that's cost-effective and enriches your business identity in the competitive market
                                landscape. With the state-of-the-art Virtual Office Spaces and Meeting Rooms, having a branch
                                office in Coimbatore has never been more seamless. The virtual office plus option is more
                                than just a prestigious address; it's an opportunity to elevate your brand's image and foster
                                meaningful connections.
                            </p>
                            <p style={{ paddingTop: "20px" }} className="mainpara">
                                Innovspace Sitra, a significant part of our expansive network, is lauded as one of the best
                                providers of co-working spaces in Coimbatore. Strategically located at the Annamalai Industrial
                                Park, a mile from the Coimbatore Airport and the IT corridor, it's a hub that provides top-notch
                                facilities and aids to budding businesses and startups, nurturing them towards a trajectory of
                                growth and sustainability.

                                But Innovspace is more than just a space; it's a community. A community where like-minded individuals
                                come together to collaborate, innovate, and accelerate their ventures towards success.
                            </p>
                            <p style={{ paddingTop: "20px" }} className="mainpara">
                                Innovspace is not just about fostering businesses; it's about crafting legacies. It's where your
                                aspirations meet the right resources, mentorship, and a community that believes in the power of
                                collective growth. Our ethos is rooted in the principle of ‘Business Productivity Identity,’ ensuring
                                that every endeavor embarked upon here is not just about surviving the market dynamics but thriving
                                amidst them.
                            </p>
                            <p style={{ paddingTop: "20px" }} className="mainpara">
                                Innovspace is more than a coworking space; it's where dreams get a direction and ambitions, a destination.
                                Welcome to Innovspace, where your journey toward creating a benchmark begins.
                            </p>


                            {/* <p className="mainpara md-mt-30">
                                Cherish the bond between Workspace and Resources at Innovspace, since our
                                responsibility is to provide the right support and ideal space for every
                                associated business organization. We understand the primary requirement
                                of every corporate business sector, and so we assist the team to find
                                the apt atmosphere by providing the most access friendly environment.
                            </p>
                            <p style={{ paddingTop: "20px" }} className="mainpara">
                                Our prioritized focus is to ensure that the right space meets the right
                                business team as we monitor continuously for the validated features and
                                often the professionals well appreciate our strict infrastructure maintenance
                                process. On due to the importance of updated office support, Innovspace is
                                preferred first by Coimbatore’s leading start-ups as well as the industry leaders.
                            </p> */}
                        </div>
                    </div>
                </div>
            </div>
            {/* /.fancy-hero-one */}

            {/* =====================================================
				Counter Info Standard
			===================================================== */}
            <div className="counter-info-standard pt-45 md-pt-0">
                <div className="container">

                    <CounterFive />

                </div>
            </div>


            <div className="container mb-80 mt-40">
                <div className="row paddingabout" style={{ backgroundColor: "var(--blue-main)" }}>

                    <div className="col-xl-3 col-lg-3">
                        <div>
                            <img src="images/assets/abt_1.jpg" alt="plug and play office space" className="w-100" style={{ height: "290px" }} />
                        </div>
                        <div>
                            <img src="images/assets/abt_2.jpg" alt="shared office space" className="w-100 disable" style={{ marginTop: "15px", height: "291px" }} />
                        </div>
                    </div>
                    <div className="col-xl-3 col-lg-3 disable">
                        <img src="images/assets/abt_3.jpg" alt="shared office" className="w-100" style={{ height: "100%", marginLeft: "-15px" }} />
                    </div>

                    <div className="col-xl-6 col-lg-6">
                        <div>

                            <h2 className="mainheading mb-15 md-mt-20" style={{ color: "#fff" }}>
                                Founder's Thought
                            </h2>
                            <h2 className="mainheading mb-20 md-mt-20" style={{ color: "#fff", fontSize: "22px" }}>
                                Cultivating a Community of Innovators: The Innvospace Journey
                            </h2>

                            <p style={{ fontSize: "14px", color: "#ebebeb", lineHeight: "normal" }}>
                                In the heart of Coimbatore, a city with a rich industrial history and a burgeoning startup ecosystem,
                                Innvospace was born with a clear vision – to create a haven for dreamers, doers, and disruptors. It's
                                more than just a coworking space; it's a community of like-minded individuals united in a melting pot
                                of creativity and innovation. Our open spaces, collaborative environment, and access to essential
                                resources provide the perfect nurturing ground for ideas to breathe, grow, and thrive.
                            </p>
                            <p style={{ fontSize: "14px", color: "#ebebeb", paddingTop: "20px", lineHeight: "normal" }}>
                                The journey so far has been exhilarating and filled with learning, challenges, and countless rewarding
                                moments. Every day at Innvospace presents a new experience and a new opportunity to make a difference.
                                The success stories of our resident entrepreneurs, the bonds forged within these walls, and our positive
                                imprint on the local ecosystem are a testament to the essence of community, collaboration, and innovation
                                we envisioned.
                            </p>
                            <p style={{ fontSize: "14px", color: "#ebebeb", paddingTop: "20px", lineHeight: "normal" }}>
                                As we look ahead, the roadmap is filled with exciting possibilities. With plans for expanding our horizons,
                                fostering more collaborations, and contributing to the larger narrative of innovation in Coimbatore and
                                beyond, Innvospace is more than just a space; it's a movement towards a future where creativity is celebrated,
                                and every entrepreneur has the right platform to shine. Our dream is shared by every member of this community,
                                and as we continue on this voyage, we remain committed to nurturing a culture of innovation that resonates
                                with the entrepreneurial spirit of Coimbatore.
                            </p>
                        </div>
                    </div>
                </div>
            </div>


            <div className="container">


                <div className="inner-content">

                    <FeatureFour />
                </div>
                {/* End .inner-content */}
            </div>

            {/* =====================================================
				Fancy Feature Four -- Journey section starts
			=====================================================  */}


            {/* <div className="fancy-hero-one1 pt-150 md-pt-70" id='journey'>
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-lg-8">
                            <h2 className="mainheading mainheadingabout" style={{ textAlign: "left" }}>
                                The Journey So Far
                            </h2>
                            <p style={{ paddingTop: "20px" }} className="mainpara">
                                Innovspace was launched in 2016 as a coworking space that develops and builds'space'
                                for everyone. The brand has since expanded to meet the needs of global corporations
                                while also influencing the direction of the Indian coworking industry.
                            </p>
                        </div>
                      
                    </div>
                </div>
            </div>

            <div className="fancy-hero-one1 mt-80 md-mt-40">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12 col-lg-12"></div>
                        <Timeline
                            myList={myList}
                            labelWidth={labelWidth}
                            amountMove={amountMove}
                            lineColor={lineColor}
                            darkMode={darkMode}
                            eventTextAlignCenter={eventTextAlignCenter}
                            showSlider={showSlider}
                            arrowsSize={arrowsSize}
                        />
                    </div>
                </div>
            </div> */}

            {/* =====================================================
				Fancy Feature Four -- Journey section Ends
			=====================================================  */}

            {/* =====================================================
				Fancy Feature Four -- Team members starts
			=====================================================  */}

            {/* <div className="team-section-one pt-150 md-pt-100">
                <div className="container">
                    <h3 className="mainheading mainheadingabout" style={{ textAlign: "left" }}>
                        Our Team
                    </h3>
                    <p style={{ paddingTop: "20px" }} className="mainpara">
                        Innovspace was launched in 2016 as a coworking space that develops and builds'space'
                        for everyone. The brand has since expanded to meet the needs of global corporations
                        while also influencing the direction of the Indian coworking industry..
                    </p>

                    <div className="mt-80 mb-100 md-mt-60 md-mb-40">
                        <TeamThree />
                    </div>
                </div>
            </div> */}

            {/* =====================================================
				Fancy Feature Four -- Team members ends
			=====================================================  */}



            {/* <div className="mt-80 md-mt-30 mb-80 md-mb-40" >
                <div className="mb-20"><h3 className="tenants">Innovspace Clients</h3></div>
                <BrandFour></BrandFour>
            </div> */}


            <div className="container mt-80 mb-80 md-mb-10">
                <div className="row">
                    <div className="mb-20"><h3 className="tenants">Innovspace Clients</h3></div>
                    <div class="client-logos">
                        {/* <!-- Repeat this block for each client logo --> */}
                        <div class="client-logo">
                            <img src="images/logo/softworth.png" alt="Client 1" style={{ width: "150px", height: "40px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Your Farm1.png" alt="your Farm" style={{ width: "150px", height: "40px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/tasaheel.png" alt="tasheel" style={{ width: "150px", height: "40px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/320x128-iGlitz-logo-white-bg.png" alt="Client 1" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/320x128-Edudefine-logo-white-bg.png" alt="Client 1" style={{ maxWidth: "100%", height: "100%" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Rebillion.png" alt="Client 1" style={{ maxWidth: "100%", height: "100%" }} />
                        </div>
                        {/* <!-- Repeat for other clients --> */}
                    </div>
                    {/* <div className="abtCli mb-20"><a href="/clients">Read More</a></div> */}
                </div>
            </div>














            {/* tenants end */}


            <div className="container mb-80 mt-120 md-mt-10">
                <div className="row" style={{ backgroundColor: "var(--blue-main)", padding: "20px 30px" }}>
                    <div className="col-xl-8 col-lg-8" >
                        <button className="con-btn" style={{ margin: "20px 0px" }}>Contact Us</button>
                        <h2 className="mainheading mt-30 mb-30" style={{ color: "#fff" }}>
                            Partner with Us for Comprehensive IT
                        </h2>
                    </div>
                </div>
                {/* <div className="about-cta"> */}
                <div className="row about-cta">
                    <div className="col-xl-6 col-lg-6 pb-40" style={{ backgroundColor: "#00000038" }} >
                        <p style={{ color: "#fff", padding: "40px 35px", fontSize: "16px" }}>
                            We’re happy to answer any questions you may have and help you determine which of our services best fit your needs. </p>
                        <p style={{ color: "#fff", padding: "40px 35px", fontSize: "16px" }}>Call Us : +91 98406 69944</p>
                        <div className="quickdetails" style={{ padding: "0px 35px" }} >
                            <p style={{ color: "#fff", fontSize: "16px", paddingBottom: "20px" }}>What happens next?</p>
                            <ul style={{ color: "#f5f5f5", fontSize: "16px" }}>
                                <li> 1. We Schedule a call at your convenience </li>
                                <li> 2. We do a discovery and consulting meting </li>
                                <li> 3. We prepare a proposal</li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-xl-6 col-lg-6 pb-60" style={{ backgroundColor: "#00000038" }} >
                        <div className="form-about">
                            {/* <ContactForm /> */}
                            <AboutForm />
                        </div>
                    </div>
                </div>
                {/* </div> */}
            </div>

            <footer className="theme-footer-one pt-130 md-pt-30">
                <div className="top-footer">
                    <div className="container">
                        <Footer />
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRight />
                    </div>

                </div>
            </footer>

        </div >
    );
};

export default About1;
