import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import TestimonialPortfolio from "../../../../components/form-survey-landing/TestimonialPortfolio";
import BlogTestimonial from "../../../../components/blog/BlogTestimonial";
import './Testimonial.css';
import TestimonialInnovSpace from "../../../../components/testimonial/TestimonialInnovSpace";
import Portfolio from "../../../../components/form-survey-landing/Portfolio";
import Portfoliomobile from "../../../../components/form-survey-landing/Portfoliomobile";
import { useMediaQuery } from "@uidotdev/usehooks";
import TestimonialsInnov from "../../../../components/testimonial/TestimonialsInnov";
import VideoPlayer from "../../../../components/form-survey-landing/VideoPlayer";


const Testimonial = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );
  const isLargeDevice = useMediaQuery(
    "only screen and (min-width : 993px) "
  );
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        {/* <title>
        Virtual Meeting Room India | Conference Room for Rent Kalapatti, Coimbatore
        </title> */}
        <title>Coworking Space and Virtual Office India | Office Space for Rent SITRA</title>
        <meta name="description"
          content="We provide a unique design place for coworking in Coimbatore with a comprehensive office environment for all startups and service related projects." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical"
          href="https://web.archive.org/web/20230609205216/https://www.innovspace.com/benefits/workspace-benefits/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Coworking Space and Virtual Office India | Office Space for Rent SITRA" />
        <meta property="og:description"
          content="We provide a unique design place for coworking in Coimbatore with a comprehensive office environment for all startups and service related projects." />
        <meta property="og:url"
          content="https://web.archive.org/web/20230609205216/https://www.innovspace.com/benefits/workspace-benefits/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="We provide a unique design place for coworking in Coimbatore with a comprehensive office environment for all startups and service related projects." />
        <meta name="twitter:title" content="Coworking Space and Virtual Office India | Office Space for Rent SITRA" />
      </Helmet>
      {/* End Page SEO Content */}

      <Header></Header>
      <div className="testimonial-bg pt-160 pb-150 md-pb-50 md-pt-120">
        <div className="container ">
          <div className="star-count pb-20 " style={{ textAlign: "center" }}>
            {[...Array(5)].map((e, i) => (
              <i class='fa fa-star' style={{ color: " #f3da35" }}></i>
            ))}
          </div>
          <h1 className="mainheading-testimonial textaligncenter" >Testimonials From Our Valued Clients</h1>
          <div className="row">
            <div className="col-xl-1 col-md-1"></div>
            <div className="col-xl-10 col-md-10">
              <p className="mainpara mt-30 textaligncenter">At Innospace,We are delighted to offer a collaborative and inspiring environment that nurtures the success of our esteemed clients. Discover what a few of them have to share about their experience with us</p>
              <div className="col-xl-1 col-md-1"></div>
            </div>
          </div>
        </div></div>

      {isLargeDevice &&

        <div className="container mt-80 md-mb-10" style={{ width: "1200px" }} >
          <div className="slider-wrappers">
            <div className="portfolio_slider_two survey-slider position pt-50 pb-50" >
              <TestimonialPortfolio />
            </div>
          </div>
        </div>

      }

      {isSmallDevice &&

        <div className="slider-wrappers md-mt-30">
          <div className="portfolio_slider_two survey-slider " >
            <Portfoliomobile />
          </div>
        </div>}
      {isMediumDevice &&
        <div className="slider-wrappers md-mt-30">
          <div className="portfolio_slider_two survey-slider " >
            <Portfoliomobile />
          </div>
        </div>}

      {/* <div className="container pt-110 md-pt-50 widthmain" >
        <div className="row ">
          <VideoPlayer></VideoPlayer>
        </div>
    </div> */}


      {/* image wit text block start */}

      {/* <div className="container pt-110 md-pt-50 widthmain" >
        <div className="row ">
            <BlogTestimonial></BlogTestimonial>
        
        </div>
      </div> */}

      {/* image wit text block end */}


      <div className="client-feedback-slider-two1 mt-80 md-mt-30">
        <div className="container">
          <h2 className="mainheading">What Our Customers Say About Us</h2>
        </div>
      </div>

      <div className="client-feedback-slider-two1  md-mt-10 container padding mt-20 " >
        <div className="slider-content">
          <div className="clientSliderTwo slick-nav-none " >
            <div className="row ">
              <TestimonialInnovSpace></TestimonialInnovSpace>
            </div>
          </div>
        </div>
        {/* /.slider-content */}
      </div>

      {/* <div className="client-feedback-slider-two1  md-mt-10 container padding mt-80 " >
 <TestimonialsInnov></TestimonialsInnov>
 </div> */}


      <div className="container mt-50">
        <div className="  row">
          <div className="col-xl-1 col-md-1"></div>
          <div className="col-xl-10 col-md-10">
            <p className="mainpara mt-30 textaligncenter">We extend our heartfelt gratitude to our clients for sharing their testimonials and experiences. Their valuable feedback inspires us to consistently deliver an exceptional coworking experience. Join Innovspace today and become a valued member of our thriving community, where professionals are making remarkable strides in their industries. Contact us now to explore membership options and embark on your path to success!</p>
            <div className="col-xl-1 col-md-1"></div>
          </div>
        </div>
      </div>

      <div className="footer-bg-wrapper mt-120 md-mt-40">

        <footer className="theme-footer-one pt-70 md-pt-30 ">

          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container ">
            <div className="bottom-footer-content ">
              <CopyRight />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}

      </div>
    </div>
  );
};

export default Testimonial;
