import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import BlogStyle3 from "../../../components/blog/BlogStyle3";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import BlogSidebarClassic from "../../../components/blog/BlogSidebarClassic";

const BlogV3 = () => {
  const blogPageUrl = 'https://innovspace.com/blog-v3';
  const shareUrl = `${blogPageUrl}?v=${Date.now()}`;
  const shareOnTwitter = () => {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(blogPageUrl)}`, '_blank');
    };
  
    const shareOnFacebook = () => {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(blogPageUrl)}`, '_blank');
    };
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>The Walk - a movie - Innovspace - Business Accelerator | Coworking Space</title>
        <meta name="description" content="coworking space coimbatore" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href="https://web.archive.org/web/20211017125546/https://www.innovspace.com/blogs/walk/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="The Walk - a movie - Innovspace - Business Accelerator | Coworking Space" />
        <meta property="og:description" content="coworking space coimbatore" />
        <meta property="og:url" content="https://web.archive.org/web/20211017125546/https://www.innovspace.com/blogs/walk/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta property="article:section" content="TGIF" />
        <meta property="article:published_time" content="2017-09-20T09:24:20+00:00" />
        <meta property="article:modified_time" content="2017-09-20T09:25:23+00:00" />
        <meta property="og:updated_time" content="2017-09-20T09:25:23+00:00" />
      
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="coworking space coimbatore" />
        <meta name="twitter:title" content="The Walk - a movie - Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:image"
          content="https://web.archive.org/web/20211017125546im_/https://www.innovspace.com/wp-content/uploads/2017/09/the-walk.jpg" />
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
            Fancy Hero Two
        ==============================================  */}
      {/* /.fancy-hero-two */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="blog-page-bg md-pb-10">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">

            <div className="col-xl-8 offset-xl-1 col-lg-8 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta">
                <h1 className="mainheading-blog">The Walk – a movie</h1>
                <div className="tag">Aug 07, 2023</div>
                <img src="images/blog/walks.jpg" alt="media post" className="image-meta" />
                {/* <h3 className="title"> Quis Nostr Exercitation Ullamco Laboris nisi ut Aliquip exeal nothing. </h3> */}
                <p className="blog-para">
                  Innovspace showed the movie “The Walk” on their TGIF movie time on 1st September. Not to mention it is a
                  true story of Philippe Petit, the French high wire artist, it is a shadow story of any entrepreneur who
                  sets out to achieve his dream.</p>

                <p>
                  A street performer could be the lowest forms of fame, income or respect in a fast-moving society. Don’t
                  we know a bunch of corporate employees who are cribbing and whining about their lack of respect or income?
                  Philippe is a testimony to how our outlook on life depends on one’s respect for self and for his dream.
                  Our self respect depends on how live from within and not from without.
                </p>

                <p>
                  In real life Philippe took 6 years to perform this “artistic crime of the century” which he calls “le coup”.
                  The dream started when the Twin towers were announced to be built and he accomplished it when it was getting
                  completed.
                </p>

                <p>
                  With one leg on the tower and one on the wire, he is yet to shift his weight on the wire to start his dream walk.
                  He tells himself, ‘once both my feet are on the wire, my life changes forever. It will never be the same again”.
                  A start up can relate to this ‘one foot on the wire’ moment. How he or she contemplates and juggles between his
                  decision. And at one gutful moment, they shift their weight, and then life is never the same again. Only a start
                  up or an entrepreneur can understand that, it is actually an elevating experience to go financially broken, go
                  miles and miles to get that first customer, to listen a thousand Nos, to be ridiculed by their own family as they
                  compare their son to well settled self-cursing neighbour, and what more, their struggle to find that equally crazy
                  life partner who might understand their craziness and be up for those daily thrills and adventures.
                </p>

                <p>The Walk teaches a lot more than just bravery, dedication, perseverance and hard work. Sometimes, to a start up,
                  it might take the 10th time of watching this movie to get another new lesson!</p>


                {/* <p>
                  is true as we experience the emotional sensation of stress
                  from our firs social rejection ridicule.We quickly learn to
                  fear and thus automatically. potentially stressful situation
                  of all kinds, including the most common of all.
                </p> */}
                <div className="d-sm-flex align-items-center justify-content-between share-area">
                  <ul className="tag-feature d-flex">
                    <li>Tag: &nbsp;</li>
                    <li>
                      <a href="#">business,</a>
                    </li>
                    <li>
                      <a href="#">marketing,</a>
                    </li>
                    <li>
                      <a href="#">tips</a>
                    </li>
                  </ul>
                  <ul className="share-option d-flex align-items-center">
                    <li>Share</li>
                    {/* <li>
                      <a href="#" style={{ background: " #F6616F" }}>
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li> */}
                    <li>
                    <a onClick={shareOnTwitter} target="blank" style={{ background: " black" ,display:'flex',justifyContent:'center'}} className="hovericonimage">
                        {/* <i className="fab fa-twitter" aria-hidden="true"></i> */}
                        <img src="images/icon/twitter-x.svg" alt="Innovspace" style={{width:'18px'}}></img>
                      </a>
                    </li>
                    <li>
                      <a onClick={shareOnFacebook} target="blank" style={{ background: " #588DE7" }} className="hovericonimage">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /End post content  */}


            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-one pt-130 md-pt-30">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogV3;
