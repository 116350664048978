import React from "react";

const FeatureContent = [
  {
    icon: "health1",
    meta: "Health Insurance",
    subTitle: `We care about the health and well-being of our employees. That's why we offer a comprehensive health insurance plan to all of our full-time employees. We offer a variety of health insurance plans to choose from, including medical, dental, and vision coverage.`,
    dataDelay: "0",
  },
  {
    icon: "friendlyculture1",
    meta: "PTO (Paid Time Off)",
    subTitle: `We offer a generous PTO policy that allows our employees to take time off for vacation, sick days, personal days, or any other reason they need time away from work. We believe that our flexible leave policy shows our commitment to supporting our employees in needed situation.`,
    dataDelay: "100",
  },
  {
    icon: "workinghours",
    meta: "Flexible Working Hours",
    subTitle: `We offer flexible working hours to accommodate our employees' needs and allow them to take time off for appointments or other commitments during the workday. We offer the option to work from home whenever required.`,
    dataDelay: "300",
  },
];
const FeatureContent2 = [
  {
    icon: "learningandgrowth1",
    meta: "Employee Assistance Program",
    subTitle: `We understand that life can be stressful at times, which is why we offer an employee assistance program that provides counseling, support, and resources to help you manage life's challenges. We encourage all employees to take advantage of this valuable benefit whenever they need it.`,
    dataDelay: "0",
  },
  {
    icon: "workfromhome1",
    meta: "Professional Development",
    subTitle: `We believe that investing in our employees' professional development not only benefits them but also our organization as a whole. We're committed to helping our employees grow and develop their skills, so we offer professional development opportunities, training programs, and career advancement opportunities.`,
    dataDelay: "100",
  },
  {
    icon: "perbonus",
    meta: "Performance Bonus",
    subTitle: `We believe in recognizing and rewarding exceptional performance. When employees feel appreciated and valued, they are more engaged, productive, and committed to the success of the organization. This can take various forms, including cash bonuses, stock options, profit-sharing, or commission-based bonuses.`,
    dataDelay: "300",
  },
];
const FeatureThree = () => {
  return (
    <div>
    <div className="row justify-content-center">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-three">
            <div className=" md-pb-20 pb-30">
              <img src={`images/icon/${val.icon}.png`} alt="icon" className="iconheight"/>
            </div>
            <h4 >{val.meta}</h4>
            <p className="mainpara" style={{textAlign:"justify"}}>{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
      <div className="row justify-content-center">
      {FeatureContent2.map((val, i) => (
        <div
          className="col-lg-4 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-three">
            <div className=" md-pb-20 pb-30">
              <img src={`images/icon/${val.icon}.png`} alt="icon" className="iconheight"/>
            </div>
            <h4>{val.meta}</h4>
            <p className="mainpara">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
    </div>
  );
};

export default FeatureThree;
