import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";
import BranchOffice from "../block-slider/BranchOffice";


const FeaturesA = () => {

    return (
        <div className="row">
            <div className="col-xl-5 col-lg-5 order-lg-first" data-aos="fade-left">
                <div className=" mt-40">
                    <div className="title-style-fourteen">
                        <h1 className="mainheading bannerheading">Innovspace: Your Success Blueprint</h1>
                        {/* <h1 className="mainheading bannerheading">Collaborative workspaces that spark creativity</h1> */}
                    </div>
                    <div className="tag-line">
                    </div>
                    {/* <p style={{ fontSize: "16px", lineHeight: "normal" }} className="mainpara">Innovspace is into the co-working space and business acceleration and process
                        optimization business, profoundly developing visionary brands present around
                        the world. With pioneered expertise, our conglomerate company empowers partnered
                        entrepreneurs /business to emerge, lead and win the competition.</p> */}
                        <p style={{ fontSize: "16px", lineHeight: "normal" }} className="mainpara">In a world where the spirit of entrepreneurship is thriving, Innovspace emerges as a sanctuary for visionaries and change-makers. Nestled in the heart 
                            of Coimbatore, Tamil Nadu, Innovspace isn't just a coworking arena but a professionally blended launchpad that fuels the journey from ideation to realization. 
                            Through its avant-garde infrastructure and a conducive work ambiance, Innovspace caters to a diverse spectrum of professionals, including Startups, Private 
                            companies, Freelancers, NGOs, CXOs, Business Consultants, and Mainstream organizations. Here, every cubicle resonates with the ethos of innovation and productivity, 
                            beckoning a future brimming with promise and success.</p>
                    <div className="mt-30 mb-5 md-mb-20">
                    <AnchorLink href='#journey'><button className="button-banner">Learn about company</button></AnchorLink>
                    </div>
                </div>
            </div>
            <div className="col-xl-7 col-lg-7">
                {/* <div className="illustration-holder d-inline-block md-mt-20">
                    <img src="images/assets/Banner_abt.jpg"  className="w-100" />
                </div> */}
                <div className="slider-wrapper  mt-30 md-mt-50">
            <BranchOffice />
          </div>
            </div>
        </div>
    );
};




export default FeaturesA;