import React from "react";
import AnchorLink from "react-anchor-link-smooth-scroll";

const HeroBannerThirteen = () => {
  return (
    // <!--
    // =============================================
    //     Theme Hero Banner
    // ==============================================
    // -->
    <div className="hero-banner-thirteen lg-container" id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <h1 className="heading mt-20 md-mt-60">Workspaces for Collaboration in Your Neighborhood</h1>
            <div className="mb-25">
              <p className="bn-icon mt-50 mb-20"> Why Innovspace </p>
              <div className="d-flex1 mt-10 ">
                <div className="iconfix">
                  <img src="images/assets/location-innov.svg" alt="feature"></img>
                </div>
                <div>
                  <p className="bn-list hometext"> Your Work Oasis, Just Around the Corner</p>
                </div>
              </div>
              <div className="d-flex1 mt-10 ">
                <div className="iconfix">
                  <img src="images/assets/cook.svg" alt="feature"></img>
                </div>
                <div>
                  <p className="bn-list hometext">Tailored to You: Workspaces Wherever, Whenever & However You Need</p>
                </div>
              </div>
              <div className="d-flex1 mt-10 ">
                <div className="iconfix">
                  <img src="images/assets/third.svg" alt="feature"></img>
                </div>
                <div>
                  <p className="bn-list hometext">All-inclusive Membership, Maximized Productivity</p>
                </div>
              </div>
              <div className="d-flex1 mt-10 ">
                <div className="iconfix">
                  <img src="images/assets/fly.svg" alt="feature"></img>
                </div>
                <div>
                  <p className="bn-list hometext">Innovspace: Where Your Ambitions Take Flight.</p>
                </div>
              </div>

            </div>
            <div className="mt-40 mb-35">
              <AnchorLink href='#getstart'>
                <a href="#" className="theme-btn-fourteen">
                  Get Started Now
                </a>
              </AnchorLink>
            </div>
          </div>
        </div>

        {/* <!-- /.screen-holder --> */}
      </div>
    </div>
    // <!-- /.hero-banner-thirteen -->
  );
};

export default HeroBannerThirteen;
