import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import AllRoutes from "./router/AllRoutes";
import ScrollToTop from "./components/ScrollToTop";
import AOS from "aos";
import "aos/dist/aos.css";
import "photoswipe/dist/photoswipe.css";
import "bootstrap/dist/js/bootstrap";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
    });
  }, []);
  window.addEventListener("load", AOS.refresh);

  return (
    <>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Helmet>
        <title>Innovspace || Business Accelerator with Coworking Space in Coimbatore,
          India - Affordable Plug n Play Office | Virtual Office | Meeting Rooms | Training Rooms</title>
        <meta property="og:site_name" content="deski" />
        <meta
          property="og:url"
          content="https://themeforest.net/user/ib-themes"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="Innovspace || Business Accelerator with Coworking Space in Coimbatore,
          India - Affordable Plug n Play Office | Virtual Office | Meeting Rooms | Training Rooms"
        />
        <meta
          name="keywords"
          content="Coworking space, coimbatore, best coworking space, modern, less cost, private office, meeting rooms, virtual office, corporate service"
        />
        <meta
          name="description"
          content="Innovspace || Business Accelerator with Coworking Space in Coimbatore"
        />
        <meta name="description" content="Innovspace || Business Accelerator with Coworking Space in Coimbatore" />
      </Helmet>

      {/* End Seo Helmt */}

      <ScrollToTop />
      {/* End Scroll top */}

      <AllRoutes />
      </LocalizationProvider>
    </>
  );
};

export default App;
