import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is a dedicated desk?",
    desc: `A dedicated desk is a reserved workspace within a coworking space that is exclusively assigned to you or your team. 
    It provides a consistent and personalized area where you can set up your equipment and belongings.`,
    expand: "a",
  },
  {
    title: "How does a dedicated desk differ from a hot seat?",
    desc: `Unlike a hot seat, which is unassigned and available on a first-come, first-served basis, a dedicated desk is exclusively
    assigned to you. You can personalize the space, leave your belongings overnight, and have a consistent workspace to call your own.`,
    expand: "b",
  },
  {
    title: "What amenities are typically included with a dedicated desk?",
    desc: `Dedicated desk users enjoy the same amenities as hot seat users, such as high-speed internet, power outlets, communal areas,
    and office supplies. Additionally, they may have extra benefits like lockable storage, ergonomic chairs, and enhanced privacy.`,
    expand: "c",
  },
  {
    title: "Is a dedicated desk suitable for individuals or teams?",
    desc: `Dedicated desks are ideal for individuals or teams seeking a reliable and personalized workspace that accommodates specific 
    needs and equipment. It enables close collaboration within a shared coworking community.`,
    expand: "d",
  },
  {
    title: "Can I switch from a hot seat to a dedicated desk?",
    desc: `If you are initially using a hot seat and later decide to switch to a dedicated desk, you can inquire with our team about 
    the availability of dedicated desks and the process for making the transition. Depending on availability and membership options, 
    it may be possible to upgrade to a dedicated desk to enjoy a more personalized workspace experience.`,
    expand: "e",
  },
  {
    title: "Are dedicated desks more expensive than hot seats?",
    desc: `Dedicated desks usually cost more than hot seats due to their exclusivity, but pricing varies based on location, 
    amenities, and services offered. Contact our team for specific details on dedicated desk membership costs.`,
    expand: "f",
  },
  {
    title: "Can I share my dedicated desk with someone else?",
    desc: `Typically, dedicated desks are assigned to a specific individual or team and are not intended for sharing. 
    However, sometime we may offer shared desk options or allow for additional members to join a dedicated desk arrangement. 
    It's best to inquire with the our team about the policies regarding desk sharing.`,
    expand: "g",
  },

];

const FaqDedicatedDesk = () => {
  return (
    <div className="accordion-style-four ">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqDedicatedDesk;
