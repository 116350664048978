import React from "react";
import FancyFeatureTewentyEight from "../features/FancyFeatureTewentyEight";
import FancyFeatureThirtyOne from "../features/FancyFeatureThirtyOne";

const featureList = [
    {
        icon: "document",
        title: "Business Address",
        description: `Establish a prestigious business location without the overheads of a physical space. Our Virtual Office service provides you with a prime business address, reinforcing your professional image and boosting your local credibility.`,
    },
    {
        icon: "mail",
        title: "Mail & Courier Management",
        description: `Stay connected wherever you are with our reliable Mail and Courier Management service. We handle your mail and courier deliveries efficiently, ensuring you never miss an important document or parcel.`,
    },
    {
        icon: "reception",
        title: "Favoured Reception",
        description: ` Make a great first impression with our Favoured Reception service. Our professional and courteous reception team will handle your calls and guests, offering a warm welcome and ensuring your business is always presented in the best light.`,
    },
];

const FancyTextHomeVirtualOff = () => {
    return (
        <div className="container">
            <div className="block-style-thirty mt-120 mb-80 md-mb-10 md-mt-50">
                <div className="row">
                   
                    <div className="col-xl-6 col-lg-6">
                        <div className="text-block md-pb-10">
                         
                            <div className="title-style-nine mb-20 md-mb-10">
                            <img src="images/shape/1.svg" alt="feature" class="cs-screen screen-one"/>
                            <h3 class="mainheading pb-20">Facilities & Services Included</h3>
                                {/* <p style={{width:"100%",padding:"0 0 10px",margin:"0px 0 0",lineHeight:"22px"}}>Innovspace is a professionally blended Business Accelerator cum Coworking space in Coimbatore, offering a rich corporate work setup for Startups, Private companies, Freelancers, NGOs, CXOs, Business Consultants and Mainstream organisations at the most comfortable budget</p> */}
                            </div>

                            <ul className="feature-list-one">
                                {featureList.map((list, i) => (
                                    <li key={i}>
                                        <img
                                            src={`images/assets/${list.icon}.png`}
                                            alt="icon"
                                            className="icon"
                                        />
                                        <strong style={{width:"280px"}}>{list.title}</strong>
                                        <span className="spanwidth">{list.description}</span>
                                    </li>
                                ))}
                            </ul>
                            {/* End .feature-list-one */}

                           
                        </div>
                        {/* <!-- /.text-block --> */}
                    </div>
                    {/* <div className="col-xl-1 col-lg-1"></div> */}
                    <div className="col-xl-6 col-lg-6">
                      <img src="images/assets/Virtual_fea.png" alt="about coworking space" style={{height:"100%"}}></img>
                  </div>
                    {/* <div className="col-xl-7 col-lg-6">
            <img src="images/assets/screen_35.png" alt="screen" />
          </div> */}
                </div>
            </div>


        </div>
    );
};

export default FancyTextHomeVirtualOff;
