import React from "react";
import Modal from "react-modal";
import { useState } from "react";
import HeaderPopupForm from "../../components/form/HeaderPopupForm";
const CouponHome = () => {
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }

  return (
    <div className="container coupon-background1 mt-80">
      <div className="row">
        <div className="col-lg-6 col-xl-6 mainpaddings">
          <h3
            className="cardheader2 mt-30"
            style={{ fontSize: "18px", fontWeight: "500" }}
          >
            INNOVSPACE
          </h3>
          <h3 className="mainheading mt-10 parawidth">
            Discover the Freedom of Flexibility with Our Coworking Space - Just{" "}
            <span>Rs 1295/Month</span>
          </h3>
          <p className="mt-10 cardheader2">
            Step into a realm of adaptability and potential with Innovspace,
            your partner for growth and success. For a budget-friendly fee of Rs
            1295 per month, access a workspace that's as dynamic and flexible as
            your business needs.
          </p>
          <p className="mt-10 cardheader2">
            We cater to everyone from independent freelancers to vibrant teams,
            offering spaces that inspire innovation and facilitate productivity.
            Our coworking spaces strike the perfect harmony between communal
            energy and individual focus.
          </p>
          <p className="mt-10 cardheader2">
            With Innovspace, you're not just renting a workspace - you're
            investing in possibilities. Join us today and witness the
            transformative power of flexibility.
          </p>
          {/* <button className="btn btn-primary mt-20">Book Now</button> */}
          <button
            className="btn-primary mt-20 notification"
            onClick={toggleModalOne}
          >
            Book Now
          </button>
        </div>
        <div className="col-lg-6 col-xl-6 parent">
          {/* <img src="images/assets/home_1.png" alt="no image" className="child1 child"/> */}
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModalOne}
        contentLabel="My dialog"
        className="custom-modal  modal-contact-popup-one"
        overlayClassName="custom-overlay"
        closeTimeoutMS={500}
      >
        <div className="box_inner ">
          <main className="main-body box_inner modal-content clearfix md-mb-30">
            <button className="close" onClick={toggleModalOne}>
              <img src="images/icon/close.svg" alt="close" />
            </button>

            <div className="right-side">
              <div className="size">
                <h3 className="mainheading mb-20">Contact us </h3>
                <HeaderPopupForm />
              </div>
            </div>
            {/*  /.right-side */}
          </main>
          {/* /.main-body */}
        </div>
      </Modal>
    </div>
  );
};

export default CouponHome;
