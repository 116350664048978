import React, { useState } from "react";
import HeaderPopupForm from "../form/HeaderPopupForm";
import Modal from "react-modal";
import Slider from "react-slick";

export default function DocSlickAsNavTwo() {
  const [isOpen, setIsOpen] = useState(false);
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const [navbar, setNavbar] = useState(false);

  function toggleModalOne() {
    setIsOpen(!isOpen);
  }
  const [nav1, setNav1] = useState();
  const [nav2, setNav2] = useState();

  var settings = {
    slidesToShow: 2,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      <Slider
        {...settings}
        asNavFor={nav1}
        ref={(slider2) => setNav2(slider2)}
        swipeToSlide={true}
        focusOnSelect={true}
        className="slick-nav-custom "
      >
        <div className="d-flex item_content">
          <h4 className="subhead">
            For Individuals and Small Teams
          </h4>
        </div>
        {/* /.d-flex */}
        <div className="d-flex item_content">
          <h4 className="subhead">
            For Medium to Large Businesses
          </h4>
        </div>
      </Slider>
      {/* Bottom text slide */}

      <div className="position-relative">
        <Slider
          className="slick-nav-none carousel-inner"
          asNavFor={nav2}
          fade={true}
          speed={600}
          ref={(slider1) => setNav1(slider1)}
        >
          <div className="carousel-item">
            <p>At Innovspace, we provide inspiring, flexible workspaces for individual professionals, freelancers, and small teams.
              Our all-in-one solution not only includes a space to thrive but also comprehensive corporate services like company registration and bookkeeping to support your business operations. Connect, innovate, and grow in an environment designed to foster productivity and creativity, all while enjoying the benefits of professional business services tailored to your unique needs.</p>
            <div className="btn-home mt-4 mb-5">
              <a href="contact-us" className="theme-btn-fourteen2" >Book Workspace</a>
              {/* <button className="btn btn-primary" onClick={toggleModalOne}>Book a workspace</button>
            <button className="btn btn-primary" onClick={toggleModalOne} >Get Started Now</button> */}
              {/* <a href="#" className="butonborder" onClick={toggleModalOne}>Get Started Now</a> */}
            </div>
          </div>
          <div className="carousel-item">
            <p>Innovspace offers adaptable, well-equipped workspace solutions for medium to large businesses, accommodating growth and fostering collaboration.
              Our workspaces, coupled with essential corporate services like accounting and company registration, provide a comprehensive solution for your business needs. With flexible contracts and scalable spaces, Innovspace supports your evolving requirements, enabling you to focus on what you do best: driving your business forward.</p>
            <div className="btn-home mt-4 mb-5">
              <a href="contact-us" className="theme-btn-fourteen1" >Book Workspace</a>
              {/* <button className="btn btn-primary" onClick={toggleModalOne}>Book a workspace</button>
            <button className="btn btn-primary" onClick={toggleModalOne} >Get Started Now</button> */}
              {/* <a href="#" className="theme-btn-fourteen2" onClick={toggleModalOne}>Book Workspace</a> */}
            </div>
          </div>
          {/* <div className="carousel-item">
            <img src="images/assets/screen_09.png" alt="feature" />
          </div> */}
        </Slider>
        <Modal
          isOpen={isOpen}
          onRequestClose={toggleModalOne}
          contentLabel="My dialog"
          className="custom-modal  modal-contact-popup-one"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div className="box_inner ">
            <main className="main-body box_inner modal-content clearfix">
              <button className="close" onClick={toggleModalOne}>
                <img src="images/icon/close.svg" alt="close" />
              </button>
              {/* End close icon */}

              <div className="left-side">
                <div className="d-flex flex-column justify-content-between h-100">
                  <div className="row">
                    <div className="col-xl-10 col-lg-8 m-auto">
                      <blockquote>
                        <h3 className="mainheading" style={{ textAlign: "left", color: "#fff" }}>Talk To An Expert</h3>
                      </blockquote>
                      <span className="bio"><p style={{ fontSize: "16px", textAlign: "left", color: "#fff" }}>  Tell Us Your Requirements and Out Workspace Expert
                        willReach out to You at earliest.
                      </p></span>
                    </div>
                  </div>
                  {/* <img src="images/assets/ils_18.svg" alt="" className="illustration mt-auto" /> */}
                </div>
              </div>
              
              {/* /.left-side */}

              <div className="right-side">
                <h2 className="form-title">Contact us</h2>
                <HeaderPopupForm />
              </div>
              {/*  /.right-side */}
            </main>
            {/* /.main-body */}
          </div>
        </Modal>
      </div>
    </>
  );
}
