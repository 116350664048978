import { useState } from "react";
import './Benefits.css'

const BenefitsCourporateService = () => {
    const [toggle, setToggle] = useState("1");
    return (
        <>
            <div className='row '>
                <div className='col-xl-6 col-lg-6 block-style-thirtySix mobilepadding'>
                    <h2 className="mainheading mb-30 md-mt-20 md-mb-10" >
                    Why Choose Us?
                    </h2>
                    <div className="benefits-space">
                        <ul><li className="benefits-list">

                            <h2 onClick={() => setToggle("1")} className={`subheading-benefits title ${toggle === '1' ? 'active' : ''}`}>Expertise and Experience</h2>
                            {toggle === "1" &&
                                <p className="para-benefits mt-10"> Count on our experienced professionals in company registration, accounting, and software solutions for accurate and reliable services.</p>
                            }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("2")} className={`subheading-benefits mt-20 title ${toggle === '2' ? 'active' : ''}`}>Customized Approach </h2>
                                {toggle === "2" &&
                                    <p className="para-benefits mt-10"> We tailor our services to your unique business needs, working closely with you to align with your goals and aspirations. Expect a personalized approach from our experts.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("3")} className={`subheading-benefits mt-20 title ${toggle === '3' ? 'active' : ''}`}>Efficiency and Timeliness </h2>
                                {toggle === "3" &&
                                    <p className="para-benefits mt-10">
                                  We prioritize your time, ensuring timely service delivery. Count on our streamlined processes and attention to detail for prompt and effective corporate service solutions.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("4")} className={`subheading-benefits mt-20 title ${toggle === '4' ? 'active' : ''}`}>Cost-Effective Solutions</h2>
                                {toggle === "4" &&
                                    <p className="para-benefits mt-10"> We believe that quality corporate services should be accessible and affordable. Our competitive pricing structure ensures that you receive exceptional value for your investment.</p>
                                }</li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 '>
                    {toggle === "1" &&
                        <img src="images/assets/Acc_Image23.png" alt="coworking space" className="benefits-img fadeinout" />
                    }
                    {toggle === "2" &&
                        <img src="images/assets/Acc_Image24.png" alt="coworking companies in coimbatore| india" className="benefits-img fadeinout" />
                    }
                    {toggle === "3" &&
                        <img src="images/assets/Acc_Image25.png" alt="hot seat, coimbatore coworking space" className="benefits-img fadeinout" />
                    }
                    {toggle === "4" &&
                        <img src="images/assets/Acc_Image26.png" alt="coworking community" className="benefits-img fadeinout" />
                    }
                </div>
            </div>

        </>
    )
}

export default BenefitsCourporateService

