import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import FaqClassicTwo from "../../components/faq/FaqClassicTwo";
import FancyTextBlockOne from "../../components/fancy-text-block/FancyTextBlockOne";
import HeroBannerHotSeat from "../../components/form-survey-landing/HeroBannerHotSeat";
import OtherServicesHotSeat from "../../components/vr-landing/OtherServicesHotSeat";
import ImageGalleryHotSeat from "../../components/vr-landing/ImageGalleryHotSeat";
import ImageGalleryHotSeatBigScreen from "../../components/vr-landing/ImageGalleryHotSeatBigScreen";
import BenefitsHotSeat from "../../components/benefits/BenefitsHotSeat";
import CouponHot from "../../components/coupon/CouponHot";
import FeaturesHotSeat from "../../components/form-survey-landing/FeaturesHotSeat";
import { useMediaQuery } from "@uidotdev/usehooks";

const HotSeat = () => {
  const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
  const isMediumDevice = useMediaQuery(
    "only screen and (min-width : 769px) and (max-width : 992px)"
  );
  const isLargeDevice = useMediaQuery(
    "only screen and (min-width : 993px) "
  );
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        {/* <title>
          Hot seat | Dedicated desk | Workspace for Startups
        </title> */}
        <title>Hot desk | Dedicated desk | Workspace for Startups</title>
        <meta name="description"
          content="Innovspace allows you to develop a structured work schedule in a secure office space with a formal conference room for your meetings and other stuffs." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical"
          href="https://web.archive.org/web/20230609222544/https://www.innovspace.com/workspaces/co-working-spaces/hot-desk/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Hot desk | Dedicated desk | Workspace for Startups" />
        <meta property="og:description"
          content="Innovspace allows you to develop a structured work schedule in a secure office space with a formal conference room for your meetings and other stuffs." />
        <meta property="og:url"
          content="https://web.archive.org/web/20230609222544/https://www.innovspace.com/workspaces/co-working-spaces/hot-desk/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="Innovspace allows you to develop a structured work schedule in a secure office space with a formal conference room for your meetings and other stuffs." />
        <meta name="twitter:title" content="Hot desk | Dedicated desk | Workspace for Startups" />
        <meta name="twitter:image"
          content="https://web.archive.org/web/20230609222544im_/https://www.innovspace.com/wp-content/uploads/2017/01/avatar-3.svg" />
      </Helmet>


      <Header></Header>

      {/* banner */}
      <div className="mt-80 md-mt-60">
        <HeroBannerHotSeat></HeroBannerHotSeat>
      </div>
      {/* banner end*/}


      {/* Facilities */}
      <div className="fancy-text-block-three mt-110  md-mt-50 md-pb-40">
        <div className="container">
          <div className="row">
            <div className="col-xl-11 m-auto">
              <FancyTextBlockOne />
            </div>
          </div>
        </div>
      </div>
      {/* Facilities end*/}

      {/* benefits */}
      <div className="container mt-120 mb-120 md-mt-10 md-mb-80">
        <BenefitsHotSeat></BenefitsHotSeat>
      </div>
      {/* benefits end*/}

      {/* Image Gallery */}
      {isSmallDevice &&
        <div className="container mb-80 mt-110 md-mb-10 md-mt-10">
          <div className="service-home">
            <h2 className="mainheading titlesizechange  pb-60 md-pb-10">Everyone can benefit from workplace solutions. Whatever your requirements are, we have a solution for you. Find the best coworking solution for you.</h2>
          </div>
          <div className="slider-wrappers">
            <div className="portfolio_slider_two survey-slider" style={{ padding: "20px" }}>
              <ImageGalleryHotSeat />
            </div>
          </div>
        </div>
      }
      {isMediumDevice &&
        <div className="container mb-80 mt-110 md-mb-10 md-mt-10">
          <div className="service-home">
            <h2 className="mainheading titlesizechange  pb-60 md-pb-10">Everyone can benefit from workplace solutions. Whatever your requirements are, we have a solution for you. Find the best coworking solution for you.</h2>
          </div>
          <div className="slider-wrappers">
            <div className="portfolio_slider_two survey-slider" style={{ padding: "20px" }}>
              <ImageGalleryHotSeat />
            </div>
          </div>
        </div>
      }
      {isLargeDevice &&
        <ImageGalleryHotSeatBigScreen></ImageGalleryHotSeatBigScreen>
      }
      {/* Image Gallery end*/}


      {/* Feature */}
      <div
        className="block-style-thirtySix lg-container  md-pt-40 mt-120 md-mt-30"
        id="feature"
      >
        <div className="container">
          <FeaturesHotSeat></FeaturesHotSeat>
        </div>
      </div>
      {/* Feature end*/}


      {/* // ---  Pricing Details Start ---*/}
      <div className="container">
      <CouponHot></CouponHot>
      </div>
      {/* // --- Pricing Details End ---*/}


      {/* // --- otherservices---*/}
      <div className="container pt-100 md-pt-100">
        <div>
          <h2 className="mainheading" >Other Services</h2>
        </div>
        <div className=" lg-container pt-40  md-pt-10 md-mb-40" id="product">
          <div className=" position-relative">
            <div className="row">
              <div className="product_slider_one arrow-middle-center-otherservices ">
                <OtherServicesHotSeat />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // --- otherservices end---*/}


      {/* // ---faq---*/}
      <div className="faq-classic mt-100 pt-60  pb-50 md-pt-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="title-style-one">
                <h3 className="mainheadingfaq" >Answers to your Hot Seat questions</h3>
                <h6 className=" mt-15">Our team is ready to answer your questions.</h6>
              </div>
            </div>
            <div className="col-lg-7">
              <div >
                <FaqClassicTwo></FaqClassicTwo>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* // ---faq end---*/}


      {/* // ---footer---*/}
      <div className="footer-bg-wrapper">
        <footer className="theme-footer-one pt-70 md-pt-30">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
          </div>
          <div className="container">
            <div className="bottom-footer-content">
              <CopyRight />
            </div>
          </div>
        </footer>
      </div>
      {/* // ---footer end---*/}
    </div>
  );
};

export default HotSeat;
