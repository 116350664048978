import React from "react";


const FeatureContent = [
  {
    icon: "Passionate1",
    meta: "Passionate",
    subTitle: `We are intensely enthusiastic about providing a superior experience.`,
    dataDelay: "0",
  },
  {
    icon: "Respectful1",
    meta: "Respectful",
    subTitle: `We are polite and kind to one another, even when it gets tough.`,
    dataDelay: "100",
  },
  {
    icon: "Ownership1",
    meta: "Ownership",
    subTitle: `We are empowered to do our jobs and work towards a common goal.`,
    dataDelay: "300",
  },
  {
    icon: "Unified1",
    meta: "Unified",
    subTitle: `We work as a team and trust each other to create a seamless experience.`,
    dataDelay: "100",
  }
];

const FeatureFour = () => {
  return (
    <div className="row justify-content-center mobilegrid">
      {FeatureContent.map((val, i) => (
        <div
          className="col-lg-3 col-md-6"
          key={i}
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay={val.dataDelay}
        >
          <div className="block-style-five">
            <div className="icon">
              <img src={`images/icon/${val.icon}.png`} alt="icon" />
            </div>
            <h6 className="title">
              <span>{val.meta}</span>
            </h6>
            <p className="mainpara">{val.subTitle}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default FeatureFour;
