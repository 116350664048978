import React from "react";
const featureList = [
    {
        icon: "bulp",
        title: "Business Address Registration documents",
        description: `Fully-equipped spaces for gatherings of any size`,
    },
    {
        icon: "hand",
        title: "Mail & Courier Management",
        description: `Meet in any major city, town or transport hub`,
    },
    {
        icon: "network",
        title: "Favoured Reception",
        description: `Event rooms, boardrooms, training rooms and more`,
    },
    {
        icon: "network",
        title: "Favoured Reception",
        description: `Comfortable, professional environments`,
    },

];
const FeaturesMeetingRoom = () => {
    return (
        <div className="container">
            <div className="block-style-thirty mt-120 mb-80 md-mb-10 md-mt-10">
                <div className="row col-reverse" style={{ padding: "5px" }}>
                    <div className="col-xl-6 col-lg-6 " style={{ textAlign: "-webkit-center" }} >
                        <img src="images/meeting/features_meeting.jpg" alt="working space"></img>
                    </div>
                    <div className="col-xl-6 col-lg-6 mt-100 mb-100 md-mt-20 md-mb-10">
                        <div className="text-block md-pb-10">

                            <div className="title-style-nine mb-20 md-mb-10">
                                <h3 class="mainheading pb-20">Rooms for every type of meeting.</h3>
                                <p className="mainpara" style={{ width: "100%", padding: "0 0 10px", margin: "0px 0 0", lineHeight: "22px", fontSize:"16px" }}>Innovspace is a professionally blended Business Accelerator cum Coworking space in Coimbatore, offering a rich corporate work setup for Startups, Private companies, Freelancers, NGOs, CXOs, Business Consultants and Mainstream organisations at the most comfortable budget</p>
                            </div>

                            <ul className="list-item">
                                {featureList.map((list, i) => (
                                    <li key={i}>
                                        <span style={{ width: "380px" }} className="points">{list.description}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    );
};

export default FeaturesMeetingRoom;
