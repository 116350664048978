import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../../components/header/Header";
import Footer from "../../../components/footer/Footer";
import CopyRight from "../../../components/footer/CopyRight";
import BlogDetailsForm from "../../../components/form/BlogDetailsForm";

const BlogPage = () => {
  const blogPageUrl = 'https://innovspace.com/blog-page';
  const shareUrl = `${blogPageUrl}?v=${Date.now()}`;
  const shareOnTwitter = () => {
      window.open(`https://twitter.com/intent/tweet?url=${encodeURIComponent(blogPageUrl)}`, '_blank');
    };
  
    const shareOnFacebook = () => {
      window.open(`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}`, '_blank');
    };
  return (
    <div className="main-page-wrapper p0">
      <Helmet>
        <title>Embracing Change: Gleaning Insights from the Churning of the Ocean</title>
        <meta name="description" content="coworking space coimbatore" />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href="https://web.archive.org/web/20211017125546/https://www.innovspace.com/blogs/walk/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Embracing Change: Gleaning Insights from the Churning of the Ocean" />
        <meta property="og:description" content="coworking space coimbatore" />
        <meta property="og:url" content="https://web.archive.org/web/20211017125546/https://www.innovspace.com/blogs/walk/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta property="article:section" content="TGIF" />
        <meta property="article:published_time" content="2017-09-20T09:24:20+00:00" />
        <meta property="article:modified_time" content="2017-09-20T09:25:23+00:00" />
        <meta property="og:updated_time" content="2017-09-20T09:25:23+00:00" />
 
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description" content="coworking space coimbatore" />
        <meta name="twitter:title" content="Embracing Change: Gleaning Insights from the Churning of the Ocean" />
        <meta name="twitter:image"
          content="https://web.archive.org/web/20211017125546im_/https://www.innovspace.com/wp-content/uploads/2017/09/the-walk.jpg" />
      </Helmet>
      {/* End Page SEO Content */}

      <Header />
      {/* End Header */}

      {/* =============================================
            Fancy Hero Two
        ==============================================  */}
      {/* /.fancy-hero-two */}

      {/* =====================================================
            Feature Blog One
        ===================================================== */}
      <div className="blog-page-bg md-pb-10">
        <div className="shapes shape-one"></div>
        <div className="shapes shape-two"></div>
        <div className="shapes shape-three"></div>
        <div className="container">
          <div className="row">

            <div className="col-xl-8 offset-xl-1 col-lg-8 feature-blog-one width-lg blog-details-post-v1">
              <div className="post-meta">
                <h1 className="mainheading-blog">Embracing Change: Gleaning Insights from the Churning of the Ocean</h1>
                <div className="tag">Oct 31, 2023</div>
                <img src="images/blog/Churning.png" alt="media post" className="image-meta" />
                {/* <h3 className="title"> Quis Nostr Exercitation Ullamco Laboris nisi ut Aliquip exeal nothing. </h3> */}
                <p className="blog-para">
                  Once upon a time in the ancient land of Bharat, as narrated in the grand epic Mahabharata, there unfolded a
                  remarkable tale known as the Samudra Manthan, or the Churning of the Ocean. This allegory, brimming with
                  timeless wisdom, subtly unveils the essence of fostering a growth mindset amidst a team, a virtue pivotal
                  for navigating through the turbulent waves of change.
                </p>
                <p>
                  The story spirals into a moment in time where the Devas (gods) and Asuras (demons) formed an unlikely alliance
                  to churn the cosmic ocean. They aimed to obtain Amrita, the nectar of immortality, a goal so alluring yet distant.
                  Employing Mount Mandara as the churning rod and Vasuki, the mighty serpent, as the rope, they embarked on a venture
                  unprecedented. Despite the inherent rivalry and discord, they collaborated towards a shared vision, showcasing that
                  "even amidst diversity, a common goal can unite hearts and efforts."
                </p>
                <p>As the churning commenced, the calm waters turned tumultuous, reflecting the chaos that often accompanies change.
                  Amidst the fierce churning, emerged not the nectar, but a deadly poison that threatened to consume all of creation.
                  It was a dire situation, unforeseen yet inevitable. However, the Devas and Asuras did not retreat; instead, they
                  sought the aid of Lord Shiva, who consumed the poison, paving the path forward. This instance symbolizes that</p>
                <blockquote className="blog-quote">
                  "adversities often precede triumphs, and the ability to adapt to unforeseen challenges is the hallmark of a growth mindset."
                </blockquote>
                <p>
                  The relentless endeavor continued, with every twist of Vasuki bringing forth new challenges and treasures. It was a testament to
                </p>
                <blockquote className="blog-quote">
                  "persistent effort and resilience," virtues synonymous with a growth mindset.</blockquote>
                <img src="images/blog/Churnocean.jpg" alt="media post" className="image-meta mt-35" />
                {/* <div className="mark-text"> */}
                <p>
                  The journey was arduous, filled with trials, yet the Devas and Asuras did not deter from their quest. Each hurdle was a
                  lesson, every challenge an opportunity for growth, emphasizing that
                </p>
                <blockquote className="blog-quote">
                  "learning from experiences, whether triumphant or challenging, is crucial for nurturing a 	growth mindset."</blockquote>
                <p>
                  As the celestial waters finally calmed, yielding the coveted nectar of immortality, the Devas and Asuras realized the
                  essence of shared vision and collaboration. The tale culminated with the distribution of the divine nectar, symbolizing that
                </p>
                <blockquote className="blog-quote">
                  "a shared vision, coupled with a growth mindset, can lead to the realization of even the 	most ambitious goals."</blockquote>
                {/* <h2 className="mainheading mt-40">Work Harder & Gain Succsess.</h2> */}
                <p>
                  The tale of Samudra Manthan transcends time, whispering the ageless wisdom of embracing change with a growth mindset, a lesson
                  profound for teams and individuals alike as they sail through the ever-evolving tide of existence, towards a horizon laden with
                  endless possibilities.
                </p>
                {/* <p>
                  is true as we experience the emotional sensation of stress
                  from our firs social rejection ridicule.We quickly learn to
                  fear and thus automatically. potentially stressful situation
                  of all kinds, including the most common of all.
                </p> */}
                <div className="d-sm-flex align-items-center justify-content-between share-area">
                  <ul className="tag-feature d-flex">
                    <li>Tag: &nbsp;</li>
                    <li>
                      <a href="#">business,</a>
                    </li>
                    <li>
                      <a href="#">marketing,</a>
                    </li>
                    <li>
                      <a href="#">tips</a>
                    </li>
                  </ul>
                  <ul className="share-option d-flex align-items-center">
                    <li>Share</li>
                    {/* <li>
                      <a href="#" style={{ background: " #F6616F" }}>
                        <i className="fa fa-google-plus" aria-hidden="true"></i>
                      </a>
                    </li> */}
                    <li>
                    <a onClick={shareOnTwitter} target="blank" style={{ background: " black" ,display:'flex',justifyContent:'center'}} className="hovericonimage">
                        {/* <i className="fab fa-twitter" aria-hidden="true"></i> */}
                        <img src="images/icon/twitter-x.svg" alt="Innovspace" style={{width:'18px'}}></img>
                      </a>
                    </li>
                    <li>
                      <a onClick={shareOnFacebook} target="blank" style={{ background: " #588DE7" }} className="hovericonimage">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              {/* /End post content  */}


            </div>
            {/* End .col */}
          </div>
          {/* End .row */}
        </div>
      </div>
      {/* /.feature-blog-one */}

      <footer className="theme-footer-one pt-130 md-pt-30">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default BlogPage;
