import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";

const socialContent = [
  {
    icon: "fa-facebook",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-twitter",
    link: "https://twitter.com/",
  },
  {
    icon: "fa-linkedin",
    link: "https://www.linkedin.com/",
  },
  {
    icon: "fa-meetup",
    link: "https://www.facebook.com/",
  },
  {
    icon: "fa-pinterest",
    link: "https://twitter.com/",
  },
  {
    icon: "fa-instagram",
    link: "https://www.linkedin.com/",
  },
];

const CopyRight = () => {
  const [isTwitterHovered, setIsTwitterHovered] = useState(false);

  return (
    <div className="d-md-flex justify-content-between">
      <ul className="order-md-last">
      <li><p style={{color:"#fff"}}>
        @Innovspace{new Date().getFullYear()}.{" "}
       
        All Rights Reserved</p>
        </li>
      
      
        {/* <li>
          <Link to="/terms-conditions">Private Policy</Link>
        </li> */}
        <li>
          <Link to="/terms-conditions" onClick={() => window.scrollTo(0, 0)}>Terms And Conditions</Link>
        </li>
      </ul>
      <div className="col-lg-2 order-lg-2 mb-10" style={{display:'flex'}}>
        <ul
          className=" d-flex
              marginleft
              social-icon
              footeralignment
              md-mt-10
              "
        >
          {/* {socialContent.map((val, i) => (
            <li key={i}>
              <a href={val.link} target="_blank" rel="noreferrer">
                <i className={`fa ${val.icon}`} style={{fontSize:"18px"}}></i>
              </a>
            </li>
          ))} */}
          <li>
          <a href="https://twitter.com/innovspace" target="_blank" rel="noreferrer" style={{width:'24px',marginTop:'7px'}}
          
          onMouseEnter={() => setIsTwitterHovered(true)}
          onMouseLeave={() => setIsTwitterHovered(false)}>
              {/* <i className="fa fa-twitter fa-twitter-col"></i> */}
              <img  src={isTwitterHovered ? "images/icon/twitter-color.svg" : "images/icon/twitter-gray.svg"} alt="Innovspace" style={{width:'24px'}} className="hovertwitter"></img>
            </a>
          </li>
          <li>
            <a href="https://www.facebook.com/InnovspaceAccelerator/" target="_blank" rel="noreferrer">
              <i className="fa fa-facebook fa-facebook-col" ></i>
            </a>
          </li>
        
          <li>
            <a href="https://in.linkedin.com/company/innovspace" target="_blank" rel="noreferrer">
              <i className="fa fa-linkedin fa-linked-col"></i>
            </a>
          </li>
          {/* <li>
            <a href="https://www.meetup.com/Innovspace-Business-Accelerator/" target="_blank" rel="noreferrer">
              <i className="fa fa-meetup fa-meetup-col"></i>
            </a>
          </li> */}
          <li>
            <a href="https://www.pinterest.com/innovspace/" target="_blank" rel="noreferrer">
              <i className="fa fa-pinterest fa-paint-col"></i>
            </a>
          </li>
          <li>
            <a href="https://www.instagram.com/innovspace_coworking/" target="_blank" rel="noreferrer">
              <i className="fa fa-instagram fa-instagram-col"></i>
            </a>
          </li>
          
        </ul>
       
        {/* End .social-icon */}
      </div>
      
      
    </div>
  );
};

export default CopyRight;
