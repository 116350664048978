import React from "react";
import { Helmet } from "react-helmet";
import SliderCarousel from "../../components/form-survey-landing/SliderCarousel";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import FancyTextHomeVirtualOff from "../../components/fancy-text-block/FancyTestBlockVirtualOff";
import OtherServicesVirtual from "../../components/vr-landing/OtherServicesVirtual";
import CouponVirtual from "../../components/coupon/CouponVirtual";
import FaqVirtualOffice from "../../components/faq/FaqVirtualOffice";
import BenefitsVirtualOffice from "../../components/benefits/BenefitsVirtualOffice";

const VirtualOffice = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Virtual Office Space | Meeting Rooms for Rent | Conference Room Coimbatore</title>
        <meta name="description"
          content="Workspaces including virtual offices and co-working spaces are available in Coimbatore at competitive prices tailored to your business needs." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical"
          href="https://web.archive.org/web/20230609204856/https://www.innovspace.com/workspaces/virtual-office/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Virtual Office Space | Meeting Rooms for Rent | Conference Room Coimbatore" />
        <meta property="og:description"
          content="Workspaces including virtual offices and co-working spaces are available in Coimbatore at competitive prices tailored to your business needs." />
        <meta property="og:url"
          content="https://web.archive.org/web/20230609204856/https://www.innovspace.com/workspaces/virtual-office/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="Workspaces including virtual offices and co-working spaces are available in Coimbatore at competitive prices tailored to your business needs." />
        <meta name="twitter:title" content="Virtual Office Space | Meeting Rooms for Rent | Conference Room Coimbatore" />
        <meta name="twitter:image"
          content="https://web.archive.org/web/20230609204856im_/https://www.innovspace.com/wp-content/uploads/2017/01/avatar-4.svg" />
      </Helmet>
      <Header></Header>



      {/* banner */}
      <div className="virtual-office">
        <div className="block-style-thirtySix container pt-110 md-pt-40" >
          <div className="container">
            <div className="row " >
              <div className="col-xl-6 col-lg-6 ">
                <div className="vir-mg md-pt-25 mt-50 ">
                  <div className="title-style-fourteen">
                    <h1 className="mainheading">Virtual Office</h1>
                  </div>
                  <div className="mt-15">
                  </div>
                  <p className="mainpara pt-10">
                    In today's global marketplace, establishing a strong local presence is vital. To this end, Innovspace presents a
                    comprehensive Virtual Office service. Perfectly crafted for businesses seeking to mark their footprint locally,
                    while operating from afar. Whether you're a startup, an established business expanding territory, or a freelancer
                    targeting a specific market, our Virtual Office acts as your professional local presence, amplifying your credibility and reach.
                  </p>
                  <div className="mt-30">
                    <Link to="/contact-us">
                      <button className="btn-primary1" >Talk to an expert</button></Link>
                  </div>
                </div>
              </div>
              <div className="col-xl-6 col-lg-6 md-pt-20 " style={{ padding: "10px" }}>
                <SliderCarousel></SliderCarousel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* banner end*/}



      {/*facilities*/}
      <FancyTextHomeVirtualOff></FancyTextHomeVirtualOff>
      {/*facilities end*/}



      {/*new component*/}
      <div className="container virtualoffice-bg md-mt-70 mt-140">
        <div className="row">
          <div className="col-md-5 col-xl-5"></div>
          <div className="col-md-7 col-xl-7">
            <div className="virtual-choose">
              <h3 className="mainheading mt-10">Choose Innovspace for Your Virtual Office</h3>
              <p className="mainpara mt-20">Stepping into the digital age with elegance and efficiency, Innovspace pioneers the virtual office revolution in Coimbatore. We offer you a prestigious business address in a prime location, enhancing your brand's local credibility. Our professional reception team and comprehensive mail management services ensure seamless communication, while you enjoy the perks of a high-profile business presence without the overheads of a physical workspace. </p>
              <p className="mainpara pt-10 pb-10">Our offerings are flexible and cost-effective, designed to adapt to your evolving business needs. And with our position as the first in Coimbatore to offer these services, we stand as a symbol of stability and innovation. At Innovspace, we are more than a service provider; we are partners in your journey to success. Choose us and experience the unrivalled benefits of our Virtual Office solutions.</p>
            </div></div>
        </div>
      </div>
      {/*new component end*/}


      {/*benefits*/}
      <div className="container mt-140 md-mt-20">
        <BenefitsVirtualOffice></BenefitsVirtualOffice>
      </div>
      {/*benefits*/}



      {/* // ---  Pricing Details Start ---*/}
      <div className="container">
      <CouponVirtual></CouponVirtual>
      </div>
      {/* // --- Pricing Details End ---*/}



      {/*other services*/}
      <div className="container pt-120 md-pt-100 ">
        <div>
          <h3 className="mainheading">Other Services</h3>
        </div>
        <div className=" lg-container pt-40  md-pt-10 md-mb-40" id="product">
          <div className=" position-relative">
            <div className="row">
              <div >
                <div className="product_slider_one arrow-middle-center-otherservices otherservicespadding">
                  <OtherServicesVirtual></OtherServicesVirtual>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*other services end*/}


      {/*faq*/}
      <div className="faq-classic mt-100 pt-60  pb-50 md-pt-50">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="title-style-one">
                <h3 className="mainheadingfaq" >Answers to your Virtual Office questions</h3>
                <h6 className=" mt-15">Our team is ready to answer your questions.</h6>
              </div>
            </div>
            <div className="col-lg-7">
              <div >
                <FaqVirtualOffice></FaqVirtualOffice>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*faq end*/}


      {/*fotter*/}
      <div className="footer-bg-wrapper">
        <footer className="theme-footer-one pt-70 md-pt-30 ">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
          </div>
          <div className="container ">
            <div className="bottom-footer-content ">
              <CopyRight />
            </div>
          </div>
        </footer>
        {/*footer*/}
      </div>
    </div>

  );
};

export default VirtualOffice;
