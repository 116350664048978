import { useState, useEffect } from 'react';
import './TestimonialHome.css';
import { Link } from 'react-router-dom';



const TestimonialHome = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    { id: 1, image: "I hope this email finds you well. I am writing to express my utmost satisfaction and appreciation for the outstanding coworking space you have created. Since joining your establishment, I have been impressed by the impeccable facilities and the welcoming atmosphere.",
  icon:"images/logo/BigIdea.png", name:"Kanimozhi. R"},
    { id: 2, image: "Innovspace has truly transformed our work experience. With its comfortable and inspiring environment, high-speed internet, and well-equipped meeting rooms, our productivity has rapidly increased." ,
    icon:"images/logo/softworth.png" ,name:"Hari Krishnan V R"},
    { id: 3, image: " As a startup founder, this co-working space has been instrumental in the early stages of our company. The flexible membership options allowed us to scale up or down as needed, giving us the freedom to adapt to our growing team.",
    icon:"images/logo/fynanziare1.png" ,name:"Sabarinathan" },
    { id: 4, image: "I’ve been a member of this co-working space for over a year, and I can confidently say it’s been a game-changer for my productivity and business. The environment is vibrant and professional, providing the perfect balance of focus and collaboration.",
  icon:"images/logo/halley.png", name:"Shanmuga Sundaram"},
    { id: 5, image: "I've been working in this co-working space along with 20 other of my employees for the past one and half year. It's more than just a place to work; it's a community that fosters creativity, collaboration, and productivity." ,
    icon:"images/logo/Your Farm1.png" ,name:"Ramya"}
    // { id: 5, image: "For freelancers and solopreneurs seeking flexible workspace, our Hot Desk option offers the freedom to work on diverse projects. Plug in and start working immediately, surrounded by like-minded individuals who inspire and motivate you.",
    // icon:"images/logo/logo-25.png" ,name:"Karan S" },
    // { id: 6, image: "If you're a small business owner looking to establish a professional presence, our Hot Desk provides an affordable and flexible solution. Collaborate with other entrepreneurs, network, and benefit from the synergies within our coworking community.",
    // icon:"images/logo/logo-24.png" ,name:"Dwarakh" }
  ];

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, [slides.length]);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const renderSlides = () => {
    return slides.map((slide, index) => (
      <div className={`slide_home ${index === currentIndex ? 'active' : ''}`} key={slide.id}>
        <h2 className='testimonial-heading'>{slide.name}</h2>
        <p>{slide.image}</p>
        <Link to="/testimonial" className='btn-link' style={{paddingTop:"20px", color: "fff"}}> Read More <span>  <i className="fa fa-chevron-right" aria-hidden="true"
            style={{ fontSize: "11px", fontWeight: "100"}}></i> </span></Link>
       
        {/* <img src={slide.image} alt={`Slide ${slide.id}`} /> */}
      </div>
    ));
  };

  const renderThumbnails = () => {
    return slides.map((slide, index) => (
      <div
        className={`thumbnail_home ${index === currentIndex ? 'active' : ''}`}
        key={slide.id}
        onClick={() => handleThumbnailClick(index)}
      >
        <img src={slide.icon} alt={`Thumbnail ${slide.id}`} />
      </div>
    ));
  };

//   const nextSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
//   };

//   const previousSlide = () => {
//     setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
//   };

  return (
    <div className="carousel-container" >
      <div className="slider_home">
        {renderSlides()}
      </div>

      <div className="thumbnails_testimonial">
        {/* <div className="arrows">
          <div className="arrow left-arrow" onClick={previousSlide}></div>
        </div> */}
        {renderThumbnails()}
        {/* <div className="arrows">
          <div className="arrow right-arrow" onClick={nextSlide}></div>
        </div> */}
      </div>
    </div>
  );
};

export default TestimonialHome;
