import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is a virtual office?",
    desc: `A virtual office offers businesses a professional address and office amenities without a physical workspace. It provides services like mail handling, call answering, and pay-as-you-go access to meeting rooms.`,
    expand: "a",
  },
  {
    title: "What are the benefits of a virtual office?",
    desc: `Virtual offices offer several benefits, including a prestigious business address, professional mail handling, a dedicated phone number with call answering services, access to meeting rooms and coworking spaces, and the flexibility to work remotely while maintaining a professional image.`,
    expand: "b",
  },
  {
    title: "Can I use the virtual office address as my official business address?",
    desc: `Yes, you can use the virtual office address as your official business address for mail correspondence, marketing materials, and online listings. It provides a professional image for your business without the need for a physical office space.`,
    expand: "c",
  },
  {
    title: "How does mail handling work in a virtual office?",
    desc: `In a virtual office, mail handling includes receiving and sorting mail on your behalf. The provider can forward it to a designated address or provide pickup options at the coworking space. Additional services like mail scanning and digital storage may be offered for convenient access.`,
    expand: "d",
  },
  {
    title: "Can I access meeting rooms or coworking spaces with a virtual office?",
    desc: `Yes, virtual office packages often include access to meeting rooms and coworking spaces on a pay-as-you-go basis. This allows you to book meeting rooms or use coworking spaces for client meetings, team gatherings, or when you need a physical workspace temporarily.`,
    expand: "e",
  },
  {
    title: "Can I use the virtual office address for business registration and licensing?",
    desc: `In most cases, you can use the virtual office address for business registration and licensing purposes. However, it's important to check the regulations and requirements of your specific jurisdiction, as some authorities may have restrictions on using virtual office addresses for official registrations.`,
    expand: "f",
  },

];

const FaqVirtualOffice = () => {
  return (
    <div className="accordion-style-four ">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqVirtualOffice;
