import React from 'react'

const ImageGalleryHotSeatBigScreen = () => {
  return (
    <div className="container mb-80 mt-110 md-mb-10 md-mt-10">
    <div className="service-home">
        <h2 className="mainheading  pb-60 md-pb-20">Everyone can benefit from workplace solutions. Whatever your requirements are, we have a solution for you. Find the best coworking solution for you.</h2>
      </div>
      
    <div className="row product_slider_one arrow-middle-center">
     <div className="col-lg-6 col-md-4 md-mt-10" > <img src="images/hotseat/hotseat_1.jpg" alt="business rooms" className="w-100" style={{borderRadius:"10px"}}/> </div>
     <div className="col-lg-6 col-md-4 "> 
     <div className="row ">
     <div className="col-lg-6 col-md-4 md-mt-10 " > <img src="images/hotseat/hotseat_2.jpg" alt="startup office spaces" className="w-100" style={{borderRadius:"10px"}}/> </div>
     <div className="col-lg-6 col-md-4 md-mt-10 " > <img src="images/hotseat/hotseat_3.jpg" alt="business rooms" className="w-100" style={{borderRadius:"10px"}}/> </div>
     </div>
     <div className="row pt-25 md-pt-10 ">
     <div className="col-lg-6 col-md-4 " > <img src="images/hotseat/hotseat_4.jpg" alt="coworking workspace" className="w-100" style={{borderRadius:"10px"}}/> </div>
     <div className="col-lg-6 col-md-4 md-mt-10 " > <img src="images/hotseat/hotseat_5.jpg" alt="coworking startup" className="w-100" style={{borderRadius:"10px"}}/> </div>
     </div>
     </div>
    </div>
 </div>
  )
}

export default ImageGalleryHotSeatBigScreen