import React from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import DayPassFacilities from "../../components/fancy-text-block/DayPassFacilities";
import OtherServicesDayPass from "../../components/vr-landing/OtherServicesDayPass";
import CouponDay from "../../components/coupon/CouponDay";
import FaqDayPass from "../../components/faq/FaqDayPass";
import FeaturesDayPass from "../../components/form-survey-landing/FeaturesDayPass";
import ImageGalleryDayPass from "../../components/vr-landing/ImageGalleryDayPass";
import { useMediaQuery } from "@uidotdev/usehooks";


const DayPass = () => {
    const isSmallDevice = useMediaQuery("only screen and (max-width : 768px)");
    const isMediumDevice = useMediaQuery(
        "only screen and (min-width : 769px) and (max-width : 992px)"
    );
    const isLargeDevice = useMediaQuery(
        "only screen and (min-width : 993px) "
    );
    return (
        <div className="main-page-wrapper p0 font-gordita">
            <Helmet>
                <title>Co-working Shared Office Space for Rent | Business Center Space Coimbatore</title>
                <meta name="description"
                    content="We provide shared office space and coworking services in Coimabtore with a wide variety of office space solutions suitable for all businesses." />
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href="https://web.archive.org/web/20230609203159/https://www.innovspace.com/partnership/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Co-working Shared Office Space for Rent | Business Center Space Coimbatore" />
                <meta property="og:description"
                    content="We provide shared office space and coworking services in Coimabtore with a wide variety of office space solutions suitable for all businesses." />
                <meta property="og:url"
                    content="https://web.archive.org/web/20230609203159/https://www.innovspace.com/partnership/" />
                <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
                <meta property="og:image"
                    content="https://web.archive.org/web/20230609203159im_/http://www.innovspace.com/wp-content/uploads/2017/01/hr.png" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description"
                    content="We provide shared office space and coworking services in Coimabtore with a wide variety of office space solutions suitable for all businesses." />
                <meta name="twitter:title" content="Co-working Shared Office Space for Rent | Business Center Space Coimbatore" />
                <meta name="twitter:image"
                    content="https://web.archive.org/web/20230609203159im_/http://www.innovspace.com/wp-content/uploads/2017/01/hr.png" />
            </Helmet>
            <Header></Header>


            {/* -- banner large screen-- */}
            {isLargeDevice &&
                <div className="container mb-40 mt-100" style={{ backgroundColor: "#f5f5f5", padding: "32px", maxWidth: "1400px" }}>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 day-content" >
                            <div className="block-style-thirtySix">
                                <div className="title-style-fourteen mb-55" style={{ marginRight: "50px" }} >
                                    <h3 className="mainheading">Day Pass</h3>
                                    <p style={{ fontSize: "16px", paddingTop: "20px", lineHeight: "normal" }}>
                                        Our bright, elegantly designed open-plan coworking spaces can enhance the way you work.
                                        They provide the opportunity to be a part of a vibrant community in our shared office space.</p>
                                    <p style={{ fontSize: "16px", paddingTop: "20px", lineHeight: "normal" }}>
                                        They provide the opportunity to be a part of a vibrant community in our shared office space.</p>
                                    <div className="mb-5 tag-line">
                                        <Link to="/contact-us">
                                            <button className="button-banner">Talk to an expert</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-3">
                            <div>
                                <img src="images/hotseat/hotseat_1.jpg" alt="rent a coworking space" className="w-100 day-image" />
                            </div>
                            <div>
                                <img src="images/hotseat/hotseat_2.jpg" alt="shared office" className="w-100 day-image" style={{ marginTop: "15px" }} />
                            </div>
                        </div>
                        <div className="col-3">
                            <div >
                                <img src="images/hotseat/hotseat_3.jpg" alt="shared workspace" className="w-100 day-image" />
                            </div>
                            <div>
                                <img src="images/hotseat/hotseat_4.jpg" alt="co working space in hyderabad" className="w-100 day-image" style={{ marginTop: "15px" }} />
                            </div>
                        </div>
                    </div>
                </div>}
            {/* -- banner large screen end-- */}

            {/* -- banner large screen end-- */}


            {/* -- banner medium screen-- */}
            {
                isMediumDevice &&
                <div className="container  mt-100 md-mt-60 " style={{ backgroundColor: "#f5f5f5", maxWidth: "1400px", paddingBottom: "100px" }}>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 day-content" >
                            <div className="block-style-thirtySix">
                                <div className="title-style-fourteen mb-55 md-mb-30"  >
                                    <h1 className="mainheading md-mt-40">Day Pass</h1>
                                    <p style={{ fontSize: "16px", paddingTop: "20px", lineHeight: "normal" }}>
                                        Our bright, elegantly designed open-plan coworking spaces can enhance the way you work.
                                        They provide the opportunity to be a part of a vibrant community in our shared office space.</p>
                                    <p style={{ fontSize: "16px", paddingTop: "20px", lineHeight: "normal" }}>
                                        They provide the opportunity to be a part of a vibrant community in our shared office space.</p>
                                    <div className="md-mt-30 ">
                                        <Link to="/contact-us">
                                            <button className="button-banner ">Talk to an expert</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6" >
                            <div className="slider-wrappers">
                                <div className="portfolio_slider_two survey-slider" >
                                    <ImageGalleryDayPass></ImageGalleryDayPass>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* -- banner medium screen end-- */}


            {/* -- banner small screen-- */}
            {
                isSmallDevice &&
                <div className="container  mt-100 md-mt-60 " style={{ backgroundColor: "#f5f5f5", maxWidth: "1400px", paddingBottom: "100px" }}>
                    <div className="row">
                        <div className="col-xl-6 col-lg-6 day-content" >
                            <div className="block-style-thirtySix">
                                <div className="title-style-fourteen mb-55 md-mb-30"  >
                                    <h1 className="mainheading md-mt-40">Day Pass</h1>
                                    <p style={{ fontSize: "16px", paddingTop: "20px", lineHeight: "normal" }}>
                                        Our bright, elegantly designed open-plan coworking spaces can enhance the way you work.
                                        They provide the opportunity to be a part of a vibrant community in our shared office space.</p>
                                    <p style={{ fontSize: "16px", paddingTop: "20px", lineHeight: "normal" }}>
                                        They provide the opportunity to be a part of a vibrant community in our shared office space.</p>
                                    <div className="md-mt-30 ">
                                        <Link to="/contact-us">
                                            <button className="button-banner ">Talk to an expert</button></Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6" >
                            <div className="slider-wrapper">
                                <div className="portfolio_slider_two survey-slider" >
                                    <ImageGalleryDayPass></ImageGalleryDayPass>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
            {/* -- banner small screen end-- */}



            {/* <!--facilities--> */}
            <div className="fancy-text-block-three mt-150 mb-80 md-mt-0 md-mb-10 md-pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-11 m-auto">
                            <DayPassFacilities />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--facilities end--> */}



            {/* <!--features--> */}
            <div className="block-style-thirtySix lg-container  mt-160 md-mt-10" id="feature">
                <div className="container">
                    <FeaturesDayPass></FeaturesDayPass>
                </div>
            </div>
            {/* <!--features end--> */}



            {/* // ---  Pricing Details Start ---*/}
            <div className="container">
            <CouponDay></CouponDay>
            </div>
            {/* // --- Pricing Details End ---*/}



            {/* <!--other services--> */}
            <div className="container pt-100 md-pt-100 ">
                <div>
                    <h2 className="mainheading" >Other Services</h2>
                </div>
                <div className=" lg-container pt-40  md-pt-10 md-mb-40" id="product">
                    <div className=" position-relative">
                        <div className="row">
                            <div className="product_slider_one arrow-middle-center-otherservices otherservicespadding">
                                <OtherServicesDayPass />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--other services end--> */}



            {/* <!--faq--> */}
            <div className="faq-classic mt-100 pt-60 pb-50 md-pt-50">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-5">
                            <div className="title-style-one">
                                <h3 className="mainheadingfaq" >Answers to your Day Pass questions</h3>
                                <h6 className=" mt-15">Our team is ready to answer your questions.</h6>
                            </div>
                        </div>
                        <div className="col-lg-7">
                            <div >
                                <FaqDayPass></FaqDayPass>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--faq end--> */}


            {/* <!--footer--> */}
            <div className="footer-bg-wrapper">
                <footer className="theme-footer-one pt-70 md-pt-30">
                    <div className="top-footer">
                        <div className="container">
                            <Footer />
                        </div>
                    </div>
                    <div className="container">
                        <div className="bottom-footer-content">
                            <CopyRight />
                        </div>
                    </div>
                </footer>
                {/* /footer end */}
            </div>
        </div>

    );
};

export default DayPass;