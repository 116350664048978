import React, { useState, useEffect } from 'react';



const SliderCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      id: 1,
      image: "virtual_1",
      alt:"shared work space",
     
    },
    {
      id: 2,
      image: "virtual_2",
      alt:"co work",
    },
    {
      id: 3,
      image: "virtual_3",
      alt:"shared working",
    },
    {
      id: 4,
      image: "virtual_4",
      alt:"free working space",
    },
    {
      id: 5,
      image: "virtual_5",
      alt:"it work space",
    },
    {
      id: 6,
      image: "virtual_6",
      alt:"shared office",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

     const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const renderSlides = () => {
    return slides.map((slide, index) => (
      <div className={`slide ${index === currentIndex ? 'active' : ''}`} key={slide.id}>
        <img src={`images/service/${slide.image}.jpg`} alt={`Slide ${slide.id} ${slide.alt}` } />
      </div>
    ));
  };

  const renderThumbnails = () => {
    return slides.map((slide, index) => (
      <div
        className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
        key={slide.id}
        onClick={() => handleThumbnailClick(index)}
      >
        <img src={`images/service/${slide.image}.jpg`} alt={`Thumbnail ${slide.id}`} />
      </div>
    ));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const previousSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };
 
  return (
    <div className="carousel-container" >
      <div className="slider" >
        {renderSlides()}
      </div>

      <div className="thumbnails">
        <div className="virtual-arrows">
          <div className="arrow left-arrow" onClick={previousSlide}></div>
        </div>
        {renderThumbnails()}
        <div className="virtual-arrows">
          <div className="arrow right-arrow" onClick={nextSlide}></div>
        </div>
      </div>
    </div>
  );
};

export default SliderCarousel;
