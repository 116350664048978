
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import emailjs from '@emailjs/browser';
//import React, { useState, useRef } from 'react';
import axios from 'axios';

import { useState ,useRef} from "react";

function CandidateForm() {
    const [Spaceinterested, setSpaceinterested] = useState('');
    const [name, setname] = useState("");
    
    const [phone, setphone] = useState("");
    const [email, setemail] = useState("");
    const [company, setcompany] = useState("");
    const [natureofbussiness, setnatureofbussiness] = useState("");
    const [city, setcity] = useState("");
    const [anyothers, setanyothers] = useState("");
    const [duration, setduration] = useState("");
    const [startfrom, setstartfrom] = useState("");
    const [noofseats, setnoofseats] = useState("");
    const [noofcabins, setnoofcabins] = useState("");
    const [description, setdescription] = useState("");
    const [formErrors, setFormErrors] = useState({});
    const [submissionStatus, setSubmissionStatus] = useState(null);
    const [spaceInterestedError, setSpaceInterestedError] = useState(false);
    const [spaceInterestedError1, setSpaceInterestedError1] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [checkboxError, setCheckboxError] = useState('');
    const [jobapplidfor, setjobapplidfor] = useState('');
    const [jobapplidforError, setjobapplidforError] = useState(false); 
    const [selectedFile, setSelectedFile] = useState(null);
    const [filename, setfilename] = useState("");
    const [conten,setcontent]=useState("");
    const [errorfile, setError] = useState(false);
    const [storefile,setstorefile]=useState(null);
    const [validate,setvalidate] = useState(false);
      const [errorMessage, setErrorMessage] = useState("");
      const hiddenFileInput = useRef(null);
      
      
    
//    useEffect(()=>{
// if(validate){
//     setstorefile(null)
// }
//    },[validate])
    const handleCheckboxChange = (event) => {
        
        setIsChecked(event.target.checked);
    };
    var filesize;
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        filesize = file.size / 1024;
    
        if (!file) {
          // If no file is chosen
          setErrorMessage("Please select a file.");
          setError(true);
          setstorefile(null);
        } else if (filesize > 500) {
          // If file size exceeds 500 KB
          setErrorMessage("File size must be less than 500 KB.");
          setError(true);
          setstorefile(null);
          event.target.value = null;
        } else {
          // If a valid file is chosen
          setErrorMessage("");
          setError(false);
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            let fileInfo = {
              name: file.name,
              conten: String(reader.result).split(',')[1],
            };
            setfilename(fileInfo.name);
            setcontent(fileInfo.conten);
            setstorefile(file);
          };
        }
      };


    const handleChange = (event) => {
        setSpaceinterested(event.target.value);
    };
    const handlejobapplidfor = (event) => {
        setjobapplidfor(event.target.value);
    };
    const handlename = (event) => {
        setname(event.target.value);
    };
    const handlephone = (event) => {
        setphone(event.target.value);
    };
    const handleemail = (event) => {
        setemail(event.target.value);
    };
    const handlecompany = (event) => {
        setcompany(event.target.value);
    };
    const handlenatureofbussiness = (event) => {
        setnatureofbussiness(event.target.value);
    };
    const handlecity = (event) => {
        setcity(event.target.value);
    };
    const handleanyothers = (event) => {
        setanyothers(event.target.value);
    };
    const handleduration = (event) => {
        setduration(event.target.value);
    };
    const handlestartfrom = (event) => {
        setstartfrom(event.target.value);
    };
    const handlenoofseats = (event) => {
        setnoofseats(event.target.value);
    };
    const handlenoofcabins = (event) => {
        setnoofcabins(event.target.value);
    };
    const handledescription = (event) => {
        setdescription(event.target.value);
    };

    const validateForm = () => {

        const errors = {};
      
  if (!storefile) {
      setError(true);
      setErrorMessage("Please select a file.");
      errors.file = "Please select a file.";
    } else if (filesize > 500) {
      setError(true);
      setErrorMessage("File size must be less than 500 KB.");
      errors.file = "File size must be less than 500 KB.";
    } else {
      setError(false);
      setErrorMessage("");
    }
        if (!isChecked) {
            errors.checkbox = 'Checkbox must be checked';
            setCheckboxError('Checkbox must be checked');
        } else {
            setCheckboxError('');
        }
     

        if (name.trim() === "") {
            errors.name = "Name is required";
        }
        if (Spaceinterested === "") {
            setSpaceInterestedError("Qualification is required");
            errors.spaceInterested = "Qualification is required";
        } else {
            setSpaceInterestedError("");
        }
     
        if (jobapplidfor === "") {
            setjobapplidforError("Job Applied For is required");
            errors.jobapplidfor = "Job Applied For is required";
        } else {
            setjobapplidforError ("");
        }
        if (phone.trim() === "") {
            errors.phone = "Phone number is required";
        } else if (!/^[0-9]{10}$/.test(phone)) {
            errors.phone = "Invalid phone number";
        }
        if (email.trim() === "") {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(email)) {
            errors.email = "Invalid email format";
        }
        if (company.trim() === "") {
            errors.company = "Last Name is required";
        }
        // if (natureofbussiness.trim() === "") {
        //     errors.natureofbussiness = "state is required";
        // }
        // if (city.trim() === "") {
        //     errors.city = "City is required";
        // }
    
        if (anyothers.trim() === "") {
            errors.anyothers = "Major is required";
        }
    
        // if (description.trim() === "") {
        //     errors.description = "Description is required";
        //     setSpaceInterestedError1("true")
        // }
        else {
            setSpaceInterestedError1("false")
        }

        if (startfrom.trim() === "") {
            errors.startfrom = "Total Experience is required";
        }


        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const handleSubmit = (event) => {
        event.preventDefault();

         {
        if (validateForm()){
            if(errorfile!==true){
            // Perform form submission or further processing
            alert("validated")
            const config = {
                headers:{
                'accept':' application/json',
                'api-key':'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',
                'content-type':'application/json'
                }
              };
              const url = "https://api.brevo.com/v3/smtp/email";
              
              const data ={
                
                    "sender":{  
                       "name":"vishal",
                       "email":"vishalrupakvr@gmail.com"
                    },
                    "to":[  
                       {  
                    
                          "email":"vishalrupakvr.19cse@kongu.edu",
                          "name":"Vishl Rupak V R"
                       }
                    ],
                    "subject":"Hello world",
                    "htmlContent":`<html><head></head><body>
                    <h3>Details</h3>
                    <p>First Name: ${name}</p>
                    <p>Last Name:${company}</p>
                    <p>Phone No: ${phone}</p>
                    <p>Email: ${email}</p>
                    <p>Qualification: ${Spaceinterested}</p>
                    <p>Major: ${anyothers}</p>
                    <p>City: ${city}</p>
                    <p>State: ${natureofbussiness}</p>
                    <p>Job Applied For: ${jobapplidfor}</p>
                    <p>Total Experience: ${startfrom}</p>
                     <p>Message: ${description}</p>
                    </body></html>`, 
                    "attachment": [
                        {
                            "content": `${conten}`,
                            "name": `${filename}`
                          }
                      ]
              }    

              axios.post(url, data, config)
                  .then(response => {
                      console.log("data",response.data);
                    })
                    .catch(error => {
                      console.log("error",error);
                    });
              
          
              
            setSubmissionStatus('success');
            console.log("Form submitted successfully!");
            setIsChecked(false);
            setvalidate(true);
            setname("");
            setphone("");
            setemail("");
            setcity("");
            setcompany("");
            setnatureofbussiness("");
            setSpaceinterested("");
            setanyothers("");
            setduration("");
            setjobapplidfor("");
            setdescription("");
            setstartfrom("");
            setstorefile(null);
            hiddenFileInput.current.value = null;
       
        } else {
            console.log("Form validation failed!");
        }}}
    };

    return (

        <div >
            {
                 console.log("datafile",validate)
                 
            }
            <div class="container mt-20">
    

                <form onSubmit={handleSubmit}>
                    <div class="row">
                        <div class="form-group col-md-6 widthset">

                            <TextField id="outlined-basic" label="Name" variant="outlined" onChange={handlename} value={name}
                                error={!!formErrors.name}
                                helperText={formErrors.name}
                                size="small"
                                name="name"
                                fullWidth

                            />

                        </div>
                        <div class="form-group col-md-6 widthset md-mt-20">
                        
                         <TextField id="outlined-basic" label="Last Name" variant="outlined" onChange={handlecompany} value={company}
                                error={!!formErrors.company}
                                helperText={formErrors.company}
                                size="small"
                                fullWidth
                                name="company"
                               
                            />
                            {/* </Box>
      */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 widthset">
                      
                            <TextField id="outlined-basic" label="Email Id" variant="outlined" onChange={handleemail} value={email}
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                                size="small"
                                name="email"
                                fullWidth
                                style={{ marginTop: "20px" }}
                            />
                            {/* </Box> */}
                        </div>
                        <div class="form-group col-md-6 widthset">
                          
                             <TextField id="outlined-basic" label="Phone Number" variant="outlined" onChange={handlephone} value={phone}
                                error={!!formErrors.phone}
                                helperText={formErrors.phone}
                                size="small"
                                fullWidth
                                name="phone"
                                className='md-mt-20'
                                style={{ marginTop: "20px" }}

                            />
                            {/* </Box> */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 widthset">

                              <TextField id="outlined-basic" label="City" variant="outlined" onChange={handlecity} value={city}
                                error={!!formErrors.city}
                                helperText={formErrors.city}
                                size="small"
                                fullWidth
                                name="city"
                                style={{ marginTop: "20px" }}
                            />
                            {/* </Box> */}
                        </div>
                        <div class="form-group col-md-6 widthset">
                        
                        <TextField id="outlined-basic" label="State" variant="outlined" onChange={handlenatureofbussiness} value={natureofbussiness}
                                error={!!formErrors.natureofbussiness}
                                helperText={formErrors.natureofbussiness}
                                size="small"
                                fullWidth
                                name="nature"
                                style={{ marginTop: "20px" }}
                            />
                            {/* </Box> */}
                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 widthset">
            
                      <select style={{ color: "#74808e" }} onChange={handleChange} value={Spaceinterested}
                                error={!!spaceInterestedError}
                                helperText={spaceInterestedError}
                                name="dropdown"
                                className={spaceInterestedError ? "error form-select form-select-lg " : 'selecttag form-select form-select-lg '}
                            >
                                <option value="" disabled selected >Qualification</option>
                                <option value="Bachelor's Degree">Bachelor's Degree</option>
                                <option value="Master's Degree">Master's Degree</option>
                                <option value="Diploma">Diploma</option>
                                <option value="Others">Others</option>
                                
                            </select>
                      
                            {spaceInterestedError && (
                                <FormHelperText error style={{ marginLeft: "10px" }}>{spaceInterestedError}</FormHelperText>
                            )}
                        </div>
                        <div class="form-group col-md-6 widthset">


                            <TextField id="outlined-basic" label="Major" variant="outlined" onChange={handleanyothers} value={anyothers}
                                error={!!formErrors.anyothers}
                                helperText={formErrors.anyothers}
                                size="small"
                                name="anyothers"
                                fullWidth
                                style={{ marginTop: "20px" }}
                            />
                            {/* </Box> */}

                        </div>
                    </div>
                    <div class="row">
                        <div class="form-group col-md-6 widthset">

                    
                              <select style={{ color: "#74808e" }} onChange={handlejobapplidfor} value={jobapplidfor}
                                error={!!jobapplidforError}
                                helperText={jobapplidforError}
                                name="dropdown"
                                className={jobapplidforError ? "error form-select form-select-lg " : 'selecttag form-select form-select-lg '}
                            >
                                <option value="" disabled selected >Job Applied For</option>
                                <option value="Private Space">	Front Desk Executive</option>
                                <option value="Virtual Office">	Client Service Specialist</option>
                          
                                
                            </select>
               
                            {jobapplidforError && (
                                <FormHelperText error style={{ marginLeft: "10px" }}>{jobapplidforError}</FormHelperText>
                            )}
                            {/* </Box> */}
                        </div>
                        <div class="form-group col-md-6 widthset">

                            <TextField id="outlined-basic" label="Total Experience" variant="outlined" onChange={handlestartfrom} value={startfrom}
                                error={!!formErrors.startfrom}
                                helperText={formErrors.startfrom}
                                size="small"
                                name="startfrom"
                                fullWidth
                                style={{ marginTop: "20px" }}
                            />
                            {/* </Box> */}
                        </div>
                    </div>


                    <div class="mobilewidth">
                        <TextField
                            placeholder="Message"
                            fullWidth
                            multiline
                            name="description"
                            sx={{
                                '& > :not(style)': { mt: 1, mb: 1, width: '100%' },
                            }}
                            rows={2}
                            variant='outlined'
                            maxRows={4}
                            style={{ marginTop: "20px" }}
                            onChange={handledescription} value={description}
                            // error={!!formErrors.description || spaceInterestedError1 === "true"}
                            helperText={formErrors.description}
                            InputProps={{
                                style: {
                                    color: spaceInterestedError1 === "true" ? "#d32f2f" : "" // Set the placeholder color to red if description is not given
                                }
                            }}
                        />
                    </div>
                    <div className='form-group mt-10' style={{width:"55%", overflow:"hidden"}}>
                    <input
          type="file"
          onChange={(e) => handleFileChange(e)}
          accept=".pdf,.doc,.txt"
          ref={hiddenFileInput}
        />
        {/* {errorfile && <p style={{ color: "#d32f2f", fontSize: "12px" }}>Please select a file.</p>} */}
        <span style={{ color: "#d32f2f", fontSize: "12px" }}>{errorMessage}</span>
      </div>
      <p style={{ fontSize: "12px" }} className='mt-10'>
        Only pdf, doc, txt is allowed (500kb)
      </p>
                  
                  
                    <div class="mt-10">

                        <div className='privacy'>

                            <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault" checked={isChecked}
                                onChange={handleCheckboxChange} />
                            <p style={{ fontSize: "15px" ,marginTop:"3px"}} className='mainpara mobilewidth'>By Selecting this you agree to our <span className='privatepolicy'> Privacy policy</span></p>

                        </div>

                        {checkboxError && (
                            <FormHelperText error>{checkboxError}</FormHelperText>
                        )}
                        <div className='mt-20'>
                         {submissionStatus === 'success' && (
                            <FormHelperText style={{ color: 'green' }} className='mt-10'>Form submitted successfully!</FormHelperText>
                        )}</div>
                        
                    </div>
                    <div class="mt-20">
                        {console.log("valid",validate)}
                        {validate===false?
                        <button type="submit" class="btn-primary" style={{ width: "270px" }} >Enquiry Now</button>: <button type="submit"  class="btn-primary" style={{ width: "270px" }} disabled>Enquiry Now</button>
                        }
                    </div>

                </form>

            </div>
        </div>
    );
}


export default CandidateForm
