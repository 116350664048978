import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import CorporateBanner from "../../components/form-survey-landing/CorporateBanner";
import PortfolioFive from "../../components/portfolio/PortfolioFive";
import OtherServicesCorporateService from "../../components/vr-landing/OtherServicesCorporateService";
import CouponCorporate from "../../components/coupon/CouponCorporate";
import FaqCorporateService from "../../components/faq/FaqCorporateService";
import FeaturesCorporate from "../../components/form-survey-landing/FeaturesCorporate";
import BenefitsCourporateService from "../../components/benefits/BenefitsCorporateService";
import FancyCorporateService from "../../components/fancy-text-block/FancyCorporateService";
import FancyCorporateServiceTest from "../../components/fancy-text-block/FancyCorporateServiceTest";
import AnchorLink from "react-anchor-link-smooth-scroll";
const CorporateService = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        {/* <title>
          Coworking Space - Innovspace - Business Accelerator | Coworking Space
        </title> */}
        <title>Virtual Meeting Room India | Conference Room for Rent Kalapatti, Coimbatore</title>
        <meta name="description"
          content="Flexible co-working shared office space and business center in Coimbatore offers variety of office space solutions for startups and freelancers." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical"
          href="https://web.archive.org/web/20230609202253/https://www.innovspace.com/benefits/business-benefits/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Virtual Meeting Room India | Conference Room for Rent Kalapatti, Coimbatore" />
        <meta property="og:description"
          content="Flexible co-working shared office space and business center in Coimbatore offers variety of office space solutions for startups and freelancers." />
        <meta property="og:url"
          content="https://web.archive.org/web/20230609202253/https://www.innovspace.com/benefits/business-benefits/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="Flexible co-working shared office space and business center in Coimbatore offers variety of office space solutions for startups and freelancers." />
        <meta name="twitter:title" content="Virtual Meeting Room India | Conference Room for Rent Kalapatti, Coimbatore" />
      </Helmet>
      <Header></Header>


      {/* <!-- banner--> */}
      <div className="mt-80 md-mt-60">
        <CorporateBanner></CorporateBanner>
      </div>
      {/* <!-- banner end --> */}



      {/* <!-- Corporate Service Offered --> */}
      <FancyCorporateServiceTest></FancyCorporateServiceTest>
      {/* <!-- Corporate Service Offered end --> */}



      {/* <!-- Innovspace Coworking's Corporate Services --> */}
      <div className="container mb-40 mt-150 md-mb-10 md-mt-10 " style={{ maxWidth: "1340px" }}>
        <div className="service-home">
          <h3 className="mainheading  pb-60 md-pb-30 md-pt-70">Innovspace Coworking's Business Services</h3>
        </div>
        <div className="row three-cards">
          <div className="col-lg-3 col-md-3 md-mt-10 corporate-card" style={{ padding: "20px 30px" }} >
            <div>
              <img src="images/assets/company.png" alt="feature" className="card-img"></img>
              <h3 className="mainheading-1 mb-15">Company Registration</h3>
              <ul className="list-corp">
                <li>2 Digital Signature - 2 Year Validity 1</li>
                <li>2 Director Identification Numbers</li>
                <li>Name Approval 2</li>
                <li>MOA Registration</li>
                <li>AOA Registration</li>
                <li>Share Certificate</li>
                <li>Authorised Capital Fee 3</li>
                <li>Incorporation Fee</li>
                <li>Stamp Duty 4</li>
                <li>PAN & TAN</li>
                <li>ESI Registration</li>
                <li>PF Registration</li>
                <li>Bank Account Opening</li>
                <li>Commencement of Business</li>
                <li>GST Registration</li>
              </ul>
              <div className="buttonalign1 mt-20">
                <AnchorLink href="#enquire">
                  <button className=" btn-primary">Enquire Now</button>
                </AnchorLink>

                {/* <button className=" btn-primary">Enquire Now</button> */}
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 md-mt-40 corporate-card" style={{ padding: "20px 30px" }} >
            <div>
              <img src="images/assets/registration.png" alt="feature" className="card-img"></img>
              <h3 className="mainheading-1 mb-15">LLP Registration</h3>
              <ul className="list-corp">
                <li>LLP Deed Drafting</li>
                <li>LLP Registration</li>
                <li>Current Account Opening</li>
                <li>GST Registration</li>
                <li>Pan card</li>
                <li>2 Digital Signatures</li>
              </ul>
              <div className="buttonalign2">
                <AnchorLink href="#enquire">
                  <button className=" btn-primary">Enquire Now</button>
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 md-mt-40 corporate-card" style={{ padding: "20px 30px" }}>
            <div>
              <img src="images/assets/booking.png" alt="feature" className="card-img"></img>
              <h3 className="mainheading-1 mb-15">Booking Keeping & Accounting</h3>
              <ul className="list-corp">
                <li>Old & Financial year booking Keeping  (ZOHO, SAP, TALLY, TALLY Prime )</li>
                <li>1 Year Dedicated Accountant</li>
                <li>1-Year GST Return Filing</li>
                <li>1-Year TDS Return Filing</li>
                <li>MCA DIN eKYC for Directors</li>
                <li>MGT-7 & AOC-4 Filing</li>
                <li>TAX AUDIT</li>
              </ul>
              <div className="buttonalign3">
                <AnchorLink href="#enquire">
                  <button className=" btn-primary">Enquire Now</button>
                </AnchorLink>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 md-mt-40 corporate-card" style={{ padding: "20px 30px" }}>
            <div>
              <img src="images/assets/legal.png" alt="feature" className="card-img"></img>
              <h3 className="mainheading-1 mb-15">Legal Services</h3>
              <div className="corporate-scroll">
                <ul className="list-corp">
                  <li>Contract Review</li>
                  <li>Intellectual Property Protection</li>
                  <li>Compliance Checks</li>
                  <li>Dispute Resolution</li>
                  <li>Corporate Governance</li>
                  <li>Employment Law Assistance</li>
                  <li>Mergers and Acquisitions</li>
                  <li>Regulatory Compliance</li>
                  <li>Risk Management</li>
                  <li>Privacy and Data Security</li>
                  <li>Licensing and Permits</li>
                  <li>Taxation Law Assistance</li>
                  <li>Cyber Law Compliance</li>
                  <li>Drafting and Negotiating Business Agreements</li>
                  <li>Insolvency and Bankruptcy Advice</li>
                  <li>Shareholder and Partnership Agreements</li>
                  <li>Franchise Agreements</li>
                  <li>Product Liability Consultation</li>
                  <li>Anti-trust and Trade Regulation Compliance</li>
                  <li>Employee Benefits and Compensation</li>
                  <li>International Trade Regulations</li>
                  <li>Securities Law Compliance</li>
                  <li>Environmental Law Compliance</li>
                  <li>E-commerce Legal Assistance</li>
                  <li>Debt Collection and Recovery</li>
                </ul></div>
              <div className="buttonalign4">
                <AnchorLink href="#enquire">
                  <button className=" btn-primary">Enquire Now</button>
                </AnchorLink>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Innovspace Coworking's Corporate Services end--> */}



      {/* <!-- document--> */}
      <div className="container mb-20 md-mb-10 md-mt-10">
        <div className="row">
          <div className="col-lg-6 md-mt-30" style={{ padding: "20px 40px" }}>
            <h2 className="mainheading" style={{ fontSize: "24px" }}> Documents required for Company Formation</h2>
            <ul className="list-corp" style={{ padding: "20px 20px" }}>
              <li>Company Names</li>
              <li>No of Directors KYC</li>
              <li>Pan card</li>
              <li>Aadhaar card</li>
              <li>Savings account bank statement 3 month</li>
              <li>Passport size photo</li>
              <li>Office address proof - electricity bill if on rent along with rental agreement</li>
            </ul>
          </div>
          <div className="col-lg-4" style={{ padding: "20px 40px" }}>
            <h2 className="mainheading" style={{ fontSize: "24px" }}>Documents required for LLP</h2>
            <ul className="list-corp" style={{ padding: "20px 20px" }}>
              <li>Pan card of Partners</li>
              <li>Aadhaar card of Partners</li>
              <li>Passport-size photo of Partners</li>
              <li>Bank statement/utility bill of Partners</li>
              <li>Office address proof - electricity bill if on rent along with the rental agreement</li>
            </ul>
          </div>
        </div>
      </div>
      {/* <!--document--> */}




      {/* <!--
        =====================================================
            Image slider start
        =====================================================
        --> */}
      <div className="fancy-portfolio-five lg-container pt-20 pb-80 md-pb-30 md-pt-0">
        <div className="slider-wrapper">
          <div className="portfolio_slider_one">
            <PortfolioFive />
          </div>
        </div>
      </div>
      {/* <!--
        =====================================================
            Image slider end
        =====================================================
        --> */}



      {/* <!-- benefits--> */}
      <div className="container mt-120 md-mt-10">
        <BenefitsCourporateService></BenefitsCourporateService>
      </div>
      {/* <!--  benefits end--> */}



      {/* // ---  Pricing Details Start ---*/}
      <div className="container">
      <CouponCorporate></CouponCorporate>
      </div>
      {/* // --- Pricing Details End ---*/}
      



      {/* <!--other services--> */}
      <div className="container pt-100 md-pt-100 ">
        <div>
          <h2 className="mainheading">Other Services</h2>
        </div>
        <div className=" lg-container pt-40  md-pt-10 md-mb-40" id="product">
          <div className=" position-relative">
            <div className="row">
              <div className="product_slider_one arrow-middle-center-otherservices otherservicespadding">
                <OtherServicesCorporateService />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--other services--> */}



      {/* <!--faq--> */}
      <div className="faq-classic mt-100 pt-60  pb-50 md-pt-40">
        <div className="container">
          <div className="row">
            <div className="col-lg-5">
              <div className="title-style-one">
                <h3 className="mainheadingfaq">Answers to your Business Services questions</h3>
                <h6 className=" mt-15">Our team is ready to answer your questions.</h6>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="">
                <FaqCorporateService></FaqCorporateService>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!--faq end--> */}



      {/* <!--footer--> */}
      <div className="footer-bg-wrapper">
        <footer className="theme-footer-one pt-70 md-pt-30">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
          </div>
          <div className="container">
            <div className="bottom-footer-content">
              <CopyRight />
            </div>
          </div>
        </footer>
        {/* <!--footer--> */}
      </div>
    </div>
  );
};

export default CorporateService;
