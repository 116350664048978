import React from "react";







const FancyTextBlockThree = () => {
  return (
    <div className="row no-gutters">
      <div className="col-lg-12">
        <div className="feature-content light-bg">
          {/* <div className="header1 mb-30" data-aos="fade-up" data-aos-duration="1200"> */}
          <div className="header1 mb-30">
            <h3 className="mainheading">Facilities & Services Included</h3>
          </div>
          {/* /.header */}
          <div className="row mt-15">
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="100"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/wifi.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">High-speed Internet ( Wi-Fi & LAN)</h5>
                <p className="tail">Experience uninterrupted connectivity with our blazing-fast, reliable internet service.</p>
              </div>
              {/* /.text-meta */}
            </div>
            {/* /.feature-list */}
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/flexible.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Flexible Desk</h5>
                <p className="tail">Enjoy the freedom of adaptability with our customizable desk configurations.</p>
              </div>
              {/* /.text-meta */}
            </div>

            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/12.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head"> 12 Hours Access</h5>
                <p className="tail">  Maximise productivity with access to your workspace for a generous 12 hours daily.</p>
              </div>
              {/* /.text-meta */}
            </div>
          </div>


          <div className="row mt-20 md-mt-10">
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="100"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/air.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Air conditioning</h5>
                <p className="tail">
                We cover India like no one else, Virtual Office across all major Indian Cities
                </p>
              </div>
              {/* /.text-meta */}
            </div>
            {/* /.feature-list */}
            <div className="feature-list d-flex col-lg-4 col-md-6"
            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-dela
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/printer.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Printing Options</h5>
                <p className="tail">
                Access convenience with our range of on-site printing options for your business needs.</p>
              </div>
              {/* /.text-meta */}
            </div>
            <div className="feature-list d-flex col-lg-4 col-md-6"

            // data-aos="fade-up"
            // data-aos-duration="1200"
            // data-aos-delay="200"
            >
              <div className="icon-box d-flex align-items-center justify-content-center">
                <img src="images/services/cupboard.png" alt="icon" />
              </div>
              <div className="text-meta">
                <h5 className="head">Storage Cupboards</h5>
                <p className="tail">
                Safely stow your belongings with our secure and ample storage consoles.
                </p>
              </div>
              {/* /.text-meta */}
            </div>
          </div>
          {/* /.feature-list  */}
        </div>
        {/* /.feature-content */}
      </div>
    </div>
  );
};

export default FancyTextBlockThree;