import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is a hot seat and how it does it work?",
    desc: `A hot seat is a flexible workspace in a coworking space where individuals can choose any available desk or workstation for 
    a specified period. It operates on a first-come, first-served basis, allowing you to start working upon arrival and leave when 
    finished, freeing the space for others.`,
    expand: "a",
  },
  {
    title: "What amenities are typically included with a hot seat?",
    desc: `Hot seat users have access to essential amenities like high-speed internet, power outlets, and office supplies.`,
    expand: "b",
  },
  {
    title: "Can I reserve a specific hot seat in advance?",
    desc: `Hot seat are typically first-come, first-served, but some times we allow reservations for recurring needs or larger teams.`,
    expand: "c",
  },
  {
    title: "What are the advantages of using a hot seat?",
    desc: `Using a hot seat provides flexibility and cost savings compared to having a dedicated desk.
     It allows you to work in a professional environment without the commitment of a long-term lease. Hot seat also fosters networking opportunities, as you can interact with different individuals and potentially collaborate with professionals from various backgrounds.`,
    expand: "d",
  },{
    title: "How secure is my equipment at a hot seat?",
    desc: `We prioritize security with surveillance, access control, and lockable storage. However, it's wise to keep an eye on your 
    belongings and take valuables when leaving the workspace.`,
    expand: "e",
  },{
    title: "Are there any time restrictions for using a hot seat?",
    desc: `We allow hot seat users to access the workspace during their operating hours. However, specific time restrictions may vary 
    depending on the space policies. Some spaces may offer 24/7 access to hot seat users, while others may have limitations on specific
     hours of availability.`,
    expand: "f",
  },

];

const FaqClassicTwo = () => {
  return (
    <div className="accordion-style-four ">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqClassicTwo;
