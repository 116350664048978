import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const TestimonialPortfolio = () => {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        },
      },
    ],
  };


  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const toggleVideo = () => {
    setIsVideoPlaying(!isVideoPlaying);
  };



  const portfolioContent = [
    {
      _id: 1,
      image: "home_2",
      editLink: "#",
      Title: "Our bright, elegantly designed open-plan coworking spaces can enhance the way you work. They provide the opportunity to be a part of a vibrant community in our shared office space",
      para: "When your needs change, we’re flexible When your needs change, we’re flexible",
      company: "ELHAA Technologies",
      name: "Maheswaran",
      viewLink: "#",
      src: "images/assets/elhaa.mp4",
    },
    // {
    //   _id: 2,
    //   image: "home_2",
    //   Title:"Our bright, elegantly designed open-plan coworking spaces can enhance the way you work. They provide the opportunity to be a part of a vibrant community in our shared office space",
    //   para:"When your needs change, we’re flexible When your needs change, we’re flexible",
    //   company:"ELHAA Technologies", 
    //   name: "Maheswaran",
    //   viewLink: "#",
    //   src:"images/assets/elhaa.mp4",
    // },
    // {
    //   _id: 3,
    //   image: "home_2",
    //   editLink: "#",
    //   Title:"Our bright, elegantly designed open-plan coworking spaces can enhance the way you work. They provide the opportunity to be a part of a vibrant community in our shared office space",
    //   para:"When your needs change, we’re flexible When your needs change, we’re flexible",
    //   company:"ELHAA Technologies", 
    //   name: "Maheswaran",
    //   viewLink: "#",
    //   src:"images/assets/elhaa.mp4",
    // },
    // {
    //   _id: 4,
    //   image: "home_2",
    //   editLink: "#",
    //   Title:"Our bright, elegantly designed open-plan coworking spaces can enhance the way you work. They provide the opportunity to be a part of a vibrant community in our shared office space",
    //   para:"When your needs change, we’re flexible When your needs change, we’re flexible",
    //   company:"ELHAA Technologies", 
    //   name: "Maheswaran",
    //   viewLink: "#",
    //   src:"images/assets/elhaa.mp4",
    // },
  ];







  return (
    <>
      <div className="container">
        {/* <div className="video-container"> */}
        <div className="tesvideo row">
          <div className="col-xl-6 col-lg-6 ">
            <h1 className="testimonialhead">
              Our bright, elegantly designed open-plan coworking spaces can enhance the way you work.
              They provide the opportunity to be a part of a vibrant community in our shared office space</h1>
            <h2 className="cardhead">ELHAA Technologies</h2>
            <h2 className="cardname ">Maheswaran</h2>
            {/* <h1 className="testimonialhead ">{item.Title}</h1> */}
            {/* <p className="testimonialpara mt-20">{item.para}</p> */}
            {/* <h2 className="cardhead">{item.company}</h2>
          <h2 className="cardname ">{item.name}</h2> */}

          </div>
          <div className="col-xl-6 col-lg-6 md-mt-20">
            <div className="video">
              {/* Video player */}
              {isVideoPlaying ? (
                <video
                  controls
                  autoPlay
                  muted
                  src="images/assets/elhaa.mp4"
                ></video>
              ) : (
                <div className="video-placeholder" onClick={toggleVideo}>
                  <img
                    src="images/assets/testimonial_cover.png"
                    alt="Video Thumbnail"
                  />
                  <div className="play-button">▶</div>
                </div>
              )}
            </div>
            </div>
        {/* </div> */}
        </div>
      </div>
    </>
  );
};

export default TestimonialPortfolio;
