import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function TestimonialTwo() {
  const settings = {
    dots: true,
    arrow: false,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [

    {

      img: "logo-8",

      desc: `I’m very happy that we found innovspace at the right time of our journey. I would definitely mention that Innovspace team is so responsive. A big kudos to the entire team for the sincerity and dedication. Thank you, so much for helping us out. We enjoy working here. I’m very confident that innovspace will make a huge difference in the forthcoming days 🙂`,

      reviewerName: "Vijay",

      designation: "Future Intelligence",

    },

    {

      img: "logo-9",

      desc: `I was emotionally stubborn in establishing my venture at Coimbatore not just because it’s my hometown but because of the unique magnitude that the city has for ideal entrepreneurs. Fortunate to have collaborated with Innovspace team who extend their assistance beyond facility management since their ultimate expertise is to augment venture success.`,

      reviewerName: "Tharsha K Pillai",

      designation: "Petrechor Content",

    },

    {

      img: "logo-10",

      desc: ` When other providers assured us to give complete workspace in weeks Innovspace gave that in hours, providing us one of the best services ever experienced. Only Professionals can do it in such a short span of time without compromising on the quality. Thanks to their team as I’m completely satisfied with the results.`,

      reviewerName: "Sujitha G",

      designation: "HastenTech",

    },

    {

      img: "logo-11",

      desc: `I was emotionally stubborn in establishing my venture at Coimbatore not just because it’s my hometown but because of the unique magnitude that the city has for ideal entrepreneurs. Fortunate to have collaborated with Innovspace team who extend their assistance beyond facility management since their ultimate expertise is to augment venture success.`,

      reviewerName: "Zibaiya Reian",

      designation: "Motion Graphy Expert",

    },
  ];

  return (
    <Slider {...settings}>
      {TestimonilContent.map((val, i) => (
        <div className="item" key={i}>
          <div className="bg-wrapper ">
            <img src={`images/logo/${val.img}.png`} alt="" className="logo" />
            <p className="mainpara">{val.desc}</p>
            <div className="name">{val.reviewerName}</div>
            <div className="desig mainpara">{val.designation}</div>
          </div>
          {/* /.bg-wrapper */}
        </div>
      ))}
    </Slider>
  );
}
