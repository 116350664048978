import React from "react";

const FeaturesHotSeat = () => {

  return (

    <div className="row ">

      <div className="col-xl-6 col-lg-7 order-lg-last" data-aos="fade-left">

        <div className="ps-lg-5 mt-120 md-mt-30">

          <div className="title-style-fourteen">
            <h3 className="mainheading">Why choose our Hot Seat?</h3>
          </div>

          <div className="tag-line">
            <ul className="list-item">

              <li style={{ fontSize: "16px" }}>Flexibility</li>

              <li style={{ fontSize: "16px" }}>Cost-effective</li>

              <li style={{ fontSize: "16px" }}>Networking and Collaboration</li>

              <li style={{ fontSize: "16px" }}>Amenities and Services</li>
            </ul>
          </div>

          <p className="mt-20" style={{fontSize:"16px"}}>Discover your perfect Hot Seat in our vibrant coworking space that suits your work style and aspirations. Experience flexibility, a supportive community, and an inspiring environment. Contact us today to learn more or schedule a facility tour.
          </p>

        </div>
      </div>
      <div className="col-xl-6 col-lg-5 text-center text-lg-left order-lg-first " data-aos="fade-right" >

        <div className="illustration-holder d-inline-block md-mt-30" >

          <img src="images/service/hot_features.png" alt="work space" className="w-100" />
        </div>
      </div>
    </div>
  );
};
export default FeaturesHotSeat;