import { useState } from "react";
import './Benefits.css'

const BenefitsPrivate = () => {
    const [toggle, setToggle] = useState("1");
    return (
        <>
            <div className='row '>
                <div className='col-xl-6 col-lg-6 block-style-thirtySix mobilepadding'>
                    <h2 className="mainheading mb-30 md-mt-20" >
                    Why a private/team office may be the perfect choice for you
                    </h2>                   
                    <div className="benefits-space">
                        <ul><li className="benefits-list">

                            <h2 onClick={() => setToggle("1")} className={`subheading-benefits title ${toggle === '1' ? 'active' : ''}`}>Enhanced Collaboration</h2>
                            {toggle === "1" &&
                                <p className="para-benefits mt-10">Working together in a dedicated team office fosters communication, synergy, and idea sharing,
                                 promoting a collaborative work culture.</p>
                            }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("2")} className={`subheading-benefits mt-20 title ${toggle === '2' ? 'active' : ''}`}>Scalability</h2>
                                {toggle === "2" &&
                                    <p className="para-benefits mt-10">Our team offices are designed to accommodate different team sizes, ensuring flexibility as your
                                     team expands or contracts. You can easily adjust the space to meet your changing needs.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("3")} className={`subheading-benefits mt-20 title ${toggle === '3' ? 'active' : ''}`}>Amenities and Services</h2>
                                {toggle === "3" &&
                                    <p className="para-benefits mt-10">Enjoy access to shared meeting rooms, communal spaces, and various amenities, including high-speed internet,
                                     printing facilities, and complimentary refreshments.</p>
                                }</li>

                            <li className="benefits-list">
                                <h2 onClick={() => setToggle("4")} className={`subheading-benefits mt-20 title ${toggle === '4' ? 'active' : ''}`}>Team Identity</h2>
                                {toggle === "4" &&
                                    <p className="para-benefits mt-10">A team office allows your group to establish a shared identity and work closely, promoting camaraderie and a sense of belonging.</p>
                                }</li>
                        </ul>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-6 '>
                    {toggle === "1" &&
                        <img src="images/assets/Acc_Image9.png" alt="shared working" className="benefits-img fadeinout" />
                    }
                    {toggle === "2" &&
                        <img src="images/assets/Acc_Image10.png"alt="work space co"className="benefits-img fadeinout" />
                    }
                    {toggle === "3" &&
                        <img src="images/assets/Acc_Image13.png" alt="flexible working space"className="benefits-img fadeinout" />
                    }
                    {toggle === "4" &&
                        <img src="images/assets/Acc_Image12.png" alt="free working space" className="benefits-img fadeinout" />
                    }
                </div>
            </div>

        </>
    )
}

export default BenefitsPrivate

