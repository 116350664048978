import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../../../components/header/Header";
import Footer from "../../../../components/footer/Footer";
import CopyRight from "../../../../components/footer/CopyRight";
import HeaderPopupForm from "../../../../components/form/HeaderPopupForm";
import ContactForm from "../../../../components/form/ContactForm";
import HeroBannerContactUs from "../../../../components/form-survey-landing/HeroBannerContactUs";
import BenefitsContactUs from "../../../../components/benefits/BenefitsContactUs";
const ContactUs = () => {
  return (
    <div className="main-page-wrapper p0 font-gordita">
      <Helmet>
        <title>Individual Office Space Avinashi Road | Shared Office Space near KMCH, India</title>
        <meta name="description"
          content="We provide affordable coworking space in peelamedu with best in class infrastructure and facilities for startups, freelancers, and entrepreneurs." />
        <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
        <link rel="canonical" href="https://web.archive.org/web/20230609201808/https://www.innovspace.com/contact/" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="article" />
        <meta property="og:title" content="Individual Office Space Avinashi Road | Shared Office Space near KMCH, India" />
        <meta property="og:description"
          content="We provide affordable coworking space in peelamedu with best in class infrastructure and facilities for startups, freelancers, and entrepreneurs." />
        <meta property="og:url" content="https://web.archive.org/web/20230609201808/https://www.innovspace.com/contact/" />
        <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:description"
          content="We provide affordable coworking space in peelamedu with best in class infrastructure and facilities for startups, freelancers, and entrepreneurs." />
        <meta name="twitter:title" content="Individual Office Space Avinashi Road | Shared Office Space near KMCH, India" />
      </Helmet>
      {/* End Page SEO Content */}

      <Header></Header>
      <HeroBannerContactUs></HeroBannerContactUs>

      <div className="container mt-120 md-mt-50 mb-100 md-mb-50">
        <BenefitsContactUs></BenefitsContactUs>
      </div>



      {/* <!--
        =====================================================
            Footer Style Nine
        =====================================================
        --> */}
      <div className="footer-bg-wrapper">
        <footer className="theme-footer-one pt-70 md-pt-10 ">
          <div className="top-footer">
            <div className="container">
              <Footer />
            </div>
            {/* /.container */}
          </div>
          {/* /.top-footer */}

          <div className="container ">
            <div className="bottom-footer-content ">
              <CopyRight />
            </div>
            {/*  /.bottom-footer */}
          </div>
        </footer>
        {/* /.theme-footer-one */}
      </div>
    </div>
  );
};

export default ContactUs;
