import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is a day pass in a coworking space and how does it work?",
    desc: `A day pass grants individuals access to coworking facilities and amenities for one day without long-term commitments. It offers a flexible workspace solution for temporary needs, allowing use of workstations and common areas during that day.`,
    expand: "a",
  },
  {
    title: "What amenities are included with a day pass?",
    desc: `Day pass users typically have access to amenities provided by the coworking space, including high-speed internet, power outlets, communal areas, and basic office supplies.`,
    expand: "b",
  },
  {
    title: "How can I purchase a day pass?",
    desc: `To purchase a day pass, you usually need to inquire with our admin team about the day pass options. They will guide you through the process, including pricing, availability, and any additional details required to gain access to the space for the day.`,
    expand: "c",
  },
  {
    title: "Can I use a day pass on weekends or public holidays?",
    desc: `Day passes are available only on weekdays.`,
    expand: "d",
  },
  {
    title: "Are day passes limited to specific hours of the day?",
    desc: `Day passes provide access to the coworking space during our operating hours (9AM to 6PM) for that particular day.`,
    expand: "e",
  },
  {
    title: "Can I bring guests with me using a day pass?",
    desc: `Day passes generally allow access for one person and may not cover additional guests. However, if you want to bring guest, separate day pass to be taken for them. Please consult our team for their specific guidelines regarding guests.`,
    expand: "f",
  },

];

const FaqDayPass = () => {
  return (
    <div className="accordion-style-four ">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqDayPass;
