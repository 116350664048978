import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import CopyRight from "../../components/footer/CopyRight";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/Header";

const Clients = () => {
    // For header select menu
    const [click1, setClick1] = useState(false);
    const handleClick1 = () => setClick1(!click1);
    return (
        <div className="main-page-wrapper font-gordita">
            <Helmet>
                {/* <title>
                    Coworking Space and Virtual Office India | Office Space for Rent SITRA

                </title> */}
                <title>Clients - Innovspace - Business Accelerator | Coworking Space</title>
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href="https://web.archive.org/web/20230609221144/https://www.innovspace.com/events/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="object" />
                <meta property="og:title" content="Events Archive - Innovspace - Business Accelerator | Coworking Space" />
                <meta property="og:url" content="https://web.archive.org/web/20230609221144/https://www.innovspace.com/events/" />
                <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:title" content="Events Archive - Innovspace - Business Accelerator | Coworking Space" />
            </Helmet>
            <Header />
            <div className="fancy-feature-sixteen md-pt-50 md-pb-10" id="features">
                {/* <div className="fancy-feature-sixteen md-pt-50 md-pb-10" id="features" style={{ backgroundColor: "antiquewhite" }}> */}
                <div className="container">
                    <div className="block-style-eighteen">
                        <div className="row align-items-center">
                            <div
                                className="col-lg-5"
                                data-aos="fade-right"
                                data-aos-duration="1200">
                                <div className="text-wrapper">
                                    <h6>INNOVSPACE</h6>
                                    <h1 className=" mt-20 clientheading" >
                                        Welcome to Innovspace! We are proud to have a diverse community of talented individuals and thriving businesses that call our coworking space home. Here are just a few of our valued clients
                                    </h1>
                                    <Link to="/contact-us" className="d-flex align-items-center learn-more mt-30" >
                                        <a className="theme-btn-fourteen2">Talk to an expert</a>
                                    </Link>
                                </div>
                            </div>
                            <div className="col-lg-7" data-aos="fade-left" data-aos-duration="1200" >
                                <div className="screen-holder-one d-flex align-items-center justify-content-center">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>


            <div className="hero-banner-seven lg-container">
                <div className="partner-slider-two mt-90 md-mt-80 mb-30 md-mb-50">
                    <div className="container">
                        <p className="text-center">
                            Trusted by some of the biggest companies.
                        </p>

                        {/* <div className="row" style={{margin:"0 45px"}}>
                        <div className="clients-logo">
                            <div className="clientee">
                                <img src="images/logo/BigIdea.png" alt="icon"  style={{width:"80px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/softworth.png" alt="icon"  style={{width:"150px"}} />
                            </div>
                            <div className="clientee">
                                <img src="images/logo/pupilsblockchain.png" alt="icon"  style={{width:"120px"}} />
                            </div>
                            <div className="clientee">
                                <img src="images/logo/halley.png" alt="icon" style={{width:"150px"}} />
                            </div>
                            <div className="clientee">
                                <img src="images/logo/fynanziare1.png" alt="icon" style={{width:"130px"}} />
                            </div>
                        </div>
                        </div> */}

                        {/* <div className="row" style={{margin:"0 45px"}}>
                        <div className="clients-logo mt-80">
                            <div className="clientee">
                                <img src="images/logo/Your Farm1.png" alt="icon" style={{width:"130px"}} />
                            </div>
                            <div className="clientee">
                                <img src="images/logo/hk.png" alt="icon" style={{width:"90px"}}  />
                            </div>
                            <div className="clientee" style={{width:"133px"}}>
                                <img src="images/logo/infoplus1.png" alt="icon" />
                            </div>
                            <div className="clientee" style={{width:"133px"}}>
                                <img src="images/logo/Elitez India.png" alt="icon" />
                            </div>
                            <div className="clientee" style={{width:"55px"}}>
                                <img src="images/logo/praathee.png" alt="icon" />
                            </div>
                        </div>
                        </div> */}

                        {/* <div className="row" style={{margin:"0 45px"}}>
                        <div className="clients-logo mt-80">
                            <div className="clientee">
                                <img src="images/logo/Oorla.png" alt="icon"  style={{width:"150px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/tasaheel.png" alt="icon" style={{width:"150px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/Lonar.png" alt="icon"  style={{width:"150px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/Rebillion.png" alt="icon" style={{width:"150px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/Greeno-Logo.png" alt="icon"  style={{width:"150px"}}/>
                            </div>
                        </div>
                        </div> */}

                        {/* <div className="row" style={{margin:"0 45px"}}>
                        <div className="clients-logo mt-80">
                            <div className="clientee">
                                <img src="images/logo/320x128-Edudefine-logo-white-bg.png" alt="icon" />
                            </div>
                            <div className="clientee">
                                <img src="images/logo/320x128-iGlitz-logo-white-bg.png" alt="icon" style={{width:"195px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/320x128-monc-logo-white-bg.png" alt="icon"  style={{width:"195px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/GV-white-bg.png" alt="icon" style={{width:"195px"}}/>
                            </div>
                            <div className="clientee">
                                <img src="images/logo/jb150.png" alt="icon"  style={{width:"80px"}}/>
                            </div>
                        </div>
                        </div> */}

                    </div>
                </div>
            </div>


            <div className="container">
                <div className="row">
                    <div class="client-logos">
                        {/* <!-- Repeat this block for each client logo --> */}
                        <div class="client-logo">
                            <img src="images/logo/BigIdea.png" alt="Client 1" style={{ maxWidth: "80px", height: "72px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/hk.png" alt="Client 2" style={{ maxWidth: "80px", height: "70px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/praathee.png" alt="Client 3" style={{ maxWidth: "80px", height: "80px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/jb150.png" alt="Client 4" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/GV-white-bg.png" alt="Client 5" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Greeno-Logo.png" alt="Client 6" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Elitez India.png" alt="Client 7" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/halley.png" alt="Client 8" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/fynanziare1.png" alt="Client 9" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/pupilsblockchain.png" alt="Client 10" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/infoplus1.png" alt="Client 11" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Lonar.png" alt="Client 12" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/softworth.png" alt="Client 13" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Your Farm1.png" alt="Client 14" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/tasaheel.png" alt="Client 15" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/320x128-iGlitz-logo-white-bg.png" alt="Client 16" />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/320x128-Edudefine-logo-white-bg.png" alt="Client 17" style={{ maxWidth: "100%", height: "100%" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Rebillion.png" alt="Client 18" style={{ maxWidth: "100%", height: "100%" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Oorla.png" alt="Client 19" style={{ maxWidth: "100%", height: "100%" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/320x128-monc-logo-white-bg.png" alt="Client 20" style={{ maxWidth: "100%", height: "100%" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/swasam.png" alt="Client 21" style={{ maxWidth: "130px", height: "80px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/ts100.png" alt="Client 22" style={{ maxWidth: "80px", height: "80px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/beamer1.png" alt="Client 23" style={{ maxWidth: "130px", height: "50px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/Prodigi.png" alt="Client 24" style={{ maxWidth: "130px", height: "45px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/sense-logo.png" alt="Client 25" style={{ maxWidth: "70px", height: "75px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/dclicks.png" alt="Client 26" style={{ maxWidth: "130px", height: "45px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/olam.png" alt="Client 27" style={{ maxWidth: "130px", height: "45px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/stanventures.png" alt="Client 28" style={{ maxWidth: "130px", height: "45px" }} />
                        </div>
                        <div class="client-logo">
                            <img src="images/logo/tessolve.png" alt="Client 29" style={{ maxWidth: "130px", height: "45px" }} />
                        </div>
                        <div class="client-logo">
                            {/* <img src="images/logo/Prodigi.png" alt="icon" alt="Client 1" style={{maxWidth:"130px", height:"45px"}}/> */}
                        </div>
                        {/* <!-- Repeat for other clients --> */}
                    </div>
                </div>
            </div>



            <div className="client-box mt-10 mb-100 md-mt-50 md-mb-50">
                <div className="row">
                    <div className="col-xl-2 col-md-2"></div>
                    <div className="col-xl-8 col-md-8">
                        <p className="mainpara mt-30 textaligncenter">These are just a glimpse of the incredible individuals and businesses that collaborate and thrive within our coworking space. Join our community today and unlock endless opportunities for networking, growth, and success. Contact us to learn more about becoming a part of our prestigious clientele at Innovspace!</p>
                    </div>
                    <div className="col-xl-2 col-md-2"></div>
                </div>
            </div>






            <footer className="theme-footer-one pt-130 md-pt-70">
                <div className="top-footer">
                    <div className="container">
                        <Footer />
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRight />
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-seven */}
        </div>
    );
};

export default Clients;
