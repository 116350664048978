import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Slide } from "@material-ui/core";
import { date } from "yup";

const BlogContent = [
  {
    img: "blog342.jpg",
    date: "Nov 11, 2024",
    title: `Maximizing Efficiency in a Shared Workspace `,
    desc: `Shared workspaces, or coworking spaces, have become increasingly popular as businesses and freelancers seek flexible, cost-effective environments to work in.However, being efficient in a shared workspace...`,
    routePath: "/maximizing-efficiency-in-a-shared-workspace",
  },
     {
    img: "blog33.jpg",
    date: "Nov 04, 2024",
    title: `Rethinking the Workplace: The Rise of Shared Workspaces`,
    desc: `In recent years, the concept of shared workspaces has gained significant traction, and for good reason. As businesses increasingly prioritize flexibility, cost-efficiency, and collaboration, the traditional office.`,
    routePath: "/rethinking-the-workplace-the-rise-of-shared-workspaces",
  },
    {
    img: "blog32.jpg",
    date: "Oct 28, 2024",
    title: `Balancing Work and Life in a Co-working Space `,
    desc: `In today’s fast-paced world, finding the right balance between work and life can be a challenge, especially for freelancers, remote workers, and entrepreneurs. Co-working spaces have emerged as a popular solution.`,
    routePath: "/balancing-work-and-life-in-a-coworking-space",
  },
   

];

const BlogStyle3 = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {BlogContent.map((item, i) => (
        <div className="post-meta" key={i}>
          <div className="imgsetup">
            <img
              src={`images/assets/${item.img}`}
              alt="media"
              className="image-meta1"
            />
          </div>
          <div className="post">
            <div className="date">{item.date}</div>
            <h3>
              <Link to={item.routePath} className="title">
                {item.title}
              </Link>
            </h3>
            <p>{item.desc}</p>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Learn More</span>
              {/* <i className="flaticon-right-arrow"></i> */}
            </Link>
          </div>
          {/* End post */}
        </div>
        // /.post-meta
      ))}
    </>
  );
};

export default BlogStyle3;
