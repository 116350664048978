import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import { useMediaQuery } from 'react-responsive';
import './Career.css';
import Modal from "react-modal";
import { useState } from "react";
import CandidateForm from "../../components/form/CandidateForm";

const CareerList = () => {
    
  const [isOpen, setIsOpen] = useState(false);
  function toggleModalOne() {
    setIsOpen(!isOpen);
  }


  const issmallScreen = useMediaQuery({ query: '(max-width: 360px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1240px)' })
  return (
    <div className="main-page-wrapper">
      <Helmet>
        <title>
        Career - Innovspace - Business Accelerator | Fully Furnished Coworking Office
        </title>
      </Helmet>
      {/* End Page SEO Content */}
      <Header />
      {/* End Header */}



      <div className="terms-conditions " style={{ backgroundColor: "#CCE8F6", padding: "20px" }}>
        <h3 className="subheading1 mt-10">Innovspace | Full time</h3>
        <h1 className="mainheading mt-10">Senior Accountant</h1>
        <h3 className="subheading1 mt-10" style={{ textAlign: "center" }}>Coimbatore, India | Posted on 16 March 2023</h3>
        <div className="buttoncareerlist mt-10">
          <div className="btn-home">
            <a href="#buttonview" className="theme-btn-fourteen1">Im Interested</a>
          </div>
          {/* <div className="btn-home ">
            <a href="#" className="theme-btn-fourteen2">Refer a Friend</a>
          </div> */}
        </div>
        <div>
          <ul
            className=" d-flex
            social-icon mt-20"
            style={{ gridGap: "20px" }}

          >
            <li>
              {/* <a href="#" target="_blank" rel="noreferrer"> */}
             <a> 
                <i className="fa fa-share-alt fa-facebook-col" ></i>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/" target="_blank" rel="noreferrer">
                <i className="fa fa-facebook fa-facebook-col" ></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/" target="_blank" rel="noreferrer">
                <i className="fa fa-twitter fa-twitter-col"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/" target="_blank" rel="noreferrer">
                <i className="fa fa-linkedin fa-linked-col"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/" target="_blank" rel="noreferrer">
                <i className="fa fa-instagram fa-instagram-col"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="container-custom container">
        <div className="row">
          <div className="col-xl-9 col-lg-9 paddingcareerlist">
            <h2 className="mainheading mt-100 md-mt-50">Organization:</h2>
            <p className="mainpara mt-20 contentjustify">At Sense7ai, we rely on our dynamic team of engineers to solve the many challenges and puzzles that come with our rapidly evolving technical stack. We’re seeking a full-stack engineer who is ready to work with new technologies and architectures in a forward-thinking organization that’s always pushing boundaries. Here, you will take complete, end-to-end ownership of projects across the entire stack. Our ideal candidate has experience building products across the stack and a firm understanding of web frameworks, APIs, databases, and various back-end languages. You’ll be joining a small team working at the forefront of new technology, solving the challenges that impact both the front-end and back-end architecture, and ultimately, delivering amazing global user experiences.</p>
            <h2 className="mainheading mt-30">Location:</h2>
            <p className="mainpara mt-20">Coimbatore, Apply only if you can join in 2-4 weeks of time</p>
            <h2 className="mainheading mt-30">Work Experience:</h2>
            <p className="mainpara mt-20">3-4 Years</p>
            <h2 className="mainheading mt-30 mb-20">Objectives of this Role</h2>
            <ul className="liststyle">
              <li>Work across the full stack, building highly scalable distributed solutions that enable positive user experiences and measurable business growth</li>
              <li>evelop new features and infrastructure development in support of rapidly emerging business and project requirements</li>
              <li>Assume leadership of new projects from conceptualization to deployment</li>
              <li>Ensure application performance, uptime, and scale, maintaining high standards of code quality and thoughtful application design</li>
              <li>Work with agile development methodologies, adhering to best practices and pursuing continued learning opportunities</li>
              <li>Work in public cloud-based architecture primarily in AWS.</li>
            </ul>
            <h2 className="mainheading mt-30 mb-20">Responsibilities
            </h2>
            <ul className="liststyle">
              <li>As a Full-Stack Engineer, you will be a crucial part of delivering new and innovative technology solutions.</li>
              <li>Participate in all aspects of agile software development including design, implementation, and deployment.</li>
              <li>Architect & develop production-quality code and provide guidance on building end-to-end systems optimized for speed and scale</li>
              <li>Work in React, Typescript, JavaScript, Python, Django, and MySQL. Knowledgeable to work in NoSQL databases.</li>
              <li>Engage and collaborate with our designer, front-end engineer, back-end and AWS cloud engineer, and QA as we create reliable APIs</li>
              <li>Collaborate across time zones via Microsoft Teams</li>
            </ul>
            <h2 className="mainheading mt-30 mb-20">Technical Requirements
            </h2>
            <ul className="liststyle">
              <li>3 – 5 years of experience building large-scale software applications</li>
              <li>The ability to write performant and optimized code for external-facing software products (SaaS) and other web applications.</li>
              <li>Developed and deployed production quality code in tech stacks – React + Redux, JavaScript/Typescript, Node.js, Python, Django, MySQL/NoSQL</li>
              <li>Prior experience partnering and collaborating with other engineers, product and design</li>
              <li>Prior experience working at a high growth startup</li>
              <li>Experience designing and integrating RESTful APIs,</li>
              <li>Thorough knowledge and experience implementing application deployment in AWS cloud or other public clouds.</li>
              <li>Excellent debugging, optimization, and troubleshooting skills</li>
              <li>Unit/integration testing experience.</li>
              <li>Self Interest in learning new tools/libraries, languages, workflows, and philosophies to solve problems or introduce new features.</li>
            </ul>

            <h2 className="mainheading mt-30 mb-20">Skills

            </h2>
            <ul className="liststyle">
              <li>React, React.js, Python, Django, Typescript, Javascript, Nosql, Mysql, Aws, Redux</li>
              <li>React, React.js, Python, Django, Typescript, Javascript, Nosql, Mysql, Aws, Redux</li>
              <li>React, React.js, Python, Django, Typescript, Javascript, Nosql, Mysql, Aws, Redux</li>
              <li>React, React.js, Python, Django, Typescript, Javascript, Nosql, Mysql, Aws, Redux</li>

            </ul>

            <h2 className="mainheading mt-30 mb-20">Employee Benefits
            </h2>
            <ul className="liststyle ">
              <li>The employee will have a flexible work policy</li>
              <li>Flexible vacation policy</li>
              <li>Fully paid health insurance for employees, spouses, children and parents, etc</li>
              <li>Company equipment like laptops and other necessary stuff for work</li>

            </ul>
            <button id="buttonview"className=" btn-primary mt-50" onClick={toggleModalOne}>I'm Interested</button>
          </div>
          <div className="col-lg-3 col-xl-3">
            <div className="line">
              <div className="marginleftcareer">
                <h2 className="mainheading mt-100 md-mt-30">Job Information</h2>
                <div className="mt-20">
                  <p className="mainpara">Work Experience</p>
                  <p className="mainparacareerlist">1-3 years</p>
                </div>
                <div className="mt-20">
                  <p className="mainpara">Industry</p>
                  <p className="mainparacareerlist">Payment Processing</p>
                </div>
                <div className="mt-20">
                  <p className="mainpara">City</p>
                  <p className="mainparacareerlist">Montreal</p>
                </div>
                <div className="mt-20">
                  <p className="mainpara">State/Province</p>
                  <p className="mainparacareerlist">Quebec</p>
                </div>
                <div className="mt-20">
                  <p className="mainpara">Country</p>
                  <p className="mainparacareerlist">Canada</p>
                </div>
                <div className="mt-20">
                  <p className="mainpara">zip/Postal Code</p>
                  <p className="mainparacareerlist">H4B2M5</p>
                </div>
              </div></div>
          </div>
         
          
        </div>
      </div>





      <footer className="theme-footer-one pt-70 md-pt-30 mt-100 md-mt-50">
        <div className="top-footer">
          <div className="container">
            <Footer />
          </div>
          {/* /.container */}
        </div>
        {/* /.top-footer */}

        <div className="container">
          <div className="bottom-footer-content">
            <CopyRight />
          </div>
          {/*  /.bottom-footer */}
        </div>
      </footer>
      <Modal
          isOpen={isOpen}
          onRequestClose={toggleModalOne}
          contentLabel="My dialog"
          className="custom-modal  modal-contact-popup-one"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >

          <div className="box_inner ">
            <main className="main-body box_inner modal-content clearfix md-mb-30">
              <button className="close" onClick={toggleModalOne}>
                <img src="images/icon/close.svg" alt="close" />
              </button>
              {/* End close icon */}

              {/* /.left-side */}


              <div className="right-side">
                <div className="size">
                  <h3 className="mainheading mb-20">Apply </h3>
                  <CandidateForm></CandidateForm></div>
              </div>
              {/*  /.right-side */}
            </main>
            {/* /.main-body */}
          </div>
        </Modal>
      {/* /.theme-footer-one */}
    </div>
  );
};

export default CareerList;
