import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";

const BlogContent = [
  {
    img: "Hot seat",
    tag: "Hot Seat",
    title: `Embrace the flexibility of our Hot Seat option in our bustling coworking space. Enjoy a dynamic workspace that adapts to your needs, comfortable work, fostering collaboration and productivity.`,
    routePath: "/hot-seat",
    dealyAnimation: "",
  },
  {
    img: "Dedicated desk",
    tag: "Dedicated Desks",
    title: `Experience productivity and personalized workspace with our Dedicated Desks in our vibrant coworking community. Reserve your spot today for a focused and comfortable work environment`,
    routePath: "/dedicated-desk",
    dealyAnimation: "50",
  },
  {
    img: "Private Team office",
    tag: "Private / Team Office",
    title: `Elevate your team's productivity with our private office spaces in our dynamic coworking environment. Experience privacy and collaboration in a dedicated workspace tailored to your needs.`,
    routePath: "/private-office",
    dealyAnimation: "100",
  },
  {
    img: "Sample",
    tag: "Day Pass",
    title: `Get a taste of our thriving coworking community with our Day Pass option. Enjoy a productive day of flexible work in our vibrant workspace, complete with all the amenities you need.`,
    routePath: "/day-pass",
    dealyAnimation: "50",
  },
  {
    img: "Meeting Room",
    tag: "Meeting Room",
    title: `Book our well-equipped meeting rooms today to impress clients and colleagues in a professional setting. Host successful meetings and presentations in our coworking space.`,
    routePath: "/meeting-room",
    dealyAnimation: "100",
  },
  {
    img: "virtual_office",
    tag: "Virtual Office",
    title: `Elevate your business with our Virtual Office solution, offering a prestigious address and essential remote services. Build your seamless virtual presence today for flexibility and a professional image.`,
    routePath: "/virtual",
    dealyAnimation: "150",
  },
  {
    img: "corporate office",
    tag: "Business Services",
    title: `Simplify business setup with our comprehensive services including company & LLP registration, available in our collaborative coworking space. Streamline processes & maximize efficiency today.`,
    routePath: "/bussiness",
    dealyAnimation: "50",
  },
];

const LatestProduct = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const vrProducts = [
    {
      _id: 1,
      image: "img_115",
      title: "Oculus Quest",
      productLink: "#",
      price: "72.00",
      rating: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star-o"],
    },
    {
      _id: 2,
      image: "img_116",
      title: "VR Controller",
      productLink: "#",
      price: "27.00",
      rating: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star"],
    },
    {
      _id: 3,
      image: "img_117",
      title: "Play Station 2",
      productLink: "#",
      price: "382.00",
      rating: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star"],
    },
    {
      _id: 4,
      image: "img_116",
      title: "VR Controller",
      productLink: "#",
      price: "27.00",
      rating: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star"],
    },
    {
      _id: 5,
      image: "img_116",
      title: "VR Controller",
      productLink: "#",
      price: "27.00",
      rating: ["fa-star", "fa-star", "fa-star", "fa-star", "fa-star"],
    },
  ];

  return (
    <>
      <Slider {...settings}>
        {BlogContent.map((item, i) => (
          <div className="col-lg-4 col-md-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            key={i}
            data-aos-delay={item.dealyAnimation}>

            <div className="card cardslider">
              <img src={`images/assets/${item.img}.jpg`} alt="media" className="img-holder" />
              <div class="p2">
                <h4 class="cardhead">
                  {item.tag}</h4>
                <p className="title-font"> {item.title} </p>
                <h6 class="mt-1">
                  <Link to={item.routePath} class="btn-card">
                    <span>Read More</span>{" "}
                    <i className="flaticon-right-arrow"></i>
                  </Link></h6>
              </div>
              {/* /.post-meta */}
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default LatestProduct;
