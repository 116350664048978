import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

const FaqContent = [
  {
    title: "What is a private office in a coworking space?",
    desc: `A private office in a coworking space is an enclosed workspace that provides privacy and exclusivity for individuals or teams.
     It offers a dedicated area with walls and a door, separate from the shared coworking areas.`,
    expand: "a",
  },
  {
    title: "What is a team office in a coworking space?",
    desc: `A team office in a coworking space is a private workspace designed to accommodate larger groups or teams. 
    It offers a dedicated space with multiple desks or workstations for team members to collaborate and work together. `,
    expand: "b",
  },
  {
    title: "What amenities are typically included with a private office or team office?",
    desc: `Private offices and team offices in coworking spaces usually include amenities such as furniture, high-speed internet, 
    power outlets, storage options, access to communal areas, and basic office supplies. Additional amenities like meeting rooms, 
    printing services, kitchenettes, and lounge areas are also available.`,
    expand: "c",
  },
  {
    title: "Can I schedule client meetings or events in a private office or team office?",
    desc: `Yes, private offices and team offices are suitable for client meetings and events. 
    They provide a professional and private environment where you can host discussions, presentations, 
    or collaborations with clients or partners. We also offer dedicated meeting rooms or event spaces for larger gatherings.`,
    expand: "d",
  },
  {
    title: "Can I add or remove team members from a team office?",
    desc: `In most cases, you can add or remove team members from a team office based on your changing requirements.
     We do allow flexibility in adjusting the number of occupants in a team office. However, it's important to discuss 
     any changes with our management to ensure proper coordination and billing adjustments.`,
    expand: "e",
  },
  {
    title: "Can I access my private office or team office 24/7?",
    desc: `Yes, we do provide 24/7 access to provate offices or team offices. If you work on weekends you should 
    give prior information to admin team so that we can make necessary arrangements from our end.`,
    expand: "f",
  },


];

const FaqPrivateOffice = () => {
  return (
    <div className="accordion-style-four ">
      <div className="faq-wrraper">
        <Accordion preExpanded={["a"]} allowZeroExpanded>
          {FaqContent.map((item, i) => (
            <AccordionItem className="card" key={i} uuid={item.expand}>
              <AccordionItemHeading className="card-header">
                <AccordionItemButton>
                  <h5 className="mb-0">
                    <button className="btn btn-link">{item.title}</button>{" "}
                  </h5>
                </AccordionItemButton>
              </AccordionItemHeading>
              {/* Accordion Heading */}
              <AccordionItemPanel className="card-body fadeInUp">
                <p>{item.desc}</p>
              </AccordionItemPanel>
              {/* Accordion Body Content */}
            </AccordionItem>
          ))}
        </Accordion>
      </div>
    </div>
  );
};

export default FaqPrivateOffice;
