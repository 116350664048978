import React from "react";
import { Link } from "react-router-dom";

const HeroBannerHotSeat = () => {
  return (
    // <!--
    // =============================================
    //     Theme Hero Banner
    // ==============================================
    // -->
    <div className="hero-banner-thirteen1 lg-container" id="home" pb-120 md-pb-10>
      <div className="container">
        <div className="row mt-30 md-mt-60" >
          <div className="col-lg-6" >
            <div className="hotbanner">
            <h1 className="heading">Hot Seat</h1>
            <div className="mb-25">
              <p className="bn-icon mt-20 md-mt-30" style={{fontSize:"16px"}}>
              Are you a futuristic professional in search of a workspace tailored to your individual needs? Our coworking space provides the perfect blend of flexibility, collaboration, and a dynamic community atmosphere. Discover our Hot seat option, curated specifically with your unique requirements in mind.
              </p>
            </div>
            <div className="mb-30 md-mt-40">
            
              <a href="/contact-us" className="button-banner">
              Talk to an expert
              </a>
            </div>
            </div>
          </div>
        </div>

        {/* <!-- /.screen-holder --> */}
      </div>
    </div>
    // <!-- /.hero-banner-thirteen -->
  );
};

export default HeroBannerHotSeat;
