import React from "react";
const Features = () => {

  return (

    <div className="row md-mt-50">

      <div className="col-xl-6 col-lg-7 order-lg-last" data-aos="fade-left">

        <div className="ps-lg-5 mt-70 md-mt-30">

          <div className="title-style-fourteen">
            <h3 className="mainheading">Speak the language of Success</h3>
          </div>

          <div className="tag-line">
          </div>

          <p className="mainpara">At Innovspace, we understand that every professional has unique requirements, which is why our dedicated desk options are tailored to cater to your specific needs. 
            Join our vibrant community and experience the benefits of a dedicated desk in a collaborative and inspiring environment.</p>
          <p className="mainpara pt-20">Contact us today to learn more about our dedicated desk options and book your space in our thriving coworking community.</p>
        </div>
      </div>
      {/* Emd .col */}
      <div className="col-xl-6 col-lg-5 text-center text-lg-left order-lg-first " data-aos="fade-right" >

        <div className="illustration-holder d-inline-block md-mt-30" >

          <img src="images/dedicated/features_dedicated.jpg" alt="coworking centre" className="w-100" />
        </div>
      </div>
      {/* End .col */}
    </div>
  );
};
export default Features;