import React from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const PortfolioContent = [
  {
    img: "corporate_4",
    meta: "Co-working space, corporate service",
    title: "Innovspace", 
    alt:"space office space",
  },
  {
    img: "corporate_2",
    meta: "corporate service",
    title: "Innovspace",
    alt:"coworking office spaces",
  },
  {
    img: "corporate_1",
    meta: "corporate service",
    title: "Innovspace",
    alt:"affordable coworking space",
  },
  {
    img: "corporate_3",
    meta: "corporate service",
    title: "Innovspace",
    alt:"spaces coworking",
  },
];

const Portfolio = () => {
  const settings = {
    dots: true,
    arrow: true,
    infinite: true,
    speed: 900,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    centerMode: true,
    centerPadding: "0",
  };

  return (
    <>
      <Slider {...settings}>
        {PortfolioContent.map((val, i) => (
          <div className="item" key={i}>
            <div className="portfolio-block-three">
              <Link to="#" className="img-holder">
                <img
                  src={`images/service/${val.img}.jpg`}
                  alt={val.alt}
                  className="w-100 tran5s"
                />
              </Link>
              {/* <div className="text">
                <div className="tag">{val.meta}</div>
                <h4>
                  <Link to="/portfolio-details-v1">{val.title}</Link>
                </h4>
              </div> */}
            </div>
            {/* /.portfolio-block-three  */}
          </div>
          //  End .item
        ))}
      </Slider>
    </>
  );
};

export default Portfolio;
