import React from "react";


const HeroBannerContactUs = () => {
  return (

    <div className="banner lg-container" id="home" pb-120 md-pb-10>
      <div className="container">
        <div className="row mt-30 md-mt-60" >
          <div className="col-lg-12" >

            <h1 className="heading" style={{textAlign:"center",color:"#ffffff"}}>Contact Us</h1>
       
            <div className="mb-30 md-mt-40">
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default HeroBannerContactUs;
