import React, { useState, useEffect } from 'react';
import "./CarouselPrivate.scss"


const CarouselPrivate = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      id: 1,
      image: "private_1",
      alt:"co work",
    },
    {
      id: 2,
      image: "private_2",
      alt:"shared work space",
    },
    {
      id: 3,
      image: "private_3",
      alt:"spaces co working",
    },
    {
      id: 4,
      image: "private_4",
      alt:"space work",
    },
    {
      id: 5,
      image: "private_5",
      alt:"room work",
    },
    {
      id: 6,
      image: "private_6",
      alt:"work the space",
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const renderSlides = () => {
    return slides.map((slide, index) => (
      <div className={`slide ${index === currentIndex ? 'active' : ''}`} key={slide.id}>
        <img src={`images/private/${slide.image}.jpg`} alt={`Slide ${slide.id} ${slide.alt}`} />
      </div>
    ));
  };

  const renderThumbnails = () => {
    return slides.map((slide, index) => (
      <div
        className={`thumbnail ${index === currentIndex ? 'active' : ''}`}
        key={slide.id}
        onClick={() => handleThumbnailClick(index)}
      >
        <img src={`images/private/${slide.image}.jpg`} alt={`Thumbnail ${slide.id}`} />
      </div>
    ));
  };

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
  };

  const previousSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + slides.length) % slides.length);
  };

  return (
    <div className="carousel-container" >
      <div className="slider1" >
        {renderSlides()}
      </div>
      
      <div className='arrows'>
        <div className="arrows-left">
          <div className="arrowprivate left-arrow" onClick={previousSlide}></div>
        </div>
   
        <div className="arrows-right">
          <div className="arrowprivate right-arrow" onClick={nextSlide}></div>
        </div>
      </div>
    </div>
  );
};

export default CarouselPrivate;
