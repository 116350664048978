import React from "react";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";

const All = [
  { img: "profileimg1", title: "Maurice Craig", designation: "Co-Founder",alt:"co worker space near me" },
  { img: "profileimg2", title: "Duane Cain",designation: "Senior Product Desogner",alt:"office rental space near me" },
  { img: "profileimg3", title: "Viola George", designation: "Co-Founder" ,alt:"work spaces"},
  { img: "profileimg4", title: "Maurice Craig", designation: "Co-Founder",alt:"office space for rent nyc" },
  // {
  //   img: "img_11",
  //   title: "Nina Walters",
  //   designation: "Senior Product Desogner",
  // },
  // { img: "img_12", title: "Nathaniel Burke", designation: "Co-Founder" },
  // { img: "img_13", title: "Maurice Craig", designation: "Co-Founder" },
  // { img: "img_14", title: "Viola George", designation: "Co-Founder" },
  // { img: "img_15", title: "Viola George", designation: "Co-Founder" },
  // { img: "img_16", title: "Viola George", designation: "Co-Founder" },
  // { img: "img_17", title: "Viola George", designation: "Co-Founder" },
  // { img: "img_18", title: "Viola George", designation: "Co-Founder" },
];


const TeamThree = () => {
  return (
    <>
      {/* <Tabs> */}
        {/* <TabList className="controls d-flex align-items-center justify-content-start mb-100 md-mb-60">
          <Tab>
            <button type="button" className="control">
              All
            </button>
          </Tab>
          <Tab>
            <button type="button" className="control">
              Leadership
            </button>
          </Tab>
          <Tab>
            <button type="button" className="control">
              Designer
            </button>
          </Tab>
          <Tab>
            <button type="button" className="control">
              Developer
            </button>
          </Tab>
          <Tab>
            <button type="button" className="control">
              Marketing
            </button>
          </Tab>
        </TabList> */}
        {/* End .Tablist */}
        {/* <TabPanel> */}
          <div className="mixitUp-container">
            {All.map((item, i) => (
              // <Link to="/team-details-v1" className="mix" key={i}>
              <div className="mix" key={i}>
                <div className="team-member">
                  <img src={`images/media/${item.img}.jpg`} alt={item.alt} />
                  <h4>{item.title}</h4>
                  <strong>{item.designation}</strong>
                </div>
                {/* /.team-member */}
              {/* </Link> */}
              </div>
            ))}
          </div>
          {/* /.mixitUp-container */}
        {/* </TabPanel> */}
       
      {/* </Tabs> */}
    </>
  );
};

export default TeamThree;
