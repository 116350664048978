import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Header from "../../components/header/Header";
import Footer from "../../components/footer/Footer";
import CopyRight from "../../components/footer/CopyRight";
import FeatureThree from "../../components/features/FeatureThree";
import FeaturesCareer from "../../components/form-survey-landing/FeaturesCareer";

import './Career.css';

const career = () => {

    return (
        <div className="main-page-wrapper">
            <Helmet>
                {/* <title>
                    Business Centre | Virtual Office Training, Meeting Room Kalapatti, Coimbatore
                </title> */}
                <title>Fully Furnished Coworking Office Space near Airport Coimbatore</title>
                <meta name="description"
                    content="Innovspace provides a great way to start your entrepreneurial path in Coimbatore with who have a business idea and a willingness to become an entrepreneur." />
                <meta name="robots" content="max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
                <link rel="canonical" href="https://web.archive.org/web/20230609212315/https://www.innovspace.com/edp/" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="Fully Furnished Coworking Office Space near Airport Coimbatore" />
                <meta property="og:description"
                    content="Innovspace provides a great way to start your entrepreneurial path in Coimbatore with who have a business idea and a willingness to become an entrepreneur." />
                <meta property="og:url" content="https://web.archive.org/web/20230609212315/https://www.innovspace.com/edp/" />
                <meta property="og:site_name" content="Innovspace - Business Accelerator | Coworking Space" />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:description"
                    content="Innovspace provides a great way to start your entrepreneurial path in Coimbatore with who have a business idea and a willingness to become an entrepreneur." />
                <meta name="twitter:title" content="Fully Furnished Coworking Office Space near Airport Coimbatore" />
            </Helmet>
            {/* End Page SEO Content */}
            <Header />

            <div className="fancy-hero-career md-pt-70">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 m-auto">
                            <h2 className="mainheading">
                                Join a firm that customers adore.
                            </h2>
                        </div>
                        <div className="col-xl-9 m-auto" style={{ width: "80%" }} >
                            <p className="mainpara mt-20 md-mt-20" >
                                We want to create a workplace where everyone is valued, so we can all grow and succeed together.
                                Above all, we value caring, attentiveness, accessibility, humility and generosity.
                                We also value transparency and honesty. Joining the Innovspace is about much more than getting a job and a paycheck. We’re on a mission to generate results, strive for excellence and change the game. Sounds right up your alley? You may be exactly what we’re looking for.
                            </p>
                            <a
                                className="theme-btn-one btn-lg mt-30 md-mt-30"
                                href="#view_job"
                            >
                                View job openings
                            </a>

                        </div>
                    </div>
                </div>
                <div className="bubble-one"></div>
                <div className="bubble-two"></div>
                <div className="bubble-three"></div>
                <div className="bubble-four"></div>
                <div className="bubble-five"></div>
                <div className="bubble-six"></div>
            </div>

            {/* As of now the sections are hidden -- dont delete it, uncommand when sections needed*/}
            {/* <div style={{ backgroundColor: "#F3FBFF" }}>
                <div className="row">
                    <div className="col-xl-3 col-lg-3 ">
                        <img src="images/assets/career1.jpg" alt="coworking space business" className="paddingimg" />
                    </div>
                    <div className="col-xl-4 col-lg-4 paddingcareer"  >
                        <h1 className="mainheading mb-20 md-mb-20 paddingright"> A Culture of
                            communication colobaration & connection</h1>
                        <p className="mainpara">We've been fully remote since day one, and are building a fully distribured team across North America. We believe that creative collaboration can happen anywhere, and that working remotely shouldn't have to mean sacrificing a sense of cohesion, community, ans conncection. We've been fully remote since day one, and are building a fully distribured team across North America. We believe that creative collaboration can happen anywhere, and that working remotely shouldn't have to mean sacrificing a sense of cohesion, community, ans conncection.</p>
                    </div>
                    <div className="col-xl-5 col-lg-5">
                        <img src="images/assets/career2.png" alt="india accelerator coworking space" className="career-img paddingimg" style={{ width: "489px" }} />
                    </div>
                </div>
            </div> */}


            <div className="fancy-text-block-four pt-120 pb-90 md-pt-30 md-pb-50">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-7 col-lg-8">
                            <div className="title-style-one mb-40 md-mb-20">
                                {/* <h3 className="mainheading" >What else you might like</h3> */}
                                <h3 className="mainheading" >Here are some of the benefits that we offer</h3>
                                <p className="mainpara " >
                                    We're looking for individuals who are passionate about innovation, collaboration, and making a positive impact on the world.
                                </p>
                            </div>
                            {/* /.title-style-one */}
                        </div>
                    </div>

                    <div className="wrapper">
                        <div className="row justify-content-center">
                            <FeatureThree />
                        </div>
                    </div>
                </div>
            </div>

            {/* <div

                className="block-style-thirtySix lg-container mt-120 md-mt-70"
                id="feature"
            >
                <div className="container">
                    <FeaturesCareer />
                </div>
            </div> */}
            {/* End dont delete */}
            <div className="backgroundlayer" id="view_job">
                <div className="container mt-120 md-mt-70 mb-120 md-mb-70">
                    <h2 className="mainheading">Open Positions</h2>
                    <div className="row mt-20 md-mt-10">
                        <div className="col-xl-6 col-lg-6">
                            <p>No positions available</p>
                        </div>
                    </div>

                    {/* <div className="row mt-20 md-mt-10">
                    <div className="col-xl-6 col-lg-6">
                        <h4 className="cardheadcareer">Senior Accountant</h4>
                        <p className="mainpara " style={{ marginTop: "5px" }}>Full Time</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 md-pt-10 pt-10">
                        <Link to="/career-list">
                            <button className="theme-btn-one" >Apply For Job</button></Link>
                            
                    </div>
                </div> */}
                    {/* <div className="row mt-20 md-mt-10">
                    <div className="col-xl-6 col-lg-6">
                        <h4 className="cardheadcareer">Business Development Exceutive</h4>
                        <p className="mainpara " style={{ marginTop: "5px" }}>Full Time</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 md-pt-10 pt-10">
                        <Link to="/career-list">
                            <button className="theme-btn-one" >Apply For Job</button></Link>
                    </div>
                </div> */}
                    {/* <div className="row mt-20 md-mt-10">
                    <div className="col-xl-6 col-lg-6">
                        <h4 className="cardheadcareer">Office Admin</h4>
                        <p className="mainpara " style={{ marginTop: "5px" }}>Full Time</p>
                    </div>
                    <div className="col-xl-6 col-lg-6 md-pt-10 pt-10">
                        <Link to="/career-list">
                            <button className="theme-btn-one" >Apply For Job</button></Link>
                    </div>
                </div> */}
                </div>
            </div>


            <footer className="theme-footer-one pt-70 md-pt-70">
                <div className="top-footer">
                    <div className="container">
                        <Footer />
                    </div>
                    {/* /.container */}
                </div>
                {/* /.top-footer */}

                <div className="container">
                    <div className="bottom-footer-content">
                        <CopyRight />
                    </div>
                    {/*  /.bottom-footer */}
                </div>
            </footer>
            {/* /.theme-footer-one */}
        </div>
    );
};

export default career;
