import React from "react";
import { Link } from "react-router-dom";
import AboutForm from "../form/AboutForm";


const CorporateBanner = () => {
  return (
    // <!--
    // =============================================
    //     Theme Hero Banner
    // ==============================================
    // -->
    <div className="hero-banner-corporate lg-container" id="home">
      <div id='enquire'>
      <div className="container">
        <div className="row">
          <div className="col-lg-6" style={{marginLeft:"-7px"}}>
            <div className="bispad">
            <h1 className="heading">Discover Excellence with Innovspace's Business Services</h1>
            <div className="mb-30">
              <p className="co-para mt-30">
              Innovspace is your one-stop destination for bespoke business solutions aimed at empowering your enterprise. We comprehend the complexity of business management, which encompasses an array of responsibilities, from adhering to legal standards to overseeing financial elements. Thus, we offer a comprehensive range of business services, meticulously crafted to manage and simplify these essential components of your operations. With us at your side, you can concentrate on your key strengths, while we take care of the rest.
              </p>
              {/* <div className="d-flex mt-10 ">
                <div className="iconfix">
                  <img src="images/assets/location-innov.svg" alt="feature"></img>
                </div>
                <div>
                  <p className="bn-list hometext"> Expansive Network,Easy access,Lively neighbourhoods</p>
                </div>
              </div>
              <div className="d-flex mt-10 ">
                <div className="iconfix">
                <img src="images/assets/cook.svg" alt="feature"></img>
                </div>
                <div>
                  <p className="bn-list hometext">Anywhere workspace, Scalable spaces, Simple contracts</p>
                </div>
              </div>
              <div className="d-flex mt-10 ">
                <div className="iconfix">
                <img src="images/assets/third.svg" alt="feature"></img>
                </div>
                <div>
                  <p className="bn-list hometext"> All-inclusive memberships with WiFi, printing, coffee, on-site support, and more</p>
                </div>
              </div> */}

            </div>
            <div className="mb-30">
              <a href="/contact-us" className="button-banner">
              Talk to an expert
              </a>
            </div>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6" style={{display:"flex",justifyContent:"center"}}>
            <div className="form-Corporate">
              {/* <ContactForm /> */}
              <AboutForm />
            </div>
          </div>
        </div>

        {/* <!-- /.screen-holder --> */}
      </div>
      </div>
    </div>
    // <!-- /.hero-banner-thirteen -->
  );
};

export default CorporateBanner;
